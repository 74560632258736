export const Button = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "medium",
    borderRadius: "10px", // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "sm",
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: "md",
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },

    lg: {
      fontSize: "25px",
      px: 6, // <-- these values are tokens from the design system
      py: 6, // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: "3px solid",
      borderColor: "black",
          color: "black",
          _hover: {
              bg: "black",
              textColor: "white",
      }
    },
    solid: {
      bg: "gray.800",
    color: "white",
        _hover: {
            bg: "gray.700",
    }    
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "solid",
  },
}