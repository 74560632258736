import { Heading, Link, keyframes, usePrefersReducedMotion } from '@chakra-ui/react'
import { Center, Container, Text } from '@chakra-ui/layout'
import React from 'react'
import { motion } from 'framer-motion'
import image from '../../Images/homeBG.jpg'
import { ChevronDownIcon } from '@chakra-ui/icons'

const Header = () => {

    const prefersReducedMotion = usePrefersReducedMotion()
    const bg = keyframes`
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }`
    const animation = prefersReducedMotion
        ? undefined
        : `${bg} infinite 60s linear`

    return (
        <div>
            <Container maxW="100%" h="100vh" bg="gray.50" bgImage={image} bgSize="300%" animation={animation}>
                <Center h="95vh" flexDirection="column">
                    <Heading textAlign="center" fontSize="7xl"> <motion.div animate={{
                        scale: [0.1, 1.1, 1]
                    }}
                        transition={{ duration: 1.5 }}>Projects </motion.div> </Heading>
                    <Text fontSize={['lg', 'xl']} textColor="gray.700" mt="20px" p={["0 20px", "0 100px"]} textAlign="center"><motion.div animate={{ opacity: [0, 1] }} transition={{ duration: 0.5, delay: 2 }}>The Quiz Club of Ananda College has provided many opportunities to our very own Anandians and students from other schools to sharpen their intellect and to become erudite quizzers. We organize many projects year around, opening doors to any quizzer who wants to learn and improve their skills in the field of quizzing.</motion.div> </Text>

                </Center>
                <motion.div animate={{ y: [100, 0] }} transition={{ staggerChildren: 0.5 }}>
                    <Center flexDirection="column">
                        <Link h="0" href="#about" cursor="pointer" transition="0.5s all ease" _hover={{ transform: "translateY(10px)" }}><ChevronDownIcon mt="-100px" fontSize="7xl" textColor="gray.700" /></Link>
                        <Text textColor="gray.600" fontSize="2xl" >Our Projects</Text>
                    </Center>
                </motion.div>
            </Container>
        </div>
    )
}

export default Header
