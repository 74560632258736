import { Heading, Link, keyframes, usePrefersReducedMotion } from '@chakra-ui/react'
import { Center, Container, Text } from '@chakra-ui/layout'
import React from 'react'
import { motion } from 'framer-motion'
import image from '../../Images/homeBG.jpg'
import { ChevronDownIcon } from '@chakra-ui/icons'
import './BrainiacBlitz_Contact.css';
import logo from '../../../src/Images/navLogo1.png';
import { FaWhatsapp, FaPhoneAlt, FaFacebookF, FaInstagram, FaYoutube, FaGlobe } from "react-icons/fa";

const Contact = () => {
    return (
        <div id='brainiacBlitz_contact'>
            <h1 id='title'>Contact</h1>
            <div id='container'>
                <div id='school'>
                    <div id='details'>
                        <img src={logo} alt="logo" />
                        <h1>Ananda College<br/>Quiz Club</h1>
                        <p>The Ananda College Quiz Club was founded in 1974 with the goal of taking young Anandians to the highest levels of competitive quizzing. We strive to broaden the horizons of our members’ knowledge by introducing them to information from beyond the strict confines of the school curriculum. Our efforts have borne fruit in the past and former members of the Ananda College quiz team often end up being knowledgeable professionals, capable of taking an innovative and informed approach to their careers.</p>
                        <div id='socialMediaLinks'>
                            <a href="https://www.facebook.com/people/The-Quiz-Club-of-Ananda-College/100066420941093/"><FaFacebookF /></a>
                            <a href="https://www.instagram.com/_acqc_/"><FaInstagram /></a>
                            <a href="https://www.youtube.com/@anandacollegequizclub1418"><FaYoutube /></a>
                            <a href="/"><FaGlobe /></a>
                        </div>
                    </div>
                </div>
                <div id='school'>
                    <div id='details'>
                        <img src='https://lh3.googleusercontent.com/fife/ALs6j_HAEsvbjJRuZplYOxZV0SBv4lPdo6Dbg1YHT5vEV9mrX0sM3nHPtscxR-yFMmRjWDkMkEEF8yqWJCVHM2INE0e0HEygB_vwllx-uf1bFrojimuSlkcolvihBEb5HKMm8YF94C6hmAShoRtPjdNhE-t4yfPELgNC5fW_pHw3vyxmkKSMzmmevYDSGTThVaHxYl3_AmF75DurxFKFojvNFKFFm3XMBtNweJCcyKdAhImRV3Lf1qALROrCGMof9-qolgMpqEy4ZWsFTFv9OV922fQTyWZDCLjmg2_4gdrQHqPMdEi3FvCK8N22JPp_uiO3e3UDpZazbUSHxo-fK5lPD7eho2uVyXGWhLWlErmUz2vc5bkb6iEOzhJQxmK5RhkcY7GY0Nhz_1Hpl0UIaP1kvqLOKZP20gaBcCGkKQr6oAqnkkHu3Z0v6KBiqNbFGa2aK5Jof4MfDKVQgvJHP3Og3pwF0UTL69ciaQAOwvU1CQS6V1G7KwD8MfQtR6kWSm9Iw_O6kHJsnJ67BAYdNF7KWctCQeiqKNFDN6V_nPcWtBqjdzcQccAgfzQNVaXE-jcj9bKCAY1Brc7I4hSQPAGqgOjJ76SkPkqKF6EMJRM35VUsRAHLHgvCeoFz5PhUlOi_KPq83eZhR-HRKie7tDLKunfbd8lLyCye2zLtKs8h0aUOF-vqp0GBkNP9-Inh_ysErXSkcqq-mrM5Ekvcf6Cxse1UUKNnzm5DEfM7zBH9Tv7beNwk9pe146fO-ynE012onZURos943ZtQVPMNShpl6dEiczuID78K2Mz5uggD5ZspzFnlofVR04ZHNgnHizjr9_1XTdrMY0RbmBh9z1KhpUgoAv1Kg32L91352CH5akOpvtcAXuE1sSaf3duN27eYGU2ia0nnM24UXa5h476zGMz2ShRhUkQtVQDCpi-LTlfi3FXeecfjL6HFe3UImd0UysDeKzE07GHxcQwxTFhFMaJtOTLTTe-SDbEDdCx6rZLdXzHlnbPCejR1IDhMuVZ3dEulHva-6nIY53KQmo9DtfnI8e_VIG2jpoEKYWOKA6x7YKg4MwSL76dAk8qplCqwLc9yDxWfhCHjes9nN_qjGSTtfgQufm86AJar5vZd5WJNEv0ADTrqcHEwwvK00SCP8RroUgfeWd_rww99uvQmLkpRSQmBQc3AaeJYHVjGmxeSHJBqR1uGdmkY2coZcd1TYBZUAOD_BwtXgDjFCMj-xdafzEgEXAZb6ne7mhqY14pd8tr705Mq7o1ybTJ828ROkHFadg1XMRfYm8JrN19ZiDIsalEQhQV9PiB1a_qsAwXkEDeOnLea1Ui2YhRWpNxo1021uCNvGBTRcx8K-y210IVL49Udr8_H8F4YFj8TJtT8MqO8yKyTMXbA9MEem4GbtczFx4rVyYeOppMRyiBE8aqyueCP11REwMgJJfICaOdYwuF79JftJrev_nsn2oad9xo2ruibcyvP7BBH3ikeph9FWXYSzJgyEw5hiU4TmuSoHPdMxT7I-tCgxY5OzhukVGUYoyxxfJpyucPjS0lQ5nj1QEM94MJZBLR5ZmRtD0cy-JIEQDyHAGp_gvYhM5kqTduSG1uQUROuwQw5fifwW4pQcA4Pog0BKoLSqD4cQg9pSyupnkzTFm079vE0DLSAwT7K-ouaftowPPlVM47mFA=w2548-h1206' alt="logo" />
                        <h1>Mahamaya Girls' College<br/>Quiz Club</h1>
                        <p>The Quiz Club of Mahamaya Girls' College Kandy was established in 2000. It stands to this day as one of the most prestigious quiz clubs in Sri Lanka. In 2017, The Quiz Club initiated its first ever inter-school quiz competition, Quaerentem. The Quiz Team itself has also won many awards and noteworthy quizzing titles throughout the years.</p>
                        <div id='socialMediaLinks'>
                            <a href="https://www.instagram.com/mahamayaqc_"><FaInstagram /></a>
                        </div>
                    </div>
                </div>
                <div id='numbers'>
                    <div id='card'>
                        <p id='name'>Janiru Wijekoon</p>
                        <p id='person_title'>[President ACQC]</p>
                        <div id='icon_container'>
                            <div id='box1'>
                                <a href="tel:+94718189436"><FaPhoneAlt /></a>
                                <p id='number'>+94 71 818 9436</p>
                            </div>
                            <a target='_blank' id='box2' href="https://wa.me/+94718189436?text=Hi%20I'm%20inquiring%20about%20BrainiacBlitz."><FaWhatsapp /></a>
                        </div>
                    </div>
                </div>
                <div id='numbers'>
                    <div id='card'>
                        <p id='name'>Senethma Rambukwella</p>
                        <p id='person_title'>[President MCQC]</p>
                        <div id='icon_container'>
                            <div id='box1'>
                                <a href="tel:+94714968737"><FaPhoneAlt /></a>
                                <p id='number'>+94 71 496 8737</p>
                            </div>
                            <a target='_blank' id='box2' href="https://wa.me/+94714968737?text=Hi%20I'm%20inquiring%20about%20BrainiacBlitz."><FaWhatsapp /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id='credits'>This Webpage is Designed and Developed By Nethula Peiris</div>
        </div>
    )
}

export default Contact