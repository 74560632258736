import { Button, Container, Link, Text, VStack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { DbContext } from '../../Services/context';

const Points = () => {
    const { history } = useContext(DbContext);
    let textAlign = false;

    console.log(history)
    return (
        <div>
            <Container maxW="100%" bg="gray.100" p={[ "50px 0", "80px 0"]}>
            {history && history.map(highlight => {
                textAlign = !textAlign;
                return (
                    <Container maxW={["98vw" ,"95vw", "90vw"]} pb="50px">
                        <VStack align={textAlign ? 'flex-start' : 'flex-end'}>
                    <Text fontSize={["md", "lg"]} textColor="gray.700">{highlight.title}</Text>
                    <Text fontSize={["lg", "x-large"]} textAlign={textAlign ? 'left' : 'right'} w={["75" ,"60", "55vw"]}>{highlight.description}</Text>
                    <Link href={highlight.link}><Button mt="10px">{highlight.buttonText}</Button></Link> 
                    </VStack>
                </Container>
                )
                
            })}
            
                
            </Container>
        </div>
    )
}

export default Points
