import { Heading, Link, keyframes, usePrefersReducedMotion } from '@chakra-ui/react'
import { Center, Container, Text } from '@chakra-ui/layout'
import React from 'react'
import { motion } from 'framer-motion'
import image from '../../Images/homeBG.jpg'
import { ChevronDownIcon } from '@chakra-ui/icons'
import './MindProbe_Contact.css';
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";

const Contact = () => {
    return (
        <div id='mindprobe_contact'>
            <h1 id='title'>Contact</h1>
            <div id='container'>
                <div id='numbers'>
                    <div id='card'>
                        <p id='name'>Janiru Wijekoon</p>
                        <p id='person_title'>[President]</p>
                        <div id='icon_container'>
                            <div id='box1'>
                                <a href="tel:+94718189436"><FaPhoneAlt /></a>
                                <p id='number'>+94 71 818 9436</p>
                            </div>
                            <a target='_blank' id='box2' href="https://wa.me/+94718189436?text=Hi%20I'm%20inquiring%20about%20MindProbe23."><FaWhatsapp /></a>
                        </div>
                    </div>
                    <div id='card'>
                        <p id='name'>Harindu Dilsanda</p>
                        <p id='person_title'>[School Coordinator]</p>
                        <div id='icon_container'>
                            <div id='box1'>
                                <a href="tel:+94710750684"><FaPhoneAlt /></a>
                                <p id='number'>+94 71 075 0684</p>
                            </div>
                            <a target='_blank' id='box2' href="https://wa.me/+94710750684?text=Hi%20I'm%20inquiring%20about%20MindProbe23."><FaWhatsapp /></a>
                        </div>
                    </div>
                    <div id='card'>
                        <p id='name'>Maj. D.H. Kudaligama</p>
                        <p id='person_title'>[Master-In-Charge]</p>
                        <div id='icon_container'>
                            <div id='box1'>
                                <a href="tel:+94777257234"><FaPhoneAlt /></a>
                                <p id='number'>+94 77 725 7234</p>
                            </div>
                            <a target='_blank' id='box2' href="https://wa.me/+94777257234?text=Hi%20I'm%20inquiring%20about%20MindProbe23."><FaWhatsapp /></a>
                        </div>
                    </div>
                </div>
                <iframe id='iframe' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3860.334364333292!2d79.86954614608912!3d6.923960358892987!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae259b927c7971b%3A0xa0b0d2538c9e3247!2sACQC%20-%20Ananda%20College%20Quiz%20Club!5e0!3m2!1sen!2slk!4v1707702001495!5m2!1sen!2slk"></iframe>
            </div>
            <div id='sponsors_wrapper'>
                <h1 id='title'>Our Valuable Sponsors</h1>
                <div id='sponsor_logo_container'>
                    <div id='silver_sponsor'>
                        <div id='inner_logo_container'>
                            <img alt='comBankLogo' src='https://lh3.googleusercontent.com/d/1UNv3_rEFbtklufnMfdmeukPaGjlAatVU=w1920-h878-iv1'></img>
                        </div>
                        <p>Silver Sponsor &<br />Official Banking Partner</p>
                    </div>
                    <div id='line_sponsor'></div>
                    <div id='bronze_sponsor'>
                        <div id='inner_logo_container'>
                            <img alt='mediaFactoryLogo' src='https://lh3.googleusercontent.com/d/1cO1kwLgGZCkT0n1FSRCk-tHB_YunqSSC=w1920-h878-iv1'></img>
                            <img alt='cropGuardLogo' src='https://lh3.googleusercontent.com/d/11-FEWfH7v8m3GlFmO4tEOXNVm6VSNSYR=w1920-h878-iv1'></img>
                            <img alt='eagleWingsLogo' src='https://lh3.googleusercontent.com/d/11wHfer-w9Fma1q6f1RbbMpiZPq6wsz3X=w1920-h878-iv1'></img>
                            <img alt='dushyanthaMahabadugeLogo' src='https://lh3.googleusercontent.com/d/1DFjGeNlU7IVspFVTZ3AshVJMIoZPaP88=w1920-h878-iv1'></img>
                        </div>
                        <p>Bronze Sponsors</p>
                    </div>
                    <div id='line_sponsor'></div>
                    <div id='dmp_sponsor'>
                        <div id='inner_logo_container'>
                            <img alt='paragonLogo' src='https://lh3.googleusercontent.com/d/1yfPp7iRccEr9UP9DbEGaxS2dSlqzyGcu=w1920-h878-iv1'></img>
                        </div>
                        <p>Digital Media Partner</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact