import React, { useContext, useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
    Input, Button, VStack, InputGroup, InputRightAddon, AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react';
import { db } from '../../Services/firebase';
import { doc, deleteDoc, setDoc } from "firebase/firestore";
import { useToast } from '@chakra-ui/toast';
import { Container, HStack, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { DbContext } from '../../Services/context';
import Nav from './Menu';
import { IconButton } from '@chakra-ui/button';
import { CheckIcon, DeleteIcon } from '@chakra-ui/icons';

const BoardUpdate = () => {
    useEffect(() => {
        document.title = "ACQC - Admin | Board Update"
    });

    const toast = useToast();

    const { board } = useContext(DbContext);
    console.log(board);

    const [deleteRef, setDeleteRef] = useState('');

    const [isOpenAlert, setIsOpen] = React.useState(false)
    const onCloseAlert = () => {
        setIsOpen(false);
        setDeleteRef('');
    }
    const cancelRef = React.useRef()



    const deleteRank = async () => {
        try {
            await deleteDoc(doc(db, "people", deleteRef));
            toast({
                title: "Member Deleted",
                description: `Member ${deleteRef} has been deleted`,
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
        }

        catch (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        setIsOpen(false)
    }

    const handleDelete = async (name) => {

        setDeleteRef(name);
        console.log(name)
        setIsOpen(true)


    }
    let i = 0;
    return (
        <>
            <AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete member?
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={deleteRank} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <Nav />
            <Container>

                <Container maxW="90vw" p="50px 50px" display="flex" flexDir="column" alignItems="center" justifyContent="center" m="0" borderRadius="2xl" bg="white">
                    <Text fontSize="3xl" mb="50px" fontWeight="semibold">Update the Board</Text>

                    <VStack>
                        <Formik
                            initialValues={{ name: '', post: '' }}
                            validate={values => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                try {

                                    console.log(values);

                                    setDoc(doc(db, "people", values.name), values);

                                    toast({
                                        title: 'Board member updated',
                                        description: '',
                                        status: 'success',
                                        duration: 5000,
                                        isClosable: true,
                                    });

                                }

                                catch (error) {
                                    toast({
                                        title: 'OOPS, something happened',
                                        description: error.message,
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                }

                                setSubmitting(false);

                                resetForm();
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <VStack>
                                        <HStack spacing={4} w="90vw">
                                            <Input
                                                type="text"
                                                name="name"
                                                w="63vw"
                                                placeholder="Student Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />

                                            <Select placeholder="Select Post"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="post"
                                                w="25vw"
                                                value={values.post}
                                            >
                                                <option value="President">President</option>
                                                <option value="Secretary">Secretary</option>
                                                <option value="Treasurer">Treasurer</option>
                                                <option value="Vice President">Vice President</option>
                                                <option value="Assistant Secretary">Assistant Secretary</option>
                                                <option value="Assistant Treasurer">Assistant Treasurer</option>
                                                <option value="Chief Organizer">Chief Organizer</option>
                                                <option value="Chief Co-Ordinator">Chief Co-Ordinator</option>
                                                <option value="Organizer">Organizer</option>
                                                <option value="Media Coordinator">Editor</option>
                                                <option value="External Affiars Co-Ordinator">Auditors</option>
                                                <option value="Senior Members">Committee Members</option>

                                                {/* Media Coordinator > changed as Editor */}
                                                {/* External Affiars Co-Ordinator > changed as Auditor */}
                                                {/* Senior Member > changed as Committee Members */}

                                            </Select>

                                            {/* <Input
                                type="text"
                                name="presidentImage"
                                w="350px"
                    
                                placeholder="President's Photo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.presidentImage}
                            /> */}

                                            <Button type="submit" disabled={isSubmitting} mt="20px">
                                                Submit
                                            </Button>
                                        </HStack>


                                    </VStack>



                                </form>
                            )}
                        </Formik>




                        <HStack spacing={4} w="90vw">
                            <Formik
                                initialValues={{ presidentImg: '' }}

                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    try {

                                        console.log(values);

                                        setDoc(doc(db, "images", "presidentImage"), values);

                                        toast({
                                            title: 'President photo updated',
                                            description: '',
                                            status: 'success',
                                            duration: 5000,
                                            isClosable: true,
                                        });

                                    }

                                    catch (error) {
                                        toast({
                                            title: 'OOPS, something happened',
                                            description: error.message,
                                            status: 'error',
                                            duration: 9000,
                                            isClosable: true,
                                        });
                                    }

                                    setSubmitting(false);

                                    resetForm();
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <InputGroup w="29vw">
                                            <Input
                                                type="text"

                                                name="presidentImg"
                                                value={values.presidentImg}
                                                placeholder="President's Photo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            // value={values.presidentImg}
                                            />
                                            <InputRightAddon as="button" type="submit" disabled={isSubmitting} children={<IconButton bg="gray.100" _hover={{ background: "#22222" }} icon={<CheckIcon textColor="gray.800" />} />} />
                                        </InputGroup>
                                    </form>
                                )}
                            </Formik>

                            <Formik
                                initialValues={{ secretaryImg: '' }}

                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    try {

                                        console.log(values);

                                        setDoc(doc(db, "images", "secretaryImage"), values);

                                        toast({
                                            title: 'Secretary photo updated',
                                            description: '',
                                            status: 'success',
                                            duration: 5000,
                                            isClosable: true,
                                        });

                                    }

                                    catch (error) {
                                        toast({
                                            title: 'OOPS, something happened',
                                            description: error.message,
                                            status: 'error',
                                            duration: 9000,
                                            isClosable: true,
                                        });
                                    }

                                    setSubmitting(false);

                                    resetForm();
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <InputGroup w="29vw">
                                            <Input
                                                type="text"

                                                name="secretaryImg"
                                                value={values.secretaryImg}
                                                placeholder="Setretary's Photo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            // value={values.presidentImg}
                                            />
                                            <InputRightAddon as="button" type="submit" disabled={isSubmitting} children={<IconButton bg="gray.100" _hover={{ background: "#22222" }} icon={<CheckIcon textColor="gray.800" />} />} />
                                        </InputGroup>
                                    </form>
                                )}
                            </Formik>

                            <Formik
                                initialValues={{ treasurerImg: '' }}

                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    try {

                                        console.log(values);

                                        setDoc(doc(db, "images", "treasurerImage"), values);

                                        toast({
                                            title: 'Joint secretary/Treasurer photo updated',
                                            description: '',
                                            status: 'success',
                                            duration: 5000,
                                            isClosable: true,
                                        });

                                    }

                                    catch (error) {
                                        toast({
                                            title: 'OOPS, something happened',
                                            description: error.message,
                                            status: 'error',
                                            duration: 9000,
                                            isClosable: true,
                                        });
                                    }

                                    setSubmitting(false);

                                    resetForm();
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <InputGroup w="29vw">
                                            <Input
                                                type="text"

                                                name="treasurerImg"
                                                value={values.treasurerImg}
                                                placeholder="Treasurer's Photo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            // value={values.presidentImg}
                                            />
                                            <InputRightAddon as="button" type="submit" disabled={isSubmitting} children={<IconButton bg="gray.100" _hover={{ background: "#22222" }} icon={<CheckIcon textColor="gray.800" />} />} />
                                        </InputGroup>
                                    </form>
                                )}
                            </Formik>



                        </HStack>

                        <Container maxW="90vw" p="0" mt="50px">
                            {board && board.map(board => {
                                i += 1;
                                return (
                                    <Container maxW="90vw" bg={"gray.100"} p="15px" mb="10px" borderRadius="10px" fontSize="18px" position="relative">
                                        <HStack>
                                            <Text mr="50px" ml="20px">{i}</Text>
                                            <Text mr="50px" w="40vw">{board.name}</Text>
                                            <Text mr="50px">{board.post}</Text>

                                            <IconButton value={board.name} position="absolute" right="5vw" icon={<DeleteIcon textColor="gray.600" _hover={{ color: "white" }} />} bg="white" onClick={() => {

                                                handleDelete(board.name)
                                            }} />
                                        </HStack>
                                    </Container>
                                )

                            })}
                        </Container>


                    </VStack>
                </Container>
            </Container>
        </>
    )
};

export default BoardUpdate;