import React from 'react';
import { ChakraProvider, Button, Link } from '@chakra-ui/react';
import theme from './Services/theme';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AuthProvider, DbProvider } from './Services/context';
import Login from './Components/AdminPanel/Login';
import Home from './Views/Home';
import Achievements from './Views/Achievements';
import Projects from './Views/Projects';
import Squad from './Views/Squad';
import './style.css';
import History from './Views/History';
import Mindprobe from './Views/Mindprobe';
import Mindprobe_RulesAndRegulations from './Components/Mindprobe/RulesAndRegulations';
import Mindprobe_Results from './Components/Mindprobe/Results';
import BrainiacBlitz from './Views/BrainiacBlitz';
import BrainiacBlitz_RulesAndRegulations from './Components/BrainiacBlitz/RulesAndRegulations';
import Nenasa from './Views/Nenasa';
import PrivateRoute from './PrivateRoute';
import MainAchievementsContainer from './Components/AdminPanel/MainAchievements';
import AchievementsEdit from './Components/AdminPanel/Achievements';
import BoardUpdate from './Components/AdminPanel/BoardUpdate';
import ProjectsUpdate from './Components/AdminPanel/Projects';
import TiCUpdate from './Components/AdminPanel/TiCUpdate';
import HistoryEdit from './Components/AdminPanel/History';
import SquadUpdate from './Components/AdminPanel/SquadUpdate';
import { Fonts } from './Services/Fonts'
import logo from './Images/navLogo.png'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <DbProvider>
        <AuthProvider>
          <Router>

            <Switch>
              <PrivateRoute path="/admin/squad" component={SquadUpdate} />
              <PrivateRoute path="/admin/main-achievements" component={MainAchievementsContainer} />
              <PrivateRoute path="/admin/achievements" component={AchievementsEdit} />
              <PrivateRoute path="/admin/history" component={HistoryEdit} />
              <PrivateRoute path="/admin/board" component={BoardUpdate} />
              <PrivateRoute path="/admin/projects" component={ProjectsUpdate} />
              <PrivateRoute path="/admin/tic" component={TiCUpdate} />

              <Route path="/" exact component={Home} />
              <Route path="/achievements" exact component={Achievements} />
              <Route path="/history" exact component={History} />
              <Route path="/projects" exact component={Projects} />
              <Route path="/mindprobe" exact component={Mindprobe} />
              <Route path="/mindprobe/r&r" exact component={Mindprobe_RulesAndRegulations} />
              <Route path="/mindprobe/results" exact component={Mindprobe_Results} />
              <Route path="/bb24" exact component={BrainiacBlitz} />
              <Route path="/bb24/r&r" exact component={BrainiacBlitz_RulesAndRegulations} />
              <Route path="/squad" exact component={Squad} />
              <Route path="/nenasa" exact component={Nenasa} />

              <Route path="/login" component={Login} />
              <Route path="/admin" exact > <Redirect to="/admin/squad" /> </Route>

              <Route path="*">{PageNotFound()}</Route>


            </Switch>

          </Router>
        </AuthProvider>
      </DbProvider>
    </ChakraProvider>
  );
}

function PageNotFound() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column', padding: '10px' }}>
      <img src={logo} alt="logo" style={{ marginBottom: '30px' }}></img>
      <p style={{ fontSize: '35px' }}>404</p>
      <p style={{ fontSize: '25px', textAlign: 'center' }}>Oops Page Not Found !!!</p>
      <Link href='/'><Button colorScheme='blue' style={{ fontSize: '18px', textAlign: 'center', marginTop: '25px', height: '50px' }}>Return to Home</Button></Link>
    </div>
  );
}

export default App;
