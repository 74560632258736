import { Button } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import './BrainiacBlitz_RulesAndRegulations.css';

const About = () => {
    useEffect(() => {
        document.title = "ACQC - Brainiac Blitz | Rules And Regulations"
    });

    return (
        <div id='brainiacBlitz_rulesAndRegulations'>
            <h1 id='title'>Brainiac Blitz<br />Rules And Regulations</h1>
            <div>
                <h2>General Information</h2>
                <p><span id='highlight'>Eligibility:</span> All competitors must be born after January 31, 2005.<br />
                    <span id='highlight'>Participation:</span> There are no limitations on the number of participants who can represent each school.<br />
                    <span id='highlight'>Qualification Round:</span> The first qualification round will be held on June 2, 2024, starting at 7:00PM via Zoom. All participants must log in to the Zoom meeting and then move to the relevant breakout room once the quiz begins.<br />
                    <span id='highlight'>Special Round:</span> After the qualification round, a special round will be held in the main Zoom meeting.<br />
                    <span id='highlight'>Registration:</span> Please register before May 26, 2024.<br />
                    <span id='highlight'>Answer Validation:</span> Phonetically correct answers will be considered correct. For names, the correct surname is sufficient; however, correct surnames with incorrect forenames will not be accepted.<br />
                    <span id='highlight'>Prohibition on External Aids:</span> Any contestant found referring to any form of written or electronic sources will be disqualified.</p><br />
                <h2>Structure of the Quiz</h2>
                <p>
                    <span id='highlight'>1 ) Qualification Round</span><br /><br />
                    <span id='highlight'>Categories:</span> The first qualification round consists of four separate quizzes based on the main subjects of quizzing.
                </p><br />
                <ol>
                    <li>Arts and Literature</li>
                    <li>History and Geography</li>
                    <li>Science and Technology</li>
                    <li>Sports and Games</li>
                </ol><br />
                <p>
                    <span id='highlight'>Platform:</span> The quiz will be held using the Quizizz platform.<br />
                    <span id='highlight'>Registration Limit:</span> Each competitor can only register for one of the four categories.<br />
                    <span id='highlight'>Format:</span> Each round will consist of 40 questions, with 30 seconds allotted per question. The quiz will be in a short answer format.<br />
                    <span id='highlight'>Advancement:</span> The top two contestants from each of the four categories will advance to the Quarterfinals.<br /><br />

                    <button><span id='special_round'>Special Round</span></button><br /><br />
                    <span id='highlight'>Content:</span> There will be a special round based on comic books and comic book literature, consisting of 30 questions with 30 seconds per question. This round is a separate category and does not impact advancement to the Quarterfinals.<br /><br />

                    <span id='highlight'>2 ) Quarterfinals</span><br /><br />
                    <span id='highlight'>Competitors:</span> The top 8 competitors from the qualification round will compete in the Quarterfinals.<br />
                    <span id='highlight'>Format:</span> The Quarterfinals will be conducted on Quizizz and will include 25 questions covering all subjects:<br /><br />
                    <ol>
                        <li>Arts and Literature</li>
                        <li>History and Geography</li>
                        <li>Science and Technology</li>
                        <li>Sports and Games</li>
                        <li>General Knowledge</li>
                        <li>Current Affairs</li>
                    </ol><br />
                    <span id='highlight'>Timing:</span> Each question will have 30 seconds to answer.<br /><br />

                    <span id='highlight'>3 ) Semifinals</span><br /><br />
                    <span id='highlight'>Advancement:</span> The top 4 competitors from the Quarterfinals will advance to the Semifinals.<br />
                    <span id='highlight'>Format:</span> The semifinals will be oral rounds. Competitors will be paired as follows:<br /><br />
                    <ul>
                        <li>1st and 3rd place competitors</li>
                        <li>2nd and 4th place competitors</li>
                    </ul><br />

                    <span id='highlight'>4 ) Finals</span><br /><br />
                    The winners of the Semifinals will compete in the Grand Final. The structure of the Finals and Semifinals will be announced later.<br /><br />
                </p>
                <h2>Important Dates</h2>
                <p>
                <span id='highlight'>Registration Deadline:</span> June 1, 2024<br />
                <span id='highlight'>Qualification Round:</span> June 2, 2024, at 7:00 PM via Zoom<br /><br />
                    Please ensure you adhere to all the rules and guidelines to avoid disqualification. Good luck!
                    <br /><br />
                    For further details &nbsp; <button><a href='/bb24#brainiacBlitz_contact' id='contact_button'>{">> Contact Us >>"}</a></button>
                </p><br /><br />
                <hr></hr>
                <a href='/bb24'><Button id='home_button'>Back To Home</Button></a>
            </div>
        </div >
    )
}

export default About
