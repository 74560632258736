import React, { useEffect } from 'react'
import Nav from '../Components/Nav'
import Header from '../Components/Nenasa/Header'
import About from '../Components/Nenasa/About'
import Results from '../Components/Nenasa/Results'
import Footer from '../Components/Footer'

const Squad = () => {
    useEffect (()=>{
        document.title = "ACQC - Nenasa"
    });
    
    return (
        <div>
            <Nav />
            <Header />
            <About />
            <Results />
            <Footer/>
        </div>
    )
}

export default Squad