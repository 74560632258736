import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons';

import logo from '../Images/navLogo.png'
import { NavLink, Link } from 'react-router-dom';

export default function Nav() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        position={"absolute"}
        minH={'60px'}

        w={'100%'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} textColor="white" /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: '0.3', md: '1' }} justify="space-between" p="0 5%" className="hello">
          {/* <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}>
            Logo
          </Text> */}

          <img src={logo} alt="logo" />

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {

  return (
    <Stack direction={'row'} spacing={4} align="center">
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>

          {navItem.label === "Mind Probe Registrations" ? (
            <NavLink
              exact
              to={navItem.href ?? '#'}
              style={{ fontSize: '1.3rem', padding: '0 1rem', color: '#0047ab' }}
              activeStyle={{ color: '#151515', fontWeight: 'bold' }}
            >
              {navItem.label}
            </NavLink>
          ) : (
            <NavLink
              exact
              to={navItem.href ?? '#'}
              style={{ fontSize: '1.3rem', padding: '0 1rem', color: '#4a5568' }}
              activeStyle={{ color: '#151515', fontWeight: 'bold' }}
            >
              {navItem.label}
            </NavLink>
          )}
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg='gray.900'
      p={4}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (

    <Stack spacing={4} onClick={children && onToggle} mt="50px">
      <Flex
        py={2}
        as={Link}
        to={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color='gray.100'>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} to={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};



const NAV_ITEMS = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'History',
    href: '/history',
  },
  {
    label: 'Achievements',
    href: '/achievements',
  },
  {
    label: 'Projects',
    href: '/projects',
  },
  {
    label: 'Squad',
    href: '/squad',
  },
  {
    label: 'BrainiacBlitz',
    href: '/bb24',
  },
];
