import { Center, Container, Text, Wrap, WrapItem } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { DbContext } from '../../Services/context'

const TiC = () => {
    const { teachers } = useContext(DbContext)

    const mainTeacher = teachers.filter(teacher => teacher.role === 'main-teacher');
    const teacher = teachers.filter(teacher => teacher.role === 'teacher');
    const principal = teachers.filter(teacher => teacher.role === 'principal');

    return (
        <div>
            <Container maxW="100%" bg="gray.50" p={['50px 30px', '80px']} id="about">


                    
                <Center flexDir="column">
                    <Text textAlign="center" fontWeight="bold" textColor="gray.900" fontSize={['4xl', '5xl', '6xl']} letterSpacing="wider">Teachers in charge</Text>
                    <Wrap justify="space-evenly" mt="80px" w={["100vw", "100vw", "80vw"]} alignContent="center">
                        <WrapItem>
                            <Container maxW="300px" mb="50px">
                        <Center flexDir="column">
                            <Image src={principal[0] && principal[0].image} w="220px" h="220px" objectFit="cover" borderRadius="10px" alt="img" />
                                   <Text textColor="gray.600" fontSize="xl" mt="20px" textAlign="center">{principal[0] && principal[0].name}</Text>
                        <Text textColor="gray.500" fontSize="lg" mt="10px" textAlign="center">Principal</Text>
                        </Center>
                    </Container>
                        </WrapItem>

                        <WrapItem>
                            <Container maxW="300px" mb="50px">
                        <Center flexDir="column">
                            <Image src={mainTeacher[0] && mainTeacher[0].image} w="220px" h="220px" objectFit="cover" borderRadius="10px" alt="img" />
                                <Text textColor="gray.600" fontSize="xl" mt="20px" textAlign="center">{mainTeacher[0] && mainTeacher[0].name}</Text>
                        <Text textColor="gray.500" fontSize="lg" mt="10px" textAlign="center">Main Teacher-in-Charge</Text>
                        </Center>
                    </Container>
                        </WrapItem>
                    </Wrap>
                    <Wrap mt="30px" spacing={["20px" ,"100px"]} justify="center" p={['0px', '0 50px']}>
                        {teacher.map(teacher => {
                            return (
                                <WrapItem key={teacher.id}>
                                    <Text textColor="gray.600" fontSize={['xl', 'xl', '2xl']} mt="30px" textAlign="center">
                                    {teacher.name}
                    </Text>
                                </WrapItem>
                            )
                        })}
                            
                    </Wrap>
                    
                </Center>
                
            </Container>
        </div>
    )
}

export default TiC
