import React, { useContext, useEffect } from 'react'
import Home from '../Components/BrainiacBlitz/Home'
import Registration from '../Components/BrainiacBlitz/Registration'
import Registration_Closed from '../Components/BrainiacBlitz/Registration_Closed'
import About from '../Components/BrainiacBlitz/About'
import Contact from '../Components/BrainiacBlitz/Contact'
import Footer from '../Components/BrainiacBlitz/Footer'
import { DbContext } from '../Services/context';

const BrainiacBlitz = () => {
    useEffect (()=>{
        document.title = "ACQC - BrainiacBlitz"
    });

    const { details } = useContext(DbContext);
    let registration_open = "";

    // eslint-disable-next-line array-callback-return
    details && details.map(details => {
        registration_open = details.registration_open;
    })

    const function1 = () => {
        if(registration_open === "Yes"){
            return(<Registration />);
        }else if(registration_open === "No"){
            // eslint-disable-next-line react/jsx-pascal-case
            return(<Registration_Closed />);
        }
    }

    return (
        <div>
            <Home />
            <About />
            {/* {function1()} */}
            <Registration />
            <Contact />
            {/* <Footer/> */}
        </div>
    )
}

export default BrainiacBlitz
