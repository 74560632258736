import React, { useEffect, useState } from "react";
import { collection, onSnapshot, query, orderBy, limit, getDocs } from "firebase/firestore";

import { db, auth } from "./firebase";

export const AuthContext = React.createContext();
export const DbContext = React.createContext();

export const AuthProvider = ({ children }) => {                                     // <-- Handle user's state
  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(setUser);
  }, []);
  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};


export const DbProvider = ({ children }) => {                                     // <-- Handle DB

  // const fetchRatings = async () => {                                              // <-- Fetch squad from DB
  //   const res = query(collection(db, "ratings"), orderBy("points", "desc"), limit(10));
  //   const unsubscribe = onSnapshot(res, (querySnapshot) => {
  //     const ratingList = [];
  //     querySnapshot.forEach((doc) => {
  //       ratingList.push(doc.data());
  //     });


  //     setRatings(ratingList);
  //   });

  // }

  const fetchTeachers = async () => {                                         // <-- Fetch teachers from DB
    const response = query(collection(db, "teachers"));
    // eslint-disable-next-line no-unused-vars
    const unsubscribe = onSnapshot(response, (querySnapshot) => {
      const teacherList = [];
      querySnapshot.forEach((doc) => {
        teacherList.push(doc.data());
      });

      setTeachers(teacherList);
    });
  }

  const fetchHistory = async () => {                                         // <-- Fetch history from DB
    const response = query(collection(db, "history"));
    // eslint-disable-next-line no-unused-vars
    const unsubscribe = onSnapshot(response, (querySnapshot) => {
      const historyList = [];
      querySnapshot.forEach((doc) => {
        historyList.push(doc.data());
      });

      setHistory(historyList);
    });
  }

  const fetchProjects = async () => {                                         // <-- Fetch projects from DB
    const response = query(collection(db, "projects"));
    // eslint-disable-next-line no-unused-vars
    const unsubscribe = onSnapshot(response, (querySnapshot) => {
      const projectslist = [];
      querySnapshot.forEach((doc) => {
        projectslist.push(doc.data());
      });

      setProjects(projectslist);
    });
  }

  const fetchMainProjects = async () => {                                         // <-- Fetch projects from DB
    const response = query(collection(db, "mainProjects"));
    // eslint-disable-next-line no-unused-vars
    const unsubscribe = onSnapshot(response, (querySnapshot) => {
      const mainProjectslist = [];
      querySnapshot.forEach((doc) => {
        mainProjectslist.push(doc.data());
      });

      setMainProjects(mainProjectslist);
    });
  }


  const fetchAchievements = async () => {                                     // <-- Fetch achievements from DB
    const response = query(collection(db, "Achievements"));
    // eslint-disable-next-line no-unused-vars
    const unsubscribe = onSnapshot(response, (querySnapshot) => {
      const achievementsList = [];
      querySnapshot.forEach((doc) => {
        achievementsList.push(doc.data());
      });

      setAchievements(achievementsList);
    });
  }

  const fetch_qTeam = async () => {                                         // <-- Fetch Ratings from DB
    const seniorQuizCaptainList = []
    const seniorQuizViceCaptainList = []
    const seniorQuizTeamMember = []
    const seniorQuizSquadMember = []
    const juniorQuizCaptainList = []
    const juniorQuizViceCaptainList = []
    const juniorQuizTeamMember = []
    const juniorQuizSquadMember = []

    const qTeamRef = query(collection(db, "QuizTeam"), orderBy("name", "asc"));
    const qTeamRes = await getDocs(qTeamRef);
    const qTeamList = [];
    qTeamRes.forEach((doc) => {
      qTeamList.push(doc.data());
    });

    set_qTeam(qTeamList);

    qTeamList.forEach((person) => {
      switch (person.seniorRole) {
        case "Senior Captain":
          seniorQuizCaptainList.push(person);
          break;
        case "Senior Vice Captain":
          seniorQuizViceCaptainList.push(person);
          break;
        case "Senior Team":
          seniorQuizTeamMember.push(person);
          break;
        case "Senior Squad":
          seniorQuizSquadMember.push(person);
          break;
        default:
          break;
      }
      switch (person.juniorRole) {
        case "Junior Captain":
          juniorQuizCaptainList.push(person);
          break;
        case "Junior Vice Captain":
          juniorQuizViceCaptainList.push(person);
          break;
        case "Junior Team":
          juniorQuizTeamMember.push(person);
          break;
        case "Junior Squad":
          juniorQuizSquadMember.push(person);
          break;
        default:
          break;
      }
    }
    )

    setSeniorQuizCaptain(seniorQuizCaptainList);
    setSeniorQuizViceCaptain(seniorQuizViceCaptainList);
    setSeniorQuizTeamMember(seniorQuizTeamMember);
    setSeniorQuizSquadMember(seniorQuizSquadMember);
    setJuniorQuizCaptain(juniorQuizCaptainList);
    setJuniorQuizViceCaptain(juniorQuizViceCaptainList);
    setJuniorQuizTeamMember(juniorQuizTeamMember);
    setJuniorQuizSquadMember(juniorQuizSquadMember);

  }

  const fetchBoard = async () => { // <-- Fetch board from DB


    const boardImages = await getDocs(collection(db, "images"), orderBy("id", "asc"));
    const imageList = [];
    boardImages.forEach((doc) => {
      imageList.push(doc.data());
    });

    setImages(imageList);

    const sectrataryList = []
    const treasureresList = []
    const presidentList = []
    const vPresidentList = []
    const AssTreasurerList = []
    const ChiefCoOrdinatorList = []
    const ExternalAffiarsCoOrdinatorList = []
    const seniorMembersList = []
    const chiefOrganizerList = []
    const organizersList = []
    const assistantSecrataryList = []
    const mediaCoordinatorList = []


    //secretary
    const boardRef = query(collection(db, "people"));
    const boardRes = await getDocs(boardRef);
    const boardList = [];
    boardRes.forEach((doc) => {
      boardList.push(doc.data());
    });

    setBoard(boardList);

    boardList.forEach((person) => {
      // if (person.post === "Secretary") { 
      //   sectrataryList.push(person);
      // }


      switch (person.post) {
        case "Treasurer":
          treasureresList.push(person);
          break;
        case "President":
          presidentList.push(person);
          break;
        case "Secretary":
          sectrataryList.push(person);
          break;
        case "Vice President":
          vPresidentList.push(person);
          break;
        case "Assistant Treasurer":
          AssTreasurerList.push(person);
          break;
        case "Chief Co-Ordinator":
          ChiefCoOrdinatorList.push(person);
          break;
        case "External Affiars Co-Ordinator":
          ExternalAffiarsCoOrdinatorList.push(person);
          break;
        case "Senior Members":
          seniorMembersList.push(person);
          break;
        case "Chief Organizer":
          chiefOrganizerList.push(person);
          break;
        case "Organizer":
          organizersList.push(person);
          break;
        case "Assistant Secretary":
          assistantSecrataryList.push(person);
          break;
        case "Media Coordinator":
          mediaCoordinatorList.push(person);
          break;
        default: break;
      }
    }
    )

    setTreasurer(treasureresList);
    setPresident(presidentList);
    setVp(vPresidentList);
    setAT(AssTreasurerList);
    setCCO(ChiefCoOrdinatorList);
    setEACO(ExternalAffiarsCoOrdinatorList);
    setSMembers(seniorMembersList);
    setCO(chiefOrganizerList);
    setOrganizers(organizersList);
    setSecretary(sectrataryList);
    setASecratary(assistantSecrataryList);
    setMCoordinator(mediaCoordinatorList);
  }


  const fetchMainAchievements = async () => {                                    // <-- Fetch main achievements from DB
    const response = query(collection(db, "mainAchievements"), orderBy("time"), limit(12));
    // eslint-disable-next-line no-unused-vars
    const unsubscribe = onSnapshot(response, (querySnapshot) => {
      const mainAchievementsList = [];
      querySnapshot.forEach((doc) => {
        mainAchievementsList.push(doc.data());
      });

      setMainAchievements(mainAchievementsList);
    });
  }

  const fetchDetails = async () => {                                     // <-- Fetch details from DB
    const response = query(collection(db, "details"));
    // eslint-disable-next-line no-unused-vars
    const unsubscribe = onSnapshot(response, (querySnapshot) => {
      const detailsList = [];
      querySnapshot.forEach((doc) => {
        detailsList.push(doc.data());
      });

      setDetails(detailsList);
    });
  }

  //   }
  // const [ratings, setRatings] = useState([]);

  const [teachers, setTeachers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [mainProjects, setMainProjects] = useState([]);

  const [board, setBoard] = useState([]);
  const [quizTeams, set_qTeam] = useState([]);


  const [achievements, setAchievements] = useState([]);

  const [mainAchievements, setMainAchievements] = useState([]);

  const [president, setPresident] = useState([]);
  const [secretary, setSecretary] = useState([]);
  const [treasurer, setTreasurer] = useState([]);
  const [sMembers, setSMembers] = useState([]);
  const [aSecratary, setASecratary] = useState([]);
  const [mCoordinator, setMCoordinator] = useState([]);
  const [vp, setVp] = useState([]);
  const [AT, setAT] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [CO, setCO] = useState([]);
  const [EACO, setEACO] = useState([]);
  const [CCO, setCCO] = useState([]);

  const [images, setImages] = useState([]);
  const [history, setHistory] = useState([]);

  const [seniorQuizCaptain, setSeniorQuizCaptain] = useState([]);
  const [seniorQuizViceCaptain, setSeniorQuizViceCaptain] = useState([]);
  const [seniorQuizTeamMember, setSeniorQuizTeamMember] = useState([]);
  const [seniorQuizSquadMember, setSeniorQuizSquadMember] = useState([]);
  const [juniorQuizCaptain, setJuniorQuizCaptain] = useState([]);
  const [juniorQuizViceCaptain, setJuniorQuizViceCaptain] = useState([]);
  const [juniorQuizTeamMember, setJuniorQuizTeamMember] = useState([]);
  const [juniorQuizSquadMember, setJuniorQuizSquadMember] = useState([]);

  const [details, setDetails] = useState([]);

  useEffect(() => {
    fetch_qTeam();
    // fetchRatings();
    fetchTeachers();
    fetchBoard();
    fetchAchievements();
    fetchMainAchievements();
    fetchProjects();
    fetchMainProjects();
    fetchHistory();
    fetchDetails();
  }, [])




  return (
    <DbContext.Provider value={{ teachers, board, quizTeams, achievements, mainAchievements, history, images, projects, mainProjects, president, secretary, treasurer, sMembers, CO, vp, AT, EACO, CCO, organizers, aSecratary, mCoordinator, seniorQuizCaptain, seniorQuizViceCaptain, seniorQuizTeamMember, seniorQuizSquadMember, juniorQuizCaptain, juniorQuizViceCaptain, juniorQuizTeamMember, juniorQuizSquadMember, details }}>{children}</DbContext.Provider>
  );

};