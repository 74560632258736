import { Button } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import './MindProbe_RulesAndRegulations.css';

const About = () => {
    useEffect (()=>{
        document.title = "ACQC - MindProbe23 | Rules And Regulations"
    });
    
    return (
            <div id='mindprobe_rulesAndRegulations'>
                <h1 id='title'>Mind Probe '23<br/>Rules And Regulations</h1>
                <div>
                    <h2>General Information</h2>
                    <p>
                        The quiz will be held in two categories. (Disclaimer – The grade considered here is for the academic year 2023).
                    </p><br />
                    <ul>
                        <li>Junior Category (Grade 6 – 9)</li>
                        <li>Senior Category (Grade 6 and Upwards; No age restrictions)</li>
                    </ul><br />
                    <p>
                        All competitors must be born after 31-01-2005.<br />
                        Both Junior and Senior Competitions will occur parallelly, therefore the same participant <span id='highlight'>cannot</span> be registered for both.<br />
                        Each team should consist of <span id='highlight'>4 members.</span><br/>
                        Each team must register through <span id='highlight'><a href='/mindprobe'>acqc.lk/mindprobe</a></span> before the <span id='highlight'>27th of February 2024</span> to confirm their participation. After registration, they will be added to a WhatsApp group for further communication.<br />
                        The participants must arrive in school attire by 8.00am on the <span id='highlight'>7th of March</span> 2024. (A Master-In-Charge from the school must accompany the students)<br />
                    </p>
                    <h2>Structure of the Quiz</h2>
                    <p>
                        Both Junior and Senior Quiz competitions will follow the same structure that is mentioned below.<br />
                        Both competitions will first commence with a written table round and conclude with the Top 2 teams competing in a final round which will be held <span id='highlight'>orally onstage</span>.<br />
                        The table round will consist of 5 main rounds and an additional special round (Marks won’t be added to the main round.)<br />
                        The rounds are as follows,
                    </p><br />
                    <ol>
                        <li>Current Affairs</li>
                        <li>History and Geography</li>
                        <li>Sports and Games</li>
                        <li>Science and Technology</li>
                        <li>Arts and Literature</li>
                        <li>Global Economic and Environmental Crisis &nbsp; <button><span id='special_round'>Special Round</span></button></li>
                    </ol><br />
                    <p>
                        Each round will consist of 10 questions that will be asked in <span id='highlight'>Both Sinhala and English Languages</span> with each question being allocated one minute to answer and an additional one minute will be given after every round for teams to finalize their answers. Answers can also be written in either Sinhala or English Languages.<br />
                        Additionally, the Master Mind trophy to determine the best individual quizzer will also be held for one competitor each from the top 4 teams of the Senior Category. (Further details will be announced on the day.)<br />
                    </p>
                    <h2>Additional Information</h2>
                    <p>
                        Registration for the Junior Category will be done on a first come first serve basis due to there being limited slots available.<br />
                        The proceedings of Mind Probe 2023 will commence throughout the day with Lunch and subsequent refreshments being provided to all participants.<br /><br />
                        For further details &nbsp; <button><a href='/mindprobe#mindprobe_contact' id='contact_button'>{">> Contact Us >>"}</a></button>
                    </p><br /><br />
                    <hr></hr>
                    <a target='_blank' rel="noreferrer" href='https://drive.google.com/file/d/1tOUjKW_DzywEIMUN8rYEX2T5lFAMyecF/view?usp=drive_link'><Button id='sinhala_rules'>isxy, NdIdfjka kS;s yd fr.=,dis</Button></a>
                </div>
            </div>
    )
}

export default About
