import { Image } from '@chakra-ui/image'
import { Center, Container, Text, Wrap } from '@chakra-ui/layout'
import { React, useContext } from 'react'
import { DbContext } from '../../Services/context'

const MainAchievements = () => {

    const { mainAchievements } = useContext(DbContext)

    return (
        <div>
            <Container maxW="100vw" bg="gray.900" p={["80px 20px", "80px 50px", "80px 100px"]} id="about">
                <Wrap justify="center" spacing="20">
                    {mainAchievements && mainAchievements.map(achievement => {
                        return (
                            <Container maxW="350px">
                                <Center flexDir="column">
                                    <Image w="300px" h="200px" borderRadius="5px" objectFit="cover" src={achievement.image} />
                                    <Text fontSize="lg" fontWeight="bold" color="white" mt="20px">
                                        {achievement.title}
                                    </Text>
                                    <Text fontSize="md" color="gray.300" textAlign="center">{achievement.description}</Text>
                                </Center>
                            </Container>
                        )
                    })}
                </Wrap>
            </Container>
        </div>
    )
}

export default MainAchievements
