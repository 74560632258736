import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { Center, Container, HStack, Text, Wrap } from '@chakra-ui/layout'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal'
import React, { useContext, useEffect, useState } from 'react'
import { DbContext } from '../../Services/context'

const OtherAchievements = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const year = (new Date()).getFullYear();
    let yearlist = year - 2000;
    const years = Array.from(new Array(yearlist), (val, index) => year - index);

    const { achievements } = useContext(DbContext);

    const [yearRef, setYearRef] = useState('');

    let achievementsByYearList = [];
    let winnerList = [];
    let runnerUpList = [];
    let thirdPlaceList = [];

    const [winner, setWinner] = useState([]);
    const [achievementsByYear, setAchievementsByYear] = useState([]);
    const [runnerUp, setRunnerUp] = useState([]);
    const [thirdPlace, setThirdPlace] = useState([]);

    useEffect(() => {
        console.log(achievementsByYear);

        achievementsByYear.forEach(achievement => {  //use the above state to get the first place achievements, runner up achievements and third place achievements
            if (achievement.place === '1') {      // Then store them in their own state
                winnerList.push(achievement);
                setWinner(winnerList);
            } else if (achievement.place === '2') {
                runnerUpList.push(achievement);
                setRunnerUp(runnerUpList);
            } else if (achievement.place === '3') {
                thirdPlaceList.push(achievement);
                setThirdPlace(thirdPlaceList);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [achievementsByYear])

    useEffect(() => {
        achievements.forEach(achievement => {   // get the value of the button (year) and add all the achievements in that year to a state
            if (achievement.year === yearRef) {
                console.log(yearRef);
                achievementsByYearList.push(achievement);
                setAchievementsByYear(achievementsByYearList);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yearRef]);

    const handleData = (e) => {  // this executes when the 'view' button is clicked

        setYearRef(e.target.value);
        onOpen();   // open the modal

    }

    return (
        <div>
            <Modal isOpen={isOpen} onClose={() => {
                onClose();
                setAchievementsByYear([]);
                setWinner([]);
                setRunnerUp([]);
                setThirdPlace([]);
            }}>
                <ModalOverlay />
                <ModalContent maxW={["95vw", "90vw", "80vw"]}>
                    <ModalHeader textColor="gray.700">{yearRef} Achievements ({achievementsByYear.length})</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb="50px">
                        {winner && <Text fontSize="3xl" fontWeight="bold" mb="10px">Champions</Text>}

                        {winner.map(achievement => {

                            return (
                                <div>

                                    <Container maxW="65vw" p="10px 20px" mb="10px" borderRadius="10px" bg="gray.800" fontSize="18px">
                                        <HStack>
                                            <Text display="inline-block" textColor="white">{achievement.title}
                                                <Text ml="50px" display="inline-block" textColor="gray.200">{achievement.description}</Text></Text>
                                        </HStack>
                                    </Container>
                                </div>
                            )
                        })}


                        {runnerUp !== null ? <Text fontSize="3xl" fontWeight="bold" mb="10px" mt="30px">Runners Up</Text> : null}
                        {runnerUp.map(achievement => {

                            return (
                                <div>

                                    <Container maxW="65vw" p="10px 20px" mb="10px" borderRadius="10px" bg="gray.700" fontSize="18px">
                                        <HStack>
                                            <Text display="inline-block" textColor="white">{achievement.title}
                                                <Text ml="50px" display="inline-block" textColor="gray.200">{achievement.description}</Text></Text>
                                        </HStack>
                                    </Container>
                                </div>
                            )
                        })}


                        {winner && <Text fontSize="3xl" fontWeight="bold" mb="10px" mt="30px">Second Runners Up</Text>}
                        {thirdPlace.map(achievement => {

                            return (
                                <div>

                                    <Container maxW="65vw" p="10px 20px" mb="10px" borderRadius="10px" bg="gray.400" fontSize="18px">
                                        <HStack>
                                            <Text display="inline-block" textColor="black">{achievement.title}
                                                <Text ml="50px" display="inline-block" textColor="gray.800">{achievement.description}</Text></Text>
                                        </HStack>
                                    </Container>
                                </div>
                            )
                        })}

                    </ModalBody>


                </ModalContent>
            </Modal>

            <Container maxW="100%" p="80px" bg="gray.50">

                <Center flexDir="column">
                    <Text fontSize="3xl" fontWeight="bold" mb="20">VIEW ACHIEVEMENTS BY YEAR</Text>
                    <Wrap spacing="5vw" justify="center">
                        {years.map(year => {
                            return (
                                <Button size="lg" value={year} key={year} onClick={handleData}>{year}</Button>
                            )
                        })}
                    </Wrap>
                </Center>
            </Container>
        </div>
    )
}

export default OtherAchievements
