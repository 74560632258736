import { Center, Container } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/layout'
import React from 'react'

const About = () => {
    return (
        <div>
            <Container maxW="100%" bg="gray.900" p={['50px 30px', '80px']} id="about">
                <Center flexDir="column">
                    <Text textColor="white"  fontSize={['4xl', '5xl', '6xl']} fontWeight="bold" letterSpacing="wider">About</Text>
                    <Text textColor="gray.300" fontSize={['xl', 'xl', '2xl']} mt="30px" p={['0px', '0 50px']} textAlign="center">
                    Anada Nenasa is an annual intra-school quiz competition where classes compete against each other to be the best quiz team in their grade. In 2021, the name of this competition was changed to “Exquizite.” In order for the young Anandians who win this event to feel like true achievers, they too are awarded at the Mind Probe awarding ceremony.
                    </Text>
                </Center>
                
            </Container>
        </div>
    )
}

export default About
