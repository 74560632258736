/* eslint-disable no-unused-vars */
import {
    Heading,
    Link,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    Box,
    Input,
    Button,
    Radio, RadioGroup, VisuallyHidden, color,
} from '@chakra-ui/react'
import { Center, Container, Text, VStack, HStack } from '@chakra-ui/layout'
import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion'
import image from '../../Images/homeBG.jpg'
import { ChevronDownIcon } from '@chakra-ui/icons'
import ReactDOM from 'react-dom';
import { Formik, Field, Form } from 'formik';
import { db } from '../../Services/firebase';
import { doc, deleteDoc, setDoc, updateDoc, collection, } from "firebase/firestore";
import { DbContext } from '../../Services/context';
import { useToast } from '@chakra-ui/toast';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { getAllByTestId } from '@testing-library/react';
import { Select } from '@chakra-ui/select';
import './MindProbe_Registration.css';

const Registration = () => {
    const toast = useToast();

    const [tabIndex, setTabIndex] = useState(0)

    const handleTabsChange = (index) => {
        setTabIndex(index);
    }

    const [preValuesTic, setPreValuesTic] = useState(['', '']);
    const [preValuesCaptain, setPreValuesCaptain] = useState(['', '', '', '', '']);
    const [preValuesMem2, setPreValuesMem2] = useState(['', '', '', '', '']);
    const [preValuesMem3, setPreValuesMem3] = useState(['', '', '', '', '']);
    const [preValuesMem4, setPreValuesMem4] = useState(['', '', '', '', '']);


    // You can update the array using the setMyArray function.
    const updatePreValues = (arrayName, value1, value2, value3, value4, value5) => {
        if (arrayName === 'preValuesTic') {
            setPreValuesTic([value1, value2]);
        } else if (arrayName === 'preValuesCaptain') {
            setPreValuesCaptain([value1, value2, value3, value4, value5]);
        } else if (arrayName === 'preValuesMem2') {
            setPreValuesMem2([value1, value2, value3, value4, value5]);
        } else if (arrayName === 'preValuesMem3') {
            setPreValuesMem3([value1, value2, value3, value4, value5]);
        } else if (arrayName === 'preValuesMem4') {
            setPreValuesMem4([value1, value2, value3, value4, value5]);
        }
    };

    const handleCategoryChange = (index) => {
        const btn1 = document.getElementById('btn1');
        const btn2 = document.getElementById('btn2');
        const radio_placeholder = document.getElementById('placeHolder');
        radio_placeholder.checked = true;

        if (index === 'btn1') {
            btn1.className = 'btn_style2';
            btn2.className = 'btn_style1';
        } else {
            btn1.className = 'btn_style1';
            btn2.className = 'btn_style2';
        }
    }

    return (
        <Container id='mindprobe_register'>
            <div id='register_background'></div>
            <div id='register_wrapper'>
                <Container id='size_control' bgColor={"#ffffff"} borderRadius={"30px"} padding={'20px 50px'}>
                    <p style={{ textAlign: "center", fontSize: "40px", fontWeight: "bold", padding: "30px 0 10px 0" }}>Registration</p>
                    <p style={{ textAlign: "center", fontSize: "15px" }}>ALL QUESTIONS ARE REQUIRED. <a target='_blank' rel="noreferrer" id='links_registartion_form' href='/mindprobe/r&r'>SEE RULES & REGULATIONS</a>.</p>
                    <p style={{ textAlign: "center", fontSize: "15px", padding: "0 0 30px 0" }}>FOR ANY INQUIRY <a id='links_registartion_form' href='#mindprobe_contact'>CONTACT US</a>.</p>
                    <Formik
                        initialValues={{
                            email: '',
                            school: '',
                            category: '',
                            ticName: 'ticName',
                            ticTelNo: 'ticTelNo',
                            captainName: 'captainName',
                            captainEmail: 'captainEmail@gmail.com',
                            captainBday: '2006-01-01',
                            captainGrade: 'Grade 6',
                            captainTelNo: 'captainTelNo',
                            member2Name: 'member2Name',
                            member2Email: 'member2Email@gmail.com',
                            member2Bday: '2006-01-01',
                            member2Grade: 'Grade 6',
                            member2TelNo: 'member2TelNo',
                            member3Name: 'member3Name',
                            member3Email: 'member3Email@gmail.com',
                            member3Bday: '2006-01-01',
                            member3Grade: 'Grade 6',
                            member3TelNo: 'member3TelNo',
                            member4Name: 'member4Name',
                            member4Email: 'member4Email@gmail.com',
                            member4Bday: '2006-01-01',
                            member4Grade: 'Grade 6',
                            member4TelNo: 'member4TelNo',
                            submissionTime: '',
                        }}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            if (tabIndex === 5) {
                                try {
                                    const currentDate = new Date();

                                    const seconds = currentDate.getSeconds();
                                    const minutes = currentDate.getMinutes();
                                    const hours = currentDate.getHours();
                                    const date = currentDate.getDate();
                                    const month = currentDate.getMonth();
                                    const year = currentDate.getFullYear();

                                    const full_date = date + "/" + (month + 1) + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
                                    const full_date_2 = date + "-" + (month + 1) + "-" + year + "_" + hours + ":" + minutes + ":" + seconds;

                                    values.submissionTime = full_date;

                                    var document_ID = full_date_2 + "_" + values.email;

                                    setDoc(doc(db, "MindProbeRegistration", document_ID), values);
                                    setSubmitting(false);
                                    resetForm();
                                    const btn1 = document.getElementById('btn1');
                                    const btn2 = document.getElementById('btn2');
                                    btn1.className = 'btn_style1';
                                    btn2.className = 'btn_style1';
                                    const radio_placeholder = document.getElementById('placeHolder');
                                    radio_placeholder.checked = false;
                                    handleTabsChange(tabIndex + 1);
                                } catch (error) {
                                    toast({
                                        title: 'OOPS, something happened',
                                        description: error.message,
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                    setSubmitting(false);
                                }
                            } else {
                                if (tabIndex === 0) {
                                    values.ticName = preValuesTic[0];
                                    values.ticTelNo = preValuesTic[1];
                                } else if (tabIndex === 1) {
                                    values.captainName = preValuesCaptain[0];
                                    values.captainEmail = preValuesCaptain[1];
                                    values.captainBday = preValuesCaptain[2];
                                    values.captainGrade = preValuesCaptain[3];
                                    values.captainTelNo = preValuesCaptain[4];
                                } else if (tabIndex === 2) {
                                    values.member2Name = preValuesMem2[0];
                                    values.member2Email = preValuesMem2[1];
                                    values.member2Bday = preValuesMem2[2];
                                    values.member2Grade = preValuesMem2[3];
                                    values.member2TelNo = preValuesMem2[4];
                                } else if (tabIndex === 3) {
                                    values.member3Name = preValuesMem3[0];
                                    values.member3Email = preValuesMem3[1];
                                    values.member3Bday = preValuesMem3[2];
                                    values.member3Grade = preValuesMem3[3];
                                    values.member3TelNo = preValuesMem3[4];
                                } else if (tabIndex === 4) {
                                    values.member4Name = preValuesMem4[0];
                                    values.member4Email = preValuesMem4[1];
                                    values.member4Bday = preValuesMem4[2];
                                    values.member4Grade = preValuesMem4[3];
                                    values.member4TelNo = preValuesMem4[4];
                                }
                                handleTabsChange(tabIndex + 1)
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Tabs variant='soft-rounded' colorScheme='green' index={tabIndex} onChange={handleTabsChange}>
                                    <TabPanels>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <p style={{ fontSize: "25px", padding: "0 0 20px 0" }}><u>Basic Details</u></p>
                                                <label htmlFor="email">Email</label>
                                                <Input
                                                    name="email"
                                                    placeholder="Your Email"
                                                    type="email"
                                                    value={values.email} onChange={handleChange} onBlur={handleBlur}
                                                    isRequired
                                                />
                                                <label htmlFor="school">School</label>
                                                <Input name="school" placeholder="Your School" value={values.school} onChange={handleChange} onBlur={handleBlur} isRequired />
                                                <label>Category</label>
                                                <div id='btn_container'>
                                                    <Button id='btn1' class='btn_style1' onClick={() => {
                                                        handleCategoryChange('btn2');
                                                        values.category = 'Senior Category';
                                                        toast({
                                                            title: 'Junior Category Registration Closed',
                                                            description: "Registration for the Junior Category was done on a first come first serve basis due to there being limited slots available. But you can still register for the Senior Category",
                                                            status: 'info',
                                                            duration: 90000,
                                                            isClosable: true,
                                                        });
                                                    }}>Junior Category<br />[Registration Closed]<input type="radio" id="placeHolder" name="category" value="placeHolder" required /></Button>

                                                    <Button id='btn2' class='btn_style1' onClick={() => {
                                                        handleCategoryChange('btn2');
                                                        values.category = 'Senior Category';
                                                    }}>Senior Category</Button>
                                                </div>
                                                <br />
                                                <div id='action_btn_container'>
                                                    <Button type="submit" disabled={isSubmitting}>Next</Button></div>
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <p style={{ fontSize: "25px", padding: "0 0 20px 0" }}><u>Master-In-Charge Details</u></p>
                                                <label htmlFor="ticName">Full Name</label>
                                                <Input name="ticName" placeholder="Your Answer" value={values.ticName} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label htmlFor="ticTelNo">Phone No. (Whatsapp)</label>
                                                <Input name="ticTelNo" placeholder="Your Answer" value={values.ticTelNo} onChange={handleChange} onBlur={handleBlur} isRequired />
                                                <br />
                                                <div id='action_btn_container'>
                                                    <Button onClick={() => {
                                                        updatePreValues('preValuesTic', values.ticName, values.ticTelNo, "", "", "");
                                                        values.ticName = 'ticName';
                                                        values.ticTelNo = 'ticTelNo';
                                                        handleTabsChange(tabIndex - 1);
                                                    }}>Previous</Button>
                                                    <Button type="submit" disabled={isSubmitting}>Next</Button></div>
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <p style={{ fontSize: "25px", padding: "0 0 20px 0" }}><u>Captain's Details</u> (Member 1)</p>
                                                <label htmlFor="captainName">Full Name</label>
                                                <Input name="captainName" placeholder="Your Answer" value={values.captainName} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label htmlFor="email">Email</label>
                                                <Input
                                                    name="captainEmail"
                                                    placeholder="Your Answer"
                                                    type="email"
                                                    value={values.captainEmail} onChange={handleChange} onBlur={handleBlur}
                                                    isRequired
                                                />

                                                <label htmlFor="captainBday">Birthday</label>
                                                <Input type="date" name="captainBday" min="2004-01-31" max="2013-01-31" value={values.captainBday} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label htmlFor="captainGrade">Grade</label>
                                                <Select
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="captainGrade"
                                                    value={values.captainGrade}
                                                    placeholder='Select Grade'
                                                    isRequired
                                                >
                                                    <option value="Grade 6">Grade 6</option>
                                                    <option value="Grade 7">Grade 7</option>
                                                    <option value="Grade 8">Grade 8</option>
                                                    <option value="Grade 9">Grade 9</option>
                                                    <option value="Grade 10">Grade 10</option>
                                                    <option value="Grade 11">Grade 11</option>
                                                    <option value="Grade 12">Grade 12</option>
                                                    <option value="Grade 13">Grade 13</option>
                                                </Select>

                                                <label htmlFor="captainTelNo">Phone No. (Whatsapp)</label>
                                                <Input name="captainTelNo" placeholder="Your Answer" value={values.captainTelNo} onChange={handleChange} onBlur={handleBlur} isRequired />
                                                <br />
                                                <div id='action_btn_container'>
                                                    <Button onClick={() => {
                                                        updatePreValues('preValuesCaptain', values.captainName, values.captainEmail, values.captainBday, values.captainGrade, values.captainTelNo);
                                                        values.captainName = 'captainName';
                                                        values.captainEmail = 'captainEmail@gmail.com';
                                                        values.captainBday = '2006-01-01';
                                                        values.captainGrade = 'Grade 6';
                                                        values.captainTelNo = 'captainTelNo';
                                                        handleTabsChange(tabIndex - 1);
                                                    }}>Previous</Button>
                                                    <Button type="submit" disabled={isSubmitting}>Next</Button></div>
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <p style={{ fontSize: "25px", padding: "0 0 20px 0" }}><u>Member 2 Details</u></p>
                                                <label htmlFor="member2Name">Full Name</label>
                                                <Input name="member2Name" placeholder="Your Answer" value={values.member2Name} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label htmlFor="email">Email</label>
                                                <Input
                                                    name="member2Email"
                                                    placeholder="Your Answer"
                                                    type="email"
                                                    value={values.member2Email} onChange={handleChange} onBlur={handleBlur}
                                                    isRequired
                                                />

                                                <label htmlFor="member2Bday">Birthday</label>
                                                <Input type="date" name="member2Bday" min="2004-01-31" max="2013-01-31" value={values.member2Bday} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label htmlFor="member2Grade">Grade</label>
                                                <Select
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="member2Grade"
                                                    value={values.member2Grade}
                                                    placeholder='Select Grade'
                                                    isRequired
                                                >
                                                    <option value="Grade 6">Grade 6</option>
                                                    <option value="Grade 7">Grade 7</option>
                                                    <option value="Grade 8">Grade 8</option>
                                                    <option value="Grade 9">Grade 9</option>
                                                    <option value="Grade 10">Grade 10</option>
                                                    <option value="Grade 11">Grade 11</option>
                                                    <option value="Grade 12">Grade 12</option>
                                                    <option value="Grade 13">Grade 13</option>
                                                </Select>

                                                <label htmlFor="member2TelNo">Phone No. (Whatsapp)</label>
                                                <Input name="member2TelNo" placeholder="Your Answer" value={values.member2TelNo} onChange={handleChange} onBlur={handleBlur} isRequired />
                                                <br />
                                                <div id='action_btn_container'>
                                                    <Button onClick={() => {
                                                        updatePreValues('preValuesMem2', values.member2Name, values.member2Email, values.member2Bday, values.member2Grade, values.member2TelNo);
                                                        values.member2Name = 'member2Name';
                                                        values.member2Email = 'member2Email@gmail.com';
                                                        values.member2Bday = '2006-01-01';
                                                        values.member2Grade = 'Grade 6';
                                                        values.member2TelNo = 'member2TelNo';
                                                        handleTabsChange(tabIndex - 1);
                                                    }}>Previous</Button>
                                                    <Button type="submit" disabled={isSubmitting}>Next</Button></div>
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <p style={{ fontSize: "25px", padding: "0 0 20px 0" }}><u>Member 3 Details</u></p>
                                                <label htmlFor="member3Name">Full Name</label>
                                                <Input name="member3Name" placeholder="Your Answer" value={values.member3Name} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label htmlFor="email">Email</label>
                                                <Input
                                                    name="member3Email"
                                                    placeholder="Your Answer"
                                                    type="email"
                                                    value={values.member3Email} onChange={handleChange} onBlur={handleBlur}
                                                    isRequired
                                                />

                                                <label htmlFor="member3Bday">Birthday</label>
                                                <Input type="date" name="member3Bday" min="2004-01-31" max="2013-01-31" value={values.member3Bday} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label htmlFor="member3Grade">Grade</label>
                                                <Select
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="member3Grade"
                                                    value={values.member3Grade}
                                                    placeholder='Select Grade'
                                                    isRequired
                                                >
                                                    <option value="Grade 6">Grade 6</option>
                                                    <option value="Grade 7">Grade 7</option>
                                                    <option value="Grade 8">Grade 8</option>
                                                    <option value="Grade 9">Grade 9</option>
                                                    <option value="Grade 10">Grade 10</option>
                                                    <option value="Grade 11">Grade 11</option>
                                                    <option value="Grade 12">Grade 12</option>
                                                    <option value="Grade 13">Grade 13</option>
                                                </Select>

                                                <label htmlFor="member3TelNo">Phone No. (Whatsapp)</label>
                                                <Input name="member3TelNo" placeholder="Your Answer" value={values.member3TelNo} onChange={handleChange} onBlur={handleBlur} isRequired />
                                                <br />
                                                <div id='action_btn_container'>
                                                    <Button onClick={() => {
                                                        updatePreValues('preValuesMem3', values.member3Name, values.member3Email, values.member3Bday, values.member3Grade, values.member3TelNo);
                                                        values.member3Name = 'member3Name';
                                                        values.member3Email = 'member3Email@gmail.com';
                                                        values.member3Bday = '2006-01-01';
                                                        values.member3Grade = 'Grade 6';
                                                        values.member3TelNo = 'member3TelNo';
                                                        handleTabsChange(tabIndex - 1);
                                                    }}>Previous</Button>
                                                    <Button type="submit" disabled={isSubmitting}>Next</Button></div>
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <p style={{ fontSize: "25px", padding: "0 0 20px 0" }}><u>Member 4 Details</u></p>
                                                <label htmlFor="member4Name">Full Name</label>
                                                <Input name="member4Name" placeholder="Your Answer" value={values.member4Name} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label htmlFor="email">Email</label>
                                                <Input
                                                    name="member4Email"
                                                    placeholder="Your Answer"
                                                    type="email"
                                                    value={values.member4Email} onChange={handleChange} onBlur={handleBlur}
                                                    isRequired
                                                />

                                                <label htmlFor="member4Bday">Birthday</label>
                                                <Input type="date" name="member4Bday" min="2004-01-31" max="2013-01-31" value={values.member4Bday} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label htmlFor="member4Grade">Grade</label>
                                                <Select
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="member4Grade"
                                                    value={values.member4Grade}
                                                    placeholder='Select Grade'
                                                    isRequired
                                                >
                                                    <option value="Grade 6">Grade 6</option>
                                                    <option value="Grade 7">Grade 7</option>
                                                    <option value="Grade 8">Grade 8</option>
                                                    <option value="Grade 9">Grade 9</option>
                                                    <option value="Grade 10">Grade 10</option>
                                                    <option value="Grade 11">Grade 11</option>
                                                    <option value="Grade 12">Grade 12</option>
                                                    <option value="Grade 13">Grade 13</option>
                                                </Select>

                                                <label htmlFor="member4TelNo">Phone No. (Whatsapp)</label>
                                                <Input name="member4TelNo" placeholder="Your Answer" value={values.member4TelNo} onChange={handleChange} onBlur={handleBlur} isRequired />
                                                <br />
                                                <div id='action_btn_container'>
                                                    <Button onClick={() => {
                                                        updatePreValues('preValuesMem4', values.member4Name, values.member4Email, values.member4Bday, values.member4Grade, values.member4TelNo);
                                                        values.member4Name = 'member4Name';
                                                        values.member4Email = 'member4Email@gmail.com';
                                                        values.member4Bday = '2006-01-01';
                                                        values.member4Grade = 'Grade 6';
                                                        values.member4TelNo = 'member4TelNo';
                                                        handleTabsChange(tabIndex - 1);
                                                    }}>Previous</Button>
                                                    <Button type="submit" disabled={isSubmitting}>Submit</Button></div>
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <VStack>
                                                <p style={{ textAlign: "center" }}>---<br />Thanks for registering a team for MIND PROBE 2023.<br />All the team members of the team will be added to a WhatsApp group in few days.<br />---</p>
                                                <br />
                                                <Button style={{ padding: "25px 30px" }} onClick={() => {
                                                    handleTabsChange(0);
                                                }}>Submit Another Response</Button>
                                            </VStack>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Form>)}
                    </Formik>
                </Container >
            </div>
        </Container>
    )
}

export default Registration
