import { Container, Text, Wrap } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import React from 'react'

import { DbContext } from '../../Services/context'
import { useContext } from 'react'

const SubProjects = () => {
    const { projects } = useContext(DbContext)
    return (
        <div>
            <Wrap justify="space-between" spacing="5vw" >
                {projects.map(project => {
                    if (project.buttonText === undefined || project.buttonText === null) {
                        return (
                            <Container id={project.title} maxW={["90vw", "80vw", "45vw", "35vw"]} backgroundImage={project.image} backgroundSize="cover" bgPos="center" backgroundColor="blackAlpha.600" backgroundBlendMode="overlay" borderRadius="2xl" border="2px white" borderStyle="solid">
                                <Text fontSize="3xl" fontWeight="bold" color="white" p="30px" mt="80px">
                                    {project.title}
                                </Text>

                                <Text p="0 30px 40px 30px" color="gray.200" fontSize="xl">
                                    {project.description}
                                </Text>
                            </Container>
                        )
                    }
                    else {
                        return (
                            <Container id={project.title} maxW={["90vw", "80vw", "45vw", "35vw"]} backgroundImage={project.image} backgroundSize="cover" bgPos="center" backgroundColor="blackAlpha.600" backgroundBlendMode="overlay" borderRadius="2xl" border="2px white" borderStyle="solid">
                                <Text fontSize="3xl" fontWeight="bold" color="white" p="30px" mt="80px">
                                    {project.title}
                                </Text>

                                <Text p="0 30px 40px 30px" color="gray.200" fontSize="xl">
                                    {project.description}
                                </Text>
                                <a href={project.buttonLink}>
                                    <Button bg="none" color="white" border="1px #FFFFFF80" borderStyle="dashed" _hover={{ bg: "#000000" }} m="0 30px 40px 30px" p="25px" fontSize="xl">{project.buttonText}</Button>
                                </a>
                            </Container>
                        )
                    }
                })}
            </Wrap>
        </div>
    )
}

export default SubProjects
