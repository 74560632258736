import React, { useContext, useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
    Input, Button, IconButton,
    HStack,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react';
import { db } from '../../Services/firebase';
import { doc, setDoc, deleteDoc } from "firebase/firestore";
import { useToast } from '@chakra-ui/toast';
import { Container, Text } from '@chakra-ui/layout';
import { DbContext } from '../../Services/context';
import { DeleteIcon } from '@chakra-ui/icons';
import Nav from './Menu';

const TiCUpdate = () => {
    useEffect(() => {
        document.title = "ACQC - Admin | TIC Update"
    });

    let index = 0;
    const toast = useToast();

    const [deleteRef, setDeleteRef] = useState('');
    const { teachers } = useContext(DbContext);

    const [isOpenAlert, setIsOpen] = React.useState(false)
    const onCloseAlert = () => {
        setIsOpen(false);
        setDeleteRef('');
    }
    const cancelRef = React.useRef()

    const deleteRank = async () => {
        try {
            await deleteDoc(doc(db, "teachers", deleteRef));
            toast({
                title: "Teacher Deleted",
                description: `Teacher ${deleteRef} has been deleted`,
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
        }

        catch (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        setIsOpen(false)
    }

    const handleDelete = async (rankName) => {

        setDeleteRef(rankName);
        console.log(rankName)
        setIsOpen(true)

    }


    return (
        <>
            <Nav />
            <AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Teacher
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={deleteRank} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Container>
                <Container maxW="90vw" p="50px 50px" display="flex" flexDir="column" alignItems="center" justifyContent="center" m="0" borderRadius="2xl">

                    <Text fontSize="3xl" mb="50px" textAlign="center" w="90vw" fontWeight="semibold">Update Teachers in Charge</Text>

                    <Formik
                        initialValues={{ name: '', role: 'teacher' }}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            try {

                                setDoc(doc(db, "teachers", values.name), values);

                                toast({
                                    title: 'Teacher-in-Charge updated',
                                    description: 'New Teacher-in-Charge has been added to the database and the website is now updated.',
                                    status: 'success',
                                    duration: 5000,
                                    isClosable: true,
                                });

                            }

                            catch (error) {
                                toast({
                                    title: 'OOPS, something happened',
                                    description: error.message,
                                    status: 'error',
                                    duration: 9000,
                                    isClosable: true,
                                });
                            }

                            setSubmitting(false);

                            resetForm();
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <HStack spacing={4} w="90vw">
                                    <Input
                                        type="text"
                                        name="name"
                                        w="100%"
                                        placeholder="Teacher Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    <Button type="submit" disabled={isSubmitting} mt="20px">
                                        Add Teacher
                                    </Button>
                                </HStack>
                            </form>
                        )}
                    </Formik>

                    <Formik
                        initialValues={{ name: '', role: 'main-teacher', image: '' }}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            try {

                                setDoc(doc(db, "teachers", 'mainTiC'), values);

                                toast({
                                    title: 'Main Teacher-in-Charge updated',
                                    description: 'New Main Teacher-in-Charge has been added to the database and the website is now updated.',
                                    status: 'success',
                                    duration: 5000,
                                    isClosable: true,
                                });

                            }

                            catch (error) {
                                toast({
                                    title: 'OOPS, something happened',
                                    description: error.message,
                                    status: 'error',
                                    duration: 9000,
                                    isClosable: true,
                                });
                            }

                            setSubmitting(false);

                            resetForm();
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>

                                <HStack spacing={4} w="90vw" mt="30px">
                                    <Input
                                        type="text"
                                        name="name"
                                        w="50%"
                                        placeholder="Main Teacher's Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    <Input
                                        type="text"
                                        name="image"
                                        w="50%"
                                        placeholder="Main Teacher's Image"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.image}
                                    />
                                    <Button type="submit" disabled={isSubmitting} mt="20px">
                                        Update Main Teacher
                                    </Button>
                                </HStack>


                            </form>
                        )}
                    </Formik>

                    <Formik
                        initialValues={{ name: '', role: 'principal', image: '' }}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            try {

                                setDoc(doc(db, "teachers", 'principal'), values);

                                toast({
                                    title: 'Principal updated',
                                    description: 'New Principal has been added to the database and the website is now updated.',
                                    status: 'success',
                                    duration: 5000,
                                    isClosable: true,
                                });

                            }

                            catch (error) {
                                toast({
                                    title: 'OOPS, something happened',
                                    description: error.message,
                                    status: 'error',
                                    duration: 9000,
                                    isClosable: true,
                                });
                            }

                            setSubmitting(false);

                            resetForm();
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>

                                <HStack spacing={4} w="90vw" mt="30px">
                                    <Input
                                        type="text"
                                        name="name"
                                        w="50%"
                                        placeholder="Principal's Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    <Input
                                        type="text"
                                        name="image"
                                        w="50%"
                                        placeholder="Principal's Image"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.image}
                                    />
                                    <Button type="submit" disabled={isSubmitting} mt="20px">
                                        Update Principal
                                    </Button>
                                </HStack>


                            </form>
                        )}
                    </Formik>

                </Container>

            </Container>
            <Container maxW="90vw" p="0" mt="50px">
                {teachers && teachers.map(teacher => {
                    index += 1;
                    return (
                        <Container maxW="90vw" bg="gray.100" p="15px" mb="10px" borderRadius="10px" fontSize="18px">
                            <HStack>
                                <Text mr="50px" ml="20px">{index}</Text>
                                <Text mr="50px" w="90vw">{teacher.name}</Text>

                                <IconButton value={teacher.name} position="absolute" right="8vw" icon={<DeleteIcon textColor="gray.600" _hover={{ color: "white" }} />} bg="white" onClick={() => {
                                    handleDelete(teacher.name)
                                }} />
                            </HStack>
                        </Container>
                    )

                })}
            </Container>
        </>
    )
};

export default TiCUpdate;