import React, { useContext, useEffect } from 'react'
import Header from '../Components/Mindprobe/Header'
import Registration from '../Components/Mindprobe/Registration'
import Registration_Closed from '../Components/Mindprobe/Registration_Closed'
import About from '../Components/Mindprobe/About'
import Contact from '../Components/Mindprobe/Contact'
import Footer from '../Components/Mindprobe/Footer'
import { DbContext } from '../Services/context';

const Mindprobe = () => {
    useEffect (()=>{
        document.title = "ACQC - MindProbe23"
    });

    const { details } = useContext(DbContext);
    let registration_open = "";

    // eslint-disable-next-line array-callback-return
    details && details.map(details => {
        registration_open = details.registration_open;
    })

    const function1 = () => {
        if(registration_open === "Yes"){
            return(<Registration />);
        }else if(registration_open === "No"){
            // eslint-disable-next-line react/jsx-pascal-case
            return(<Registration_Closed />);
        }
    }

    return (
        <div>
            <Header />
            <About />
            {function1()}
            <Contact />
            <Footer/>
        </div>
    )
}

export default Mindprobe
