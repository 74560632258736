// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react"
import { Button } from "../Components/Chakra-Components/Button"

const theme = extendTheme({
  colors: {
    brand: {
      100: "#f7fafc",
      // ...
      900: "#1a202c",
    },
    },

    fonts: {
        body: "DM Sans, sans-serif",
        heading: "Trajan Pro",
    },
    
    components: {
    Button,
  },
})

export default theme