/* eslint-disable no-unused-vars */
import {
    Heading,
    Link,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    Box,
    Input,
    Button,
    Radio, RadioGroup, VisuallyHidden, color,
} from '@chakra-ui/react'
import { Center, Container, Text, VStack, HStack } from '@chakra-ui/layout'
import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion'
import image from '../../Images/homeBG.jpg'
import { ChevronDownIcon } from '@chakra-ui/icons'
import ReactDOM from 'react-dom';
import { Formik, Field, Form } from 'formik';
import { db } from '../../Services/firebase';
import { doc, deleteDoc, setDoc, updateDoc, collection, } from "firebase/firestore";
import { DbContext } from '../../Services/context';
import { useToast } from '@chakra-ui/toast';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { getAllByTestId } from '@testing-library/react';
import { Select } from '@chakra-ui/select';
import './MindProbe_Registration.css';

const Registration_Closed = () => {
    return (
        <Container id='mindprobe_register'>
            <div id='register_background'></div>
            <div id='register_wrapper'>
                <Container id='size_control' bgColor={"#ffffff"} borderRadius={"30px"} padding={'20px 50px'}>
                    <p style={{ textAlign: "center", fontSize: "40px", fontWeight: "bold", padding: "30px 0 10px 0" }}>Registration</p>
                    <p style={{ textAlign: "center", fontSize: "15px", padding: "0 0 30px 0" }}>FOR ANY INQUIRY <a id='links_registartion_form' href='#mindprobe_contact'>CONTACT US</a>.</p>
                    <VStack>
                        <p style={{ textAlign: "center" }}>---<br />MindProbe23 Successfully Concluded<br/><br/><a href='/mindprobe/results'><Button>View Results</Button></a><br />---</p>
                    </VStack>
                </Container >
            </div>
        </Container>
    )
}

export default Registration_Closed
