import React, { useContext, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from './Services/context'

const PrivateRoute = ({component: Component, ...rest}) => {
    const { user } = useContext(AuthContext)
    
    useEffect(() => {
        console.log(user, "CHANGED!!!!!!!!!") 
    }, [user])
    
    return (
    <>
        {user && <Route {...rest}
        render={props => {
            return user ? <Component {...props} /> : <Redirect to={{ pathname: "/login" }} />
        }}
        />}
</>
    )
}

export default PrivateRoute
