import { Center, Container } from '@chakra-ui/layout'
import React from 'react'
import MainProjects from './MainProjects'
import SubProjects from './SubProjects'
import Footer from '../Footer'

const ProjectsContainer = () => {
    return (
        <div>
            <Container maxW="100vw" bg='gray.900' p="10vw">
                <Center flexDir="column">
                    <MainProjects />
                    <SubProjects />
                </Center>
            </Container>
            <Footer color="white" />
        </div>
    )
}

export default ProjectsContainer
