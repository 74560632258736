import React, { useEffect } from 'react'
import Header from '../Components/Home/Header'
import About from '../Components/Home/About'
import TiC from '../Components/Home/TiC'
import Board from '../Components/Home/Board'
import Footer from '../Components/Footer'
import Nav from '../Components/Nav'

const Home = () => {
    useEffect (()=>{
        document.title = "ACQC"
    });
    
    return (
        <div>
            {/* <a href="/nenasa">
            <Alert
                status='success'
                variant='subtle'
                flexDirection='row'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                height='50px'
            >
                <AlertIcon mr={3} />
                <AlertDescription maxWidth='sm' mr={4}>
                    Anada Nenasa Results Released.
                </AlertDescription>
                <Button bg="white" color="black" fontSize="sm" h="20px" _hover={{  }}>View</Button>
            </Alert></a> */}
            <Nav />
            <Header />
            <About />
            <TiC />
            <Board />
            <Footer color="white" />
        </div>
    )
}

export default Home
