import {
    Heading, Link, keyframes, usePrefersReducedMotion
} from '@chakra-ui/react'
import { Center, Container, Text } from '@chakra-ui/layout'
import React from 'react'
import { motion } from 'framer-motion'
import image from '../../Images/homeBG.jpg'
import { ChevronDownIcon } from '@chakra-ui/icons'

const Header = () => {
    const prefersReducedMotion = usePrefersReducedMotion()
    const bg = keyframes`
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }`
    const animation = prefersReducedMotion
        ? undefined
        : `${bg} infinite 60s linear`

    return (
        <div>
            <Container maxW="100%" h="calc(100vh - 0px)" bg="gray.50" bgImage={image} bgSize="300%" animation={animation}> {/* New Message */}
                <Center h="95vh" flexDirection="column">
                    <Heading

                        textAlign="center" fontSize={['4xl', '5xl', '8xl']}> <motion.div animate={{
                            scale: [0.1, 1.1, 1]
                        }}
                            transition={{ duration: 2 }}>ANANDA COLLEGE <br /> QUIZ CLUB</motion.div>  </Heading>
                    <Heading fontSize={['xl', '2xl', '3xl']} textColor="gray.700" mt="20px">
                        <motion.div animate={{ opacity: [0, 1] }} transition={{ duration: 0.5, delay: 2 }}>Errand from the unique</motion.div>
                    </Heading>
                </Center>
                <motion.div animate={{ y: [100, 0] }} transition={{ staggerChildren: 0.5 }}>
                    <Center flexDirection="column" mt="0px"> {/* New Message */}
                        <Link h="0" href="#about" cursor="pointer" transition="0.5s all ease" _hover={{ transform: "translateY(10px)" }}><ChevronDownIcon mt="-100px" fontSize="7xl" textColor="gray.700" /></Link>
                        <Text fontSize={['xl', '2xl', '3xl']} mt="-15px" textColor="gray.600">More About Us</Text>
                    </Center>
                </motion.div>
            </Container>
        </div>
    )
}

export default Header
