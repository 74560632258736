import React, { useEffect, useState } from 'react';
import { useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, Button, Center, Box } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import logo from '../../../src/Images/navLogo.png';
import './MindProbe_Home.css';
import { useToast } from '@chakra-ui/toast';

const Header = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [placement, setPlacement] = React.useState("right");
    const handlePlacementChange = event => setPlacement(event.target.value);

    const toast = useToast();
    const [toastShown, setToastShown] = useState(false);

    useEffect(() => {
        const handleLoad = () => {
            if (!toastShown) {
                toast({
                    status: 'info',
                    colorScheme: 'green',
                    title: 'MindProbe23 Successfully Concluded',
                    description: 'With a Groundbreaking New Record for the Largest Quiz Day organized by a School in Sri Lanka. With a Grand Total of 95 Teams & 70 Schools.',
                    duration: 100000,
                    isClosable: true,
                    onCloseComplete: () => {
                        setToastShown(true);
                    },
                });
            }
        };

        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, [toast, toastShown]);

    return (
        <div id='mindprobe_home'>
            <div id='main'>
                <div id='main_nav_container'>
                    <div id='main_nav_logo'>
                        <img src={logo} alt="logo" />
                    </div>
                    <div id='main_nav_links'>
                        <a href='/'>Home</a>
                        <a href='/history'>History</a>
                        <a href='/achievements'>Achievements</a>
                        <a href='/projects'>Projects</a>
                        <a href='/squad'>Squad</a>
                        <a href='/mindprobe'><b>MindProbe</b></a>
                        <HamburgerIcon onClick={onOpen} id='hamburgerIcon' />
                    </div>
                </div>
                <div id='container'>
                    <div id='nav'>
                        <img id='logo_nav' src='https://lh3.googleusercontent.com/d/1-7-DqsnJYKF-u0nuxUSEhiJMipnDgzh5=w1920-h878-iv1' alt=''></img>
                        <a href='#mindprobe_home'>HOME</a>
                        <a href='#mindprobe_about'>ABOUT</a>
                        <a href='#galleryAndSouvenir'>SOUVENIR</a>
                        <a href='#galleryAndSouvenir'>GALLERY</a>
                        <a href='#mindprobe_contact'>CONTACT</a>
                        {/* <a href='#mindprobe_register' id='button_register'>REGISTER</a> */}
                        <a href='/mindprobe/results' id='button_register'>RESULTS</a>
                    </div>
                    <div id='line'></div>
                    <div id='content'>
                        <div id='logo_container'>
                            <img id='logo_main' src='https://lh3.googleusercontent.com/d/1-7-DqsnJYKF-u0nuxUSEhiJMipnDgzh5=w1920-h878-iv1' alt=''></img>
                            <img id='logo_stroke' src='https://lh3.googleusercontent.com/d/1m-w5ZqMdd3FTZSKZ1SpSLgSF2ZXfSBqK=w1920-h878-iv1' alt=''></img>
                        </div>
                        <div id='home_text_container'>
                            <p>THE ULTIMATE ODYSSEY FOR UNIQUE EXCELLENCE</p>
                            <p>THE LARGEST QUIZ DAY ORGANIZED BY A SCHOOL IN SRI LANKA</p><br />
                            <p>MINDPROBE'23 SUCCESSFULLY CONCLUDED.</p>
                        </div>
                        <div id='button_container'>
                            {/* <a href='#mindprobe_register' id='button_register'>REGISTER</a> */}
                            <a href='/mindprobe/results' id='button_register'>VIEW RESULTS</a>
                            <a target='_blank' id='button_rr' href='https://drive.google.com/drive/folders/12v1I2CSLPsm8ubB2M9W1WM62uMOO33Zx?usp=sharing' rel="noreferrer">QUESTIONS</a>
                            {/* <a target='_blank' id='button_rr' href='/mindprobe/r&r'>RULES &<br />REGULATIONS</a> */}
                        </div>
                    </div>
                </div>
            </div>
            <Drawer placement='top' onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader borderBottomWidth="1px">
                        <div id='mindprobe_mobile_nav_header'>
                            <img src={logo} alt="logo" />
                            <CloseIcon onClick={onClose} id='hamburgerIcon' />
                        </div>
                    </DrawerHeader>
                    <DrawerBody id='mindprobe_mobile_nav_items_container'>
                        <a href='/'>Home</a><br />
                        <a href='/history'>History</a><br />
                        <a href='/achievements'>Achievements</a><br />
                        <a href='/projects'>Projects</a><br />
                        <a href='/squad'>Squad</a><br />
                        <div id='mindprobe_mobile_nav_items'>
                            <a href='/mindprobe' id='current'><b>MindProbe</b></a><br />
                            <a href='#mindprobe_home' onClick={onClose}>Home</a><br />
                            <a href='#mindprobe_about' onClick={onClose}>About</a><br />
                            <a href='#galleryAndSouvenir' onClick={onClose}>Souvenir</a><br />
                            <a href='#galleryAndSouvenir' onClick={onClose}>Gallery</a><br />
                            <a href='#mindprobe_contact' onClick={onClose}>Contact</a><br />
                            {/* <a href='#mindprobe_register' id='mindprobe_mobile_nav_register_border' onClick={onClose}>REGISTER</a> */}
                            <a href='/mindprobe/results' id='mindprobe_mobile_nav_register_border' onClick={onClose}>RESULTS</a>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    )
}

export default Header
