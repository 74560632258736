import React, { useContext, useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
    Input, Button, VStack, AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/react';
import { db } from '../../Services/firebase';
import { doc, deleteDoc, setDoc, updateDoc, } from "firebase/firestore";
import { useToast } from '@chakra-ui/toast';
import { Container, HStack, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { DbContext } from '../../Services/context';
import Nav from './Menu';
import { IconButton } from '@chakra-ui/button';
import { CheckIcon, DeleteIcon } from '@chakra-ui/icons';

const SquadUpdate = () => {
    useEffect(() => {
        document.title = "ACQC - Admin | Squad Update"
    });

    const toast = useToast();

    const { quizTeams } = useContext(DbContext);

    const [deleteRef, setDeleteRef] = useState('');

    const [isOpenAlert, setIsOpen] = React.useState(false)
    const onCloseAlert = () => {
        setIsOpen(false);
        setDeleteRef('');
    }
    const cancelRef = React.useRef()

    // const [menuOptions, setMenuOptions] = useState([{ 'Senior Captain' }, { value: 'Senior Vice Captain' }, { value: 'Senior Team' }, { value: 'Senior Squad' }, { value: 'Junior Captain' }, { value: 'Junior Vice Captain' }, { value: 'Junior Team' }, { value: 'Junior Squad' }]);

    const deleteMember = async () => {
        try {
            await deleteDoc(doc(db, "QuizTeam", deleteRef));
            toast({
                title: "Member Deleted",
                description: `Member ${deleteRef} has been deleted`,
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
        }

        catch (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        setIsOpen(false)
    }

    const handleDelete = async (name) => {
        setDeleteRef(name);
        console.log(name)
        setIsOpen(true)
    }

    // eslint-disable-next-line no-unused-vars
    let i = 0;
    return (
        <>
            <Button onClick={() => {
                try {
                    updateDoc(doc(db, "details", "details"), {
                        registration_open: "No"
                    });

                    toast({
                        title: 'Success',
                        description: '',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                } catch (error) {
                    toast({
                        title: 'OOPS, something happened',
                        description: error.message,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }}>Close Registration</Button>
            <Button onClick={() => {
                try {
                    updateDoc(doc(db, "details", "details"), {
                        registration_open: "Yes"
                    });

                    toast({
                        title: 'Success',
                        description: '',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                } catch (error) {
                    toast({
                        title: 'OOPS, something happened',
                        description: error.message,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }}>Open Registration</Button>
            <AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete member?
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={deleteMember} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <Container maxW="100%" m="0" p="0" overflow="hidden"><Nav /></Container>
            <Container maxW="100%" m="0" p="0" overflow="hidden">

                <Container maxW="100%" p="50px 50px" display="flex" flexDir="column" alignItems="center" justifyContent="center" m="0" borderRadius="2xl" bg="white" overflow="hidden">
                    <Text fontSize="3xl" mb="50px" fontWeight="semibold">Update Quiz Teams</Text>

                    <Accordion defaultIndex={[0]} allowToggle w="90vw">
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left'>
                                        Quiz Team Details Update
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Formik
                                    initialValues={{ name: '', grade: '', juniorRole: '', seniorRole: '' }}
                                    validate={values => {
                                        const errors = {};
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        if (values.juniorRole === '' && values.seniorRole === '') {
                                            alert("You Have To Atleast Select One Role Value");
                                            setSubmitting(false);
                                        } else {
                                            try {
                                                setDoc(doc(db, "QuizTeam", values.name), values);
                                                toast({
                                                    title: 'Team member updated',
                                                    description: '',
                                                    status: 'success',
                                                    duration: 5000,
                                                    isClosable: true,
                                                });
                                                setSubmitting(false);
                                                resetForm();
                                                values.img = '';
                                            } catch (error) {
                                                toast({
                                                    title: 'OOPS, something happened',
                                                    description: error.message,
                                                    status: 'error',
                                                    duration: 9000,
                                                    isClosable: true,
                                                });
                                                setSubmitting(false);
                                            }
                                        }
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        /* and other goodies */
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <VStack>
                                                <HStack spacing={4} w="90vw">
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        w="83vw"
                                                        placeholder="Student Name [eg : John Doe]"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                        isRequired
                                                    />
                                                    <Select
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="grade"
                                                        w="40vw"
                                                        value={values.grade}
                                                        placeholder='Select Grade'
                                                        isRequired
                                                    >
                                                        <option value="Grade 4">Grade 4</option>
                                                        <option value="Grade 5">Grade 5</option>
                                                        <option value="Grade 6">Grade 6</option>
                                                        <option value="Grade 7">Grade 7</option>
                                                        <option value="Grade 8">Grade 8</option>
                                                        <option value="Grade 9">Grade 9</option>
                                                        <option value="Grade 10">Grade 10</option>
                                                        <option value="Grade 11">Grade 11</option>
                                                        <option value="Post O/L">Post O/L</option>
                                                        <option value="Grade 12">Grade 12</option>
                                                        <option value="Grade 13">Grade 13</option>
                                                    </Select>
                                                    <Select
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="seniorRole"
                                                        w="40vw"
                                                        value={values.seniorRole}
                                                        placeholder='Senior Role (if applicable)'
                                                    >
                                                        <option value="Senior Captain">Senior Captain</option>
                                                        <option value="Senior Vice Captain">Senior Vice Captain</option>
                                                        <option value="Senior Team">Senior Team</option>
                                                        <option value="Senior Squad">Senior Squad</option>
                                                    </Select>
                                                    <Select
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="juniorRole"
                                                        w="40vw"
                                                        value={values.juniorRole}
                                                        placeholder='Junior Role (if applicable)'
                                                    >
                                                        <option value="Junior Captain">Junior Captain</option>
                                                        <option value="Junior Vice Captain">Junior Vice Captain</option>
                                                        <option value="Junior Team">Junior Team</option>
                                                        <option value="Junior Squad">Junior Squad</option>
                                                    </Select>
                                                    <Button type="submit" disabled={isSubmitting} mt="20px" bgColor="gray.700" px="40px">
                                                        Submit
                                                    </Button>
                                                </HStack>
                                            </VStack>
                                        </form>
                                    )}
                                </Formik>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left'>
                                        Captain / Vice Captain Photo Update
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Formik
                                    initialValues={{ name: '', img: '' }}
                                    validate={values => {
                                        const errors = {};
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        try {
                                            updateDoc(doc(db, "QuizTeam", values.name), {
                                                img: values.img
                                            });

                                            toast({
                                                title: 'Squad member updated',
                                                description: '',
                                                status: 'success',
                                                duration: 5000,
                                                isClosable: true,
                                            });
                                            setSubmitting(false);
                                            resetForm();
                                        } catch (error) {
                                            toast({
                                                title: 'OOPS, something happened',
                                                description: error.message,
                                                status: 'error',
                                                duration: 9000,
                                                isClosable: true,
                                            });
                                            setSubmitting(false);
                                        }
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <VStack>
                                                <HStack spacing={4} w="90vw">
                                                    <Select
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="name"
                                                        w="40vw"
                                                        value={values.name}
                                                        placeholder='Select the Name'
                                                        isRequired
                                                    >
                                                        {/* eslint-disable-next-line array-callback-return */}
                                                        {quizTeams && quizTeams.map(quizTeams => {
                                                            if (quizTeams.seniorRole === "Senior Captain" || quizTeams.seniorRole === "Senior Vice Captain" || quizTeams.juniorRole === "Junior Captain" || quizTeams.juniorRole === "Junior Vice Captain") {
                                                                i += 1;
                                                                return (
                                                                    <option key={quizTeams.name} value={quizTeams.name}>{quizTeams.name}</option>
                                                                )
                                                            }
                                                        })}

                                                    </Select>

                                                    <Input
                                                        type="text"
                                                        name="img"
                                                        w="83vw"
                                                        placeholder="Paste the Image URL"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.img}
                                                        isRequired
                                                    />
                                                    <Button type="submit" disabled={isSubmitting} mt="20px" bgColor="gray.700" px="40px">
                                                        Submit
                                                    </Button>
                                                </HStack>
                                            </VStack>



                                        </form>
                                    )}
                                </Formik>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                    <VStack>

                        <Text visibility="hidden">.</Text>

                        <Container maxW="90vw" p="0" mt="50px">
                            <Container maxW="90vw" bg={"gray.700"} p="15px" mb="10px" borderRadius="10px" fontSize="18px" position="relative" color="white">
                                <HStack>
                                    <Text mr="50px" w="35vw">Name</Text>
                                    <Text mr="50px" w="20vw">Grade</Text>
                                    <Text mr="50px" w="20vw">Role</Text>
                                    <Text mr="50px" w="5vw">Img</Text>
                                </HStack>
                            </Container>
                            {quizTeams && quizTeams.map(quizTeams => {
                                i += 1;
                                return (
                                    <Container maxW="90vw" bg={"gray.100"} p="15px" mb="10px" borderRadius="10px" fontSize="18px" position="relative">
                                        <HStack>
                                            <Text mr="50px" w="35vw">{quizTeams.name}</Text>
                                            <Text mr="50px" w="20vw">{quizTeams.grade}</Text>
                                            <Text mr="50px" w="20vw">
                                                {quizTeams.seniorRole}
                                                {(quizTeams.juniorRole !== '' && quizTeams.seniorRole !== '') && (
                                                    ", "
                                                )}
                                                {quizTeams.juniorRole}</Text>
                                            <Text mr="50px" w="5vw">
                                                {!(quizTeams.img === undefined) && (
                                                    <CheckIcon />
                                                )}
                                            </Text>

                                            <IconButton value={quizTeams.name} icon={<DeleteIcon textColor="gray.600" _hover={{ color: "white" }} />} bg="white" onClick={() => {
                                                handleDelete(quizTeams.name)
                                            }} />
                                        </HStack>
                                    </Container>
                                )

                            })}
                        </Container>
                    </VStack>
                </Container>
            </Container>
        </>
    )
};

export default SquadUpdate;