import React from 'react';
import { useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, Button, Center, Box } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import logo from '../../../src/Images/navLogo1.png';
import './BrainiacBlitz_Home.css';
import { useToast } from '@chakra-ui/toast';

const Home = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [placement, setPlacement] = React.useState("right");
    const handlePlacementChange = event => setPlacement(event.target.value);

    return (
        <div id='brainiacBlitz_home'>
            <div id='main'>
                <div id='header_background'></div>
                <div id='header_overlay'></div>
                <div id='main_nav_container'>
                    <div id='main_nav_logo'>
                        <img src={logo} alt="logo" />
                        <img src='https://lh3.googleusercontent.com/fife/ALs6j_HAEsvbjJRuZplYOxZV0SBv4lPdo6Dbg1YHT5vEV9mrX0sM3nHPtscxR-yFMmRjWDkMkEEF8yqWJCVHM2INE0e0HEygB_vwllx-uf1bFrojimuSlkcolvihBEb5HKMm8YF94C6hmAShoRtPjdNhE-t4yfPELgNC5fW_pHw3vyxmkKSMzmmevYDSGTThVaHxYl3_AmF75DurxFKFojvNFKFFm3XMBtNweJCcyKdAhImRV3Lf1qALROrCGMof9-qolgMpqEy4ZWsFTFv9OV922fQTyWZDCLjmg2_4gdrQHqPMdEi3FvCK8N22JPp_uiO3e3UDpZazbUSHxo-fK5lPD7eho2uVyXGWhLWlErmUz2vc5bkb6iEOzhJQxmK5RhkcY7GY0Nhz_1Hpl0UIaP1kvqLOKZP20gaBcCGkKQr6oAqnkkHu3Z0v6KBiqNbFGa2aK5Jof4MfDKVQgvJHP3Og3pwF0UTL69ciaQAOwvU1CQS6V1G7KwD8MfQtR6kWSm9Iw_O6kHJsnJ67BAYdNF7KWctCQeiqKNFDN6V_nPcWtBqjdzcQccAgfzQNVaXE-jcj9bKCAY1Brc7I4hSQPAGqgOjJ76SkPkqKF6EMJRM35VUsRAHLHgvCeoFz5PhUlOi_KPq83eZhR-HRKie7tDLKunfbd8lLyCye2zLtKs8h0aUOF-vqp0GBkNP9-Inh_ysErXSkcqq-mrM5Ekvcf6Cxse1UUKNnzm5DEfM7zBH9Tv7beNwk9pe146fO-ynE012onZURos943ZtQVPMNShpl6dEiczuID78K2Mz5uggD5ZspzFnlofVR04ZHNgnHizjr9_1XTdrMY0RbmBh9z1KhpUgoAv1Kg32L91352CH5akOpvtcAXuE1sSaf3duN27eYGU2ia0nnM24UXa5h476zGMz2ShRhUkQtVQDCpi-LTlfi3FXeecfjL6HFe3UImd0UysDeKzE07GHxcQwxTFhFMaJtOTLTTe-SDbEDdCx6rZLdXzHlnbPCejR1IDhMuVZ3dEulHva-6nIY53KQmo9DtfnI8e_VIG2jpoEKYWOKA6x7YKg4MwSL76dAk8qplCqwLc9yDxWfhCHjes9nN_qjGSTtfgQufm86AJar5vZd5WJNEv0ADTrqcHEwwvK00SCP8RroUgfeWd_rww99uvQmLkpRSQmBQc3AaeJYHVjGmxeSHJBqR1uGdmkY2coZcd1TYBZUAOD_BwtXgDjFCMj-xdafzEgEXAZb6ne7mhqY14pd8tr705Mq7o1ybTJ828ROkHFadg1XMRfYm8JrN19ZiDIsalEQhQV9PiB1a_qsAwXkEDeOnLea1Ui2YhRWpNxo1021uCNvGBTRcx8K-y210IVL49Udr8_H8F4YFj8TJtT8MqO8yKyTMXbA9MEem4GbtczFx4rVyYeOppMRyiBE8aqyueCP11REwMgJJfICaOdYwuF79JftJrev_nsn2oad9xo2ruibcyvP7BBH3ikeph9FWXYSzJgyEw5hiU4TmuSoHPdMxT7I-tCgxY5OzhukVGUYoyxxfJpyucPjS0lQ5nj1QEM94MJZBLR5ZmRtD0cy-JIEQDyHAGp_gvYhM5kqTduSG1uQUROuwQw5fifwW4pQcA4Pog0BKoLSqD4cQg9pSyupnkzTFm079vE0DLSAwT7K-ouaftowPPlVM47mFA=w2548-h1206' alt="logo" />
                    </div>
                    <div id='main_nav_links'>
                        {/* <a href='/'>Home</a>
                        <a href='/history'>History</a>
                        <a href='/achievements'>Achievements</a>
                        <a href='/projects'>Projects</a>
                        <a href='/squad'>Squad</a>
                        <a href='/mindprobe'><b>Brainiac Blitz</b></a> */}
                        <a href='#brainiacBlitz_home'>Home</a>
                        <a href='#brainiacBlitz_about'>About</a>
                        <a href='#brainiacBlitz_about'>Timeline</a>
                        <a href='#brainiacBlitz_contact'>Contact</a>
                        <a href='#brainiacBlitz_register' id='button_register'>Register</a>
                        <HamburgerIcon onClick={onOpen} id='hamburgerIcon' />
                    </div>
                </div>
                <div id='container'>
                    {/* <div id='content'> */}
                        <div id='logo_container'>
                            {/* <img id='logo_main' src='https://lh3.googleusercontent.com/d/1-7-DqsnJYKF-u0nuxUSEhiJMipnDgzh5=w1920-h878-iv1' alt=''></img> */}
                            <img id='logo_main' src='https://lh3.googleusercontent.com/fife/ALs6j_GxoavJ3fMR3OlOdlkggJ_eZNQRgGuLIZOfmNpJTYRqKTsxWqimpTur3IlmCYT4OQTHCER1HYLn_C_D2YLutFn3xzXAzY-6eyNAEkmOtKfXL9u7ph9D4SDfdHohwpeMuij16a-RfcELms-mXmTiuRvG8D7nUn3nVfTCnHFgBV30vW60wzm5P0pfY7MRZarHIAoNa73C4raV47j3G-4fIMzDW0dFconydj8Gnd5zActjpAFvhNcE4AwOpxa8L56nI4iLtP9blPWqk0jMoqvRns5Ei1S4MXrzpH50hRznhwl7lHRQeur3oILF9RwtVBtBXYLqS3eX6mWUvpGUv-pC7PsvwWaERgkiVOwXVrpQx0ZYuWcqvtcjDwdFg56oHMSytHGMDpt4N4cxLm6XjWWzN-Xwu5kjhzMgdeNv-I4sGPVRPT_Jd7qPdMNHPHi8CppJEkD-sRZDURR6QM4dVjhuul3Y6ndpi3cGm6z2jEl0fbAIzt3PY_wHGiP3RPba_-oi7ek2nCEUOSYdeMJBqioCyFXkDb4jLRr0otMTe2A6b6Q9D6gzYBDa5Xr3m3M7lfge2ISdv4rDnpYUoEFxHDxwJVXAdoggzbtmo2DGw-ZUFVu1lkcPRF-5jfA5AsIp9Ix_Okemx2ypDvhD7ZE2xT8DL0nICBke_sy2-m2_1u2DQEea1h25mHdTXs3o--UEZv8MJRE0PRbuZjJpmkuS9GINmfhaxBy-pHia8PyI13cwKXSyMhqwsoTDOvw_l5iXPEFSxR6svJMGQw9CoI5UjBFBmeFdUMobiANMSkSgY1EsmnQIk_-eRrdHRxm8oVExGCxNftO9lZOD_pAS5cb0pQ4fiQrQEVnGEYpJITgfrxukv2zmH7a95zk0qlhdcSbzGfCVT3Jv6aQWpvmfv1K4ixreBT8EvQD9uTvjW-GNpan82NmVWVvUpjE_mWUn8D7-FLg12LBWu-zUzWuBVsLkEeaGFcVzsCuTInlrW1cnZTPBy-n1TOdC9fBgrbOx1YxyWTRjvyoOcuES-f3ULeUr74JOG0-2zLxzPlamrGSO62nxDeGpsnWzXQUcDIGrvAKPDSls8sMu6YyE_Edc5pisUyeYgMMGLgBZzPBvoACU4sgLNAigJB5JLzvH1u1B5ND7mRdCDfzHkgxpEryBsksx2BV_1es4-NL6cUUUt19_UlfhNE9YUF5Ej_Pg44OzxfvGdfTf8Mf-DQ42nV4onIqq_9TVPlJKDseU2uG4uFLkvLMeF9-9P0AwCuevYcjblfgrj-jEEcED71upI14JBs9PZv2KIVmwEpF7U0IcdfNxfi4MK2hCpGIoWeRSzRbxRsKj58tsMOAmRp2PkypGQTwenmhNTZ9KFQ-nED3dVGLixrady-GyOKLf28QIl7OcN0hDkZYUDOgNtzqKXZQhGquH-5ZtuLpjzd3Z7CmTeyBXJKriNPTskchxjHyjFaroMpCXzC7pV3c-cSna7s5_GbMUGO0E-IERZO9YzNP5XlzWiIox0rZPl1X4H9FPN9bmuFKz3lg78VNyLPkgyMkTF0xH4HypZ7ltCFdmCoNEWiqZ63p9uK2r61PJDqQM2YXkK1kQwdnc1PAT9lE8Xoxy-9CuKdBk8rnNbWuklfD5-jD-tzyFYmqQXvL92TY8ljl3nGSMRhV9RaF9SVZKs1IWG7tjQqU_bA=w2548-h1206' alt=''></img>
                        </div>
                        {/* <div id='home_text_container'>
                            <p>THE ULTIMATE ODYSSEY FOR UNIQUE EXCELLENCE</p>
                            <p>THE LARGEST QUIZ DAY ORGANIZED BY A SCHOOL IN SRI LANKA</p><br />
                            <p>MINDPROBE'23 SUCCESSFULLY CONCLUDED.</p>
                        </div> */}
                        <div id='button_container'>
                            {/* <a href='#mindprobe_register' id='button_register'>REGISTER</a> */}
                            <a href='#brainiacBlitz_register' id='button_register'>Register</a>
                            {/* <a target='_blank' id='button_rr' href='https://drive.google.com/drive/folders/12v1I2CSLPsm8ubB2M9W1WM62uMOO33Zx?usp=sharing' rel="noreferrer">QUESTIONS</a> */}
                            <a target='_blank' id='button_rr' href='/bb24/r&r'>RULES &<br />REGULATIONS</a>
                        </div>
                    {/* </div> */}
                </div>
            </div>
            <Drawer placement='top' onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent backgroundColor="#0A1D1B">
                    <DrawerHeader borderBottomWidth="1px">
                        <div id='brainiacBlitz_mobile_nav_header'>
                            <img src={logo} alt="logo" />
                            <CloseIcon onClick={onClose} id='hamburgerIcon' color="white" />
                        </div>
                    </DrawerHeader>
                    <DrawerBody id='brainiacBlitz_mobile_nav_items_container' paddingBottom={"40px"}>
                        <a href='/'>Home</a><br />
                        <a href='/history'>History</a><br />
                        <a href='/achievements'>Achievements</a><br />
                        <a href='/projects'>Projects</a><br />
                        <a href='/squad'>Squad</a><br />
                        <div id='brainiacBlitz_mobile_nav_items'>
                            <a href='/brainiacBlitz' id='current'><b>Brainiac Blitz</b></a><br />
                            <a href='#brainiacBlitz_home' onClick={onClose}>Home</a><br />
                            <a href='#brainiacBlitz_about' onClick={onClose}>About</a><br />
                            <a href='#brainiacBlitz_about' onClick={onClose}>Timeline</a><br />
                            <a href='#brainiacBlitz_contact' onClick={onClose}>Contact</a><br />
                            <a href='#brainiacBlitz_register' id='brainiacBlitz_mobile_nav_register_border' onClick={onClose}>REGISTER</a>
                            {/* <a href='/mindprobe/results' id='mindprobe_mobile_nav_register_border' onClick={onClose}>RESULTS</a> */}
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    )
}

export default Home
