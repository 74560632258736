import React, { useContext } from 'react';
import { Center, Container, Wrap, WrapItem } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image';
import { DbContext } from '../../Services/context';

const Squad = () => {
    const { seniorQuizCaptain, seniorQuizViceCaptain, seniorQuizTeamMember, seniorQuizSquadMember, juniorQuizCaptain, juniorQuizViceCaptain, juniorQuizTeamMember, juniorQuizSquadMember, details } = useContext(DbContext);
    // eslint-disable-next-line no-unused-vars
    let i = 0;
    let fallbackImg;
    let from;
    let to;

    // eslint-disable-next-line array-callback-return
    details && details.map(details => {
        from = details.quizTeamFrom;
        to = details.quizTeamTo;
        fallbackImg = details.fallbackImg;
    })

    return (
        <div>
            <Container maxW="100%" bg="gray.900" id="squad">
                <Container maxW="100%" p={['80px 30px 30px 30px']}>
                    <Center flexDir="column">
                        <Text textAlign="center" fontWeight="bold" textColor="gray.100" fontSize={['4xl', '5xl', '6xl']} letterSpacing="wider">Senior Quiz Team</Text>
                        <Text textAlign="center" textColor="gray.100" fontSize="xl" letterSpacing="wider">{from} - {to}</Text>
                        <Wrap justify="space-around" mt="80px" w={["100vw", "100vw", "80vw"]} alignContent="center">
                            {seniorQuizCaptain && seniorQuizCaptain.map(squad => {
                                if (squad.img === undefined) {
                                    squad.img = fallbackImg;
                                }
                                i += 1;
                                return (
                                    <WrapItem>
                                        <Container maxW="300px" mb="50px">
                                            <Center flexDir="column">
                                                <Image src={squad.img} w="220px" h="220px" objectFit="cover" borderRadius="10px" alt="img" onError={(e) => (e.currentTarget.src = fallbackImg)} />
                                                <Text textColor="white" fontSize="xl" mt="20px" textAlign="center">{squad.name}</Text>
                                                <Text fontSize="lg" color="gray.400">{squad.grade}</Text>
                                                <Text textColor="gray.400" fontSize="larger" mt="10px" textAlign="center">- {squad.seniorRole} -</Text>
                                            </Center>
                                        </Container>
                                    </WrapItem>
                                )
                            })}
                            {seniorQuizViceCaptain && seniorQuizViceCaptain.map(squad => {
                                if (squad.img === undefined) {
                                    squad.img = fallbackImg;
                                }
                                i += 1;
                                return (
                                    <WrapItem>
                                        <Container maxW="300px" mb="50px">
                                            <Center flexDir="column">
                                                <Image src={squad.img} w="220px" h="220px" objectFit="cover" borderRadius="10px" alt="img" onError={(e) => (e.currentTarget.src = fallbackImg)} />
                                                <Text textColor="white" fontSize="xl" mt="20px" textAlign="center">{squad.name}</Text>
                                                <Text fontSize="lg" color="gray.400">{squad.grade}</Text>
                                                <Text textColor="gray.400" fontSize="larger" mt="10px" textAlign="center">- {squad.seniorRole} -</Text>
                                            </Center>
                                        </Container>
                                    </WrapItem>
                                )
                            })}
                        </Wrap>

                    </Center>
                </Container>

                <Container maxW="100%" p={['0px 30px 70px 30px']}>
                    <Wrap justify="center" spacing="20">

                        {seniorQuizTeamMember && seniorQuizTeamMember.map(squad => {
                            i += 1;
                            return (
                                <Container maxW="350px">
                                    <Center flexDir="column">
                                        <Text fontSize="xl" color="white" mt="-10px">{squad.name}</Text>
                                        <Text fontSize="lg" color="gray.400">{squad.grade}</Text>
                                    </Center>
                                </Container>

                            )
                        })}
                    </Wrap>
                </Container>

                {(seniorQuizSquadMember.length > 0) && (
                    <Container maxW="100%" p={['0px 30px 80px 30px']}>
                        <Text textAlign="center" marginBottom={"60px"} textColor="gray.100" fontSize={['2xl', '3xl', '4xl']} letterSpacing="wider"> ~ Senior Squad ~</Text>

                        <Wrap justify="center" spacing="20">
                            {seniorQuizSquadMember && seniorQuizSquadMember.map(squad => {
                                i += 1;
                                return (
                                    <Container maxW="350px">
                                        <Center flexDir="column">
                                            <Text fontSize="xl" color="white" mt="-10px">{squad.name}</Text>
                                            <Text fontSize="lg" color="gray.400">{squad.grade}</Text>
                                        </Center>
                                    </Container>

                                )
                            })}
                        </Wrap>
                    </Container>
                )}
            </Container>

            <Container maxW="100%" bg="gray.100" id="squad">
                <Container maxW="100%" p={['80px 30px 30px 30px']}>
                    <Center flexDir="column">
                        <Text textAlign="center" fontWeight="bold" textColor="gray.700" fontSize={['4xl', '5xl', '6xl']} letterSpacing="wider">Junior Quiz Team</Text>
                        <Text textAlign="center" textColor="gray.700" fontSize="xl" letterSpacing="wider">{from} - {to}</Text>
                        <Wrap justify="space-around" mt="80px" w={["100vw", "100vw", "80vw"]} alignContent="center">
                            {juniorQuizCaptain && juniorQuizCaptain.map(squad => {
                                if (squad.img === undefined) {
                                    squad.img = fallbackImg;
                                }
                                i += 1;
                                return (
                                    <WrapItem>
                                        <Container maxW="300px" mb="50px">
                                            <Center flexDir="column">
                                                <Image src={squad.img} w="220px" h="220px" objectFit="cover" borderRadius="10px" alt="img" onError={(e) => (e.currentTarget.src = fallbackImg)} />
                                                <Text textColor="gray.700" fontSize="xl" mt="20px" textAlign="center">{squad.name}</Text>
                                                <Text fontSize="lg" color="gray.500">{squad.grade}</Text>
                                                <Text textColor="gray.500" fontSize="larger" mt="10px" textAlign="center">- {squad.juniorRole} -</Text>
                                            </Center>
                                        </Container>
                                    </WrapItem>
                                )
                            })}
                            {juniorQuizViceCaptain && juniorQuizViceCaptain.map(squad => {
                                if (squad.img === undefined) {
                                    squad.img = fallbackImg;
                                }
                                i += 1;
                                return (
                                    <WrapItem>
                                        <Container maxW="300px" mb="50px">
                                            <Center flexDir="column">
                                                <Image src={squad.img} w="220px" h="220px" objectFit="cover" borderRadius="10px" alt="img" onError={(e) => (e.currentTarget.src = fallbackImg)} />
                                                <Text textColor="gray.700" fontSize="xl" mt="20px" textAlign="center">{squad.name}</Text>
                                                <Text fontSize="lg" color="gray.500">{squad.grade}</Text>
                                                <Text textColor="gray.500" fontSize="larger" mt="10px" textAlign="center">- {squad.juniorRole} -</Text>
                                            </Center>
                                        </Container>
                                    </WrapItem>
                                )
                            })}
                        </Wrap>

                    </Center>
                </Container>

                <Container maxW="100%" p={['0px 30px 70px 30px']}>
                    <Wrap justify="center" spacing="20">

                        {juniorQuizTeamMember && juniorQuizTeamMember.map(squad => {
                            i += 1;
                            return (
                                <Container maxW="350px">
                                    <Center flexDir="column">
                                        <Text fontSize="xl" color="gray.700" mt="-10px">{squad.name}</Text>
                                        <Text fontSize="lg" color="gray.500">{squad.grade}</Text>
                                    </Center>
                                </Container>

                            )
                        })}
                    </Wrap>
                </Container>

                {(juniorQuizSquadMember.length > 0) && (
                    <Container maxW="100%" p={['0px 30px 80px 30px']}>
                        <Text textAlign="center" marginBottom={"60px"} textColor="gray.700" fontSize={['2xl', '3xl', '4xl']} letterSpacing="wider"> ~ Junior Squad ~</Text>

                        <Wrap justify="center" spacing="20">
                            {juniorQuizSquadMember && juniorQuizSquadMember.map(squad => {
                                i += 1;
                                return (
                                    <Container maxW="350px">
                                        <Center flexDir="column">
                                            <Text fontSize="xl" color="gray.700" mt="-10px">{squad.name}</Text>
                                            <Text fontSize="lg" color="gray.500">{squad.grade}</Text>
                                        </Center>
                                    </Container>

                                )
                            })}
                        </Wrap>
                    </Container>
                )}
            </Container>

        </div>
    )
}

export default Squad