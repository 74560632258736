import React from 'react';
import { Center, Container, Wrap } from '@chakra-ui/layout'
import { Table, Thead, Tbody, Tr, Td, TableContainer, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Text } from '@chakra-ui/layout'

const Results = () => {
    return (
        <div>
            <Container maxW="100%" bg="gray.100" id="results">
                <Text textAlign="center" fontWeight="bold" textColor="gray.700" fontSize={['4xl', '5xl', '6xl']} letterSpacing="wider" pt="50px">Results</Text>
                <Tabs isFitted variant='enclosed' bg="gray.100" maxW="100%" margin="auto" pt="30px">
                    <Container border="1.5px solid" borderColor="#00000010" borderRadius="20px" p="10px" maxW="95%">
                        <Text fontSize="lg" color="gray.700" align="center" pb="15px">Choose the Category :</Text>
                        <TabList mb='1em' maxW="100%" margin="auto" display="grid" gridGap="6px" gridTemplateColumns="repeat(auto-fit, minmax(160px, 1fr))">
                            <Tab p="20px 0" _selected={{ color: 'white', bg: 'gray.700', letterSpacing: 'wide', border: 'none' }} bg="#00000003" border="1px solid" borderColor="#00000010" borderRadius="20px">Primary Category</Tab>
                            <Tab p="20px 0" _selected={{ color: 'white', bg: 'gray.700', letterSpacing: 'wide', border: 'none' }} bg="#00000003" border="1px solid" borderColor="#00000010" borderRadius="20px">Junior Category</Tab>
                            <Tab p="20px 0" _selected={{ color: 'white', bg: 'gray.700', letterSpacing: 'wide', border: 'none' }} bg="#00000003" border="1px solid" borderColor="#00000010" borderRadius="20px">Senior Category</Tab>
                        </TabList>
                    </Container>
                    <TabPanels>
                        <TabPanel maxW="100%" p="0" m="0">
                            <Container maxW="100%" bg="gray.100" p={['50px 30px 70px 30px']}>
                                <Text textAlign="center" borderRadius="15px 0 15px 0" p="20px 10px" textColor="white" bg="gray.700" fontSize="larger" letterSpacing="wide" mb="70px">Grade 4</Text>
                                <Container maxW="100%" mb="70px">
                                    <Wrap justify="center" spacing="30px">
                                        <Container maxW="100%">
                                            <Container maxW="350px">
                                                <Center flexDir="column">
                                                    <Text fontSize="xl" color="gray.700" mb="5px" align="center">4<sup>4</sup> : Team B</Text>
                                                    <Container bgColor="#FFD700" p="10px 20px" borderRadius="10px">
                                                        <Text fontSize="s" color="gray.700" align="center">Winner</Text>
                                                    </Container>
                                                    <Container p="10px 20px">
                                                        <Text fontSize="s" color="gray.700" align="center">Dinula Liyanage</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Isindu Ekanayeka</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Vinula Senal</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Livinu Dahamvin</Text>
                                                    </Container>
                                                </Center>
                                            </Container>
                                        </Container>
                                        <Container maxW="100%" flexDir="column">
                                            <Wrap justify="space-around" alignContent="center" spacing="30px">
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">4<sup>3</sup> : Team B</Text>
                                                        <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Lashen</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Wihanga </Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Rivenu</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">4<sup>2</sup> : Team A</Text>
                                                        <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Dinuth Randira</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Chenitha Mindula</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Thiveyn Lonitha</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Vidath Kalahansa</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                            </Wrap>
                                        </Container>
                                    </Wrap>
                                </Container>

                                <Container maxW="100%">
                                    <Accordion allowToggle>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        See the Full Leaderboard
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size='md' variant='striped' colorScheme='blackAlpha'>
                                                        <Thead>
                                                            <Tr>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Rank</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Team Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Captain's Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Score<br /><Text fontSize="small">(Out of 25)</Text></Td>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr><Td>1</Td><Td>4<sup>4</sup> : Team B</Td><Td>Dinula Liyanage</Td><Td>11</Td></Tr>
                                                            <Tr><Td>2</Td><Td>4<sup>3</sup> : Team B</Td><Td>Lashen</Td><Td>9</Td></Tr>
                                                            <Tr><Td>2</Td><Td>4<sup>2</sup> : Team A</Td><Td>Dinuth Randira</Td><Td>9</Td></Tr>
                                                            <Tr><Td>4</Td><Td>4<sup>6</sup> : Team A</Td><Td>DImeth</Td><Td>8</Td></Tr>
                                                            <Tr><Td>5</Td><Td>4<sup>3</sup> : Team A</Td><Td>Abhiman</Td><Td>8</Td></Tr>
                                                            <Tr><Td>6</Td><Td>4<sup>4</sup> : Team B</Td><Td>Yehandu Dewsad</Td><Td>8</Td></Tr>
                                                            <Tr><Td>7</Td><Td>4<sup>2</sup> : Team B</Td><Td>Yenul</Td><Td>6</Td></Tr>
                                                            <Tr><Td>8</Td><Td>4<sup>5</sup> : Team B</Td><Td>Ashen</Td><Td>6</Td></Tr>
                                                            <Tr><Td>9</Td><Td>4<sup>1</sup> : Team B</Td><Td>Risindu</Td><Td>3</Td></Tr>
                                                            <Tr><Td>10</Td><Td>4<sup>4</sup> : Team A</Td><Td>Sandira Perera</Td><Td>3</Td></Tr>
                                                            <Tr><Td>11</Td><Td>4<sup>1</sup> : Team A</Td><Td>Akein</Td><Td>3</Td></Tr>
                                                            <Tr><Td>12</Td><Td>4<sup>6</sup> : Team B</Td><Td>Vihen Wasala</Td><Td>3</Td></Tr>
                                                            <Tr><Td>13</Td><Td>4<sup>5</sup> : Team A</Td><Td>Manuth</Td><Td>2</Td></Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Container>

                                <Text textAlign="center" borderRadius="15px 0 15px 0" p="20px 10px" textColor="white" bg="gray.700" fontSize="larger" letterSpacing="wide" mb="70px" mt="50px">Grade 5</Text>
                                <Container maxW="100%" mb="70px">
                                    <Wrap justify="center" spacing="30px">
                                        <Container maxW="100%">
                                            <Container maxW="350px">
                                                <Center flexDir="column">
                                                    <Text fontSize="xl" color="gray.700" mb="5px" align="center">5<sup>6</sup> : Team A</Text>
                                                    <Container bgColor="#FFD700" p="10px 20px" borderRadius="10px">
                                                        <Text fontSize="s" color="gray.700" align="center">Winner</Text>
                                                    </Container>
                                                    <Container p="10px 20px">
                                                        <Text fontSize="s" color="gray.700" align="center">Chaniru</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Tharin</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Ranuka</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Chalinu</Text>
                                                    </Container>
                                                </Center>
                                            </Container>
                                        </Container>
                                        <Container maxW="100%" flexDir="column">
                                            <Wrap justify="space-around" alignContent="center" spacing="30px">
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">5<sup>4</sup> : Team A</Text>
                                                        <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Nehan</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Rithika </Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Shavith</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">5<sup>3</sup> : Team B</Text>
                                                        <Container bgColor="#CD7F32" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">2nd Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Gaura</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Dheera</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Aasitha</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Lesandu</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                            </Wrap>
                                        </Container>
                                    </Wrap>
                                </Container>

                                <Container maxW="100%">
                                    <Accordion allowToggle>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        See the Full Leaderboard
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size='md' variant='striped' colorScheme='blackAlpha'>
                                                        <Thead>
                                                            <Tr>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Rank</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Team Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Captain's Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Score<br /><Text fontSize="small">(Out of 25)</Text></Td>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr><Td>1</Td><Td>5<sup>6</sup> : Team A</Td><Td>Chaniru</Td><Td>19</Td></Tr>
                                                            <Tr><Td>2</Td><Td>5<sup>4</sup> : Team A</Td><Td>Nehan</Td><Td>18</Td></Tr>
                                                            <Tr><Td>3</Td><Td>5<sup>3</sup> : Team B</Td><Td>Gaura</Td><Td>15</Td></Tr>
                                                            <Tr><Td>4</Td><Td>5<sup>2</sup> : Team A</Td><Td>Theertha</Td><Td>14</Td></Tr>
                                                            <Tr><Td>5</Td><Td>5<sup>5</sup> : Team B</Td><Td>Nithesh</Td><Td>13</Td></Tr>
                                                            <Tr><Td>6</Td><Td>5<sup>3</sup> : Team A</Td><Td>Kenul Scofield</Td><Td>12</Td></Tr>
                                                            <Tr><Td>8</Td><Td>5<sup>4</sup> : Team B</Td><Td>Ometh Ninduwara</Td><Td>12</Td></Tr>
                                                            <Tr><Td>7</Td><Td>5<sup>5</sup> : Team A</Td><Td>Thisula</Td><Td>12</Td></Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Container>
                            </Container>
                        </TabPanel>

                        <TabPanel maxW="100%" p="0" m="0">
                            <Container maxW="100%" bg="gray.100" p={['50px 30px 70px 30px']}>
                                <Text textAlign="center" borderRadius="15px 0 15px 0" p="20px 10px" textColor="white" bg="gray.700" fontSize="larger" letterSpacing="wide" mb="70px">Grade 6</Text>
                                <Container maxW="100%" mb="70px">
                                    <Wrap justify="center" spacing="30px">
                                        <Container maxW="100%">
                                            <Container maxW="350px">
                                                <Center flexDir="column">
                                                    <Text fontSize="xl" color="gray.700" mb="5px" align="center">Golden Knights</Text>
                                                    <Container bgColor="#FFD700" p="10px 20px" borderRadius="10px">
                                                        <Text fontSize="s" color="gray.700" align="center">Winner</Text>
                                                    </Container>
                                                    <Container p="10px 20px">
                                                        <Text fontSize="s" color="gray.700" align="center">Isara Lekamge</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Ramthu Rodrigo</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Vinuka Wickremerathne</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Hansitha Gunasekara</Text>
                                                    </Container>
                                                </Center>
                                            </Container>
                                        </Container>
                                        <Container maxW="100%" flexDir="column">
                                            <Wrap justify="space-around" alignContent="center" spacing="30px">
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">6<sup>6</sup> : Team A</Text>
                                                        <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">U.D.Tesanda Thathsara</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">Masterminds</Text>
                                                        <Container bgColor="#CD7F32" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">2nd Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">M.K.V.D.Weeravickum</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">M.A.Manula Hasarel</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">B.G.Vihas Nethmitha</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">T.M.Dihansa Nethmina</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                            </Wrap>
                                        </Container>
                                    </Wrap>
                                </Container>

                                <Container maxW="100%">
                                    <Accordion allowToggle>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        See the Full Leaderboard
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size='md' variant='striped' colorScheme='blackAlpha'>
                                                        <Thead>
                                                            <Tr>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Rank</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Team Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Captain's Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Score<br /><Text fontSize="small">(Out of 25)</Text></Td>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr><Td>1</Td><Td>Golden Knights</Td><Td>Isara Lekamge</Td><Td>10</Td></Tr>
                                                            <Tr><Td>2</Td><Td>5<sup>6</sup> : Team A</Td><Td>U.D.Tesanda Thathsara</Td><Td>7</Td></Tr>
                                                            <Tr><Td>3</Td><Td>Masterminds</Td><Td>M.K.V.D.Weeravickum</Td><Td>5</Td></Tr>
                                                            <Tr><Td>4</Td><Td>5<sup>4</sup> : Team A</Td><Td>Sakith Gesandu</Td><Td>4</Td></Tr>
                                                            <Tr><Td>5</Td><Td>Dragons</Td><Td>Sehas Sethumge</Td><Td>4</Td></Tr>
                                                            <Tr><Td>6</Td><Td>Luky four</Td><Td>Thenuk</Td><Td>1</Td></Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Container>

                                <Text textAlign="center" borderRadius="15px 0 15px 0" p="20px 10px" textColor="white" bg="gray.700" fontSize="larger" letterSpacing="wide" mb="70px" mt="50px">Grade 7</Text>
                                <Container maxW="100%" mb="70px">
                                    <Wrap justify="center" spacing="30px">
                                        <Container maxW="100%">
                                            <Container maxW="350px">
                                                <Center flexDir="column">
                                                    <Text fontSize="xl" color="gray.700" mb="5px" align="center">Little Masters</Text>
                                                    <Container bgColor="#FFD700" p="10px 20px" borderRadius="10px">
                                                        <Text fontSize="s" color="gray.700" align="center">Winner</Text>
                                                    </Container>
                                                    <Container p="10px 20px">
                                                        <Text fontSize="s" color="gray.700" align="center">Ojith Dulanjana</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Rithindu Sanyuga</Text>
                                                    </Container>
                                                </Center>
                                            </Container>
                                        </Container>
                                        <Container maxW="100%" flexDir="column">
                                            <Wrap justify="space-around" alignContent="center" spacing="30px">
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">Quiz Wizards 2.0</Text>
                                                        <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Mihela Gunaratne</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">Kings of Quiz</Text>
                                                        <Container bgColor="#CD7F32" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">2nd Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Uthul Minhas</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Sihela Divithuragama</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Dihela Divithuragama</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Harshitha Karunathilaka</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                            </Wrap>
                                        </Container>
                                    </Wrap>
                                </Container>

                                <Container maxW="100%">
                                    <Accordion allowToggle>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        See the Full Leaderboard
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size='md' variant='striped' colorScheme='blackAlpha'>
                                                        <Thead>
                                                            <Tr>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Rank</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Team Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Captain's Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Score<br /><Text fontSize="small">(Out of 25)</Text></Td>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr><Td>1</Td><Td>Little Masters</Td><Td>Ojith Dulanjana</Td><Td>16</Td></Tr>
                                                            <Tr><Td>2</Td><Td>Quiz Wizards 2.0</Td><Td>Mihela Gunaratne</Td><Td>13</Td></Tr>
                                                            <Tr><Td>3</Td><Td>Kings of Quiz</Td><Td>Uthul Minhas</Td><Td>7</Td></Tr>
                                                            <Tr><Td>4</Td><Td>7 at 7</Td><Td>Nirodha Jayasuriya</Td><Td>6</Td></Tr>
                                                            <Tr><Td>5</Td><Td>The Knights of knowladge</Td><Td>Manuka Diksanda</Td><Td>6</Td></Tr>
                                                            <Tr><Td>6</Td><Td>Little masters</Td><Td>R. D. Imeth Bandara</Td><Td>6</Td></Tr>
                                                            <Tr><Td>7</Td><Td>Four real friends</Td><Td>K.P.Sethika Disas</Td><Td>5</Td></Tr>
                                                            <Tr><Td>8</Td><Td>Mastermate</Td><Td>Nimna Sudaraka</Td><Td>5</Td></Tr>
                                                            <Tr><Td>9</Td><Td>7^6 Legends</Td><Td>Akitha Sanuk</Td><Td>4</Td></Tr>
                                                            <Tr><Td>10</Td><Td>Clever Boys</Td><Td>P.D.C.Bethmin</Td><Td>4</Td></Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Container>

                                <Text textAlign="center" borderRadius="15px 0 15px 0" p="20px 10px" textColor="white" bg="gray.700" fontSize="larger" letterSpacing="wide" mb="70px" mt="50px">Grade 8</Text>
                                <Container maxW="100%" mb="70px">
                                    <Wrap justify="center" spacing="30px">
                                        <Container maxW="100%">
                                            <Container maxW="350px">
                                                <Center flexDir="column">
                                                    <Text fontSize="xl" color="gray.700" mb="5px" align="center">DMDO Little quizers</Text>
                                                    <Container bgColor="#FFD700" p="10px 20px" borderRadius="10px">
                                                        <Text fontSize="s" color="gray.700" align="center">Winner</Text>
                                                    </Container>
                                                    <Container p="10px 20px">
                                                        <Text fontSize="s" color="gray.700" align="center">Dulen Senevirathna</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Desindu Thenula</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Okitha Adith</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Milana Lavinda</Text>
                                                    </Container>
                                                </Center>
                                            </Container>
                                        </Container>
                                        <Container maxW="100%" flexDir="column">
                                            <Wrap justify="space-around" alignContent="center" spacing="30px">
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">Fire Team</Text>
                                                        <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Saviru Dulen</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Raveen Anuja </Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Sandeep Sithumina</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Disina Lakniju</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">The Vision</Text>
                                                        <Container bgColor="#CD7F32" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">2nd Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Janidu Nuwanthira</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Rangeeth Ravindra</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Nethaka Nonis</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Mihin Induwara</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                            </Wrap>
                                        </Container>
                                    </Wrap>
                                </Container>

                                <Container maxW="100%">
                                    <Accordion allowToggle>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        See the Full Leaderboard
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size='md' variant='striped' colorScheme='blackAlpha'>
                                                        <Thead>
                                                            <Tr>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Rank</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Team Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Captain's Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Score<br /><Text fontSize="small">(Out of 25)</Text></Td>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr><Td>1</Td><Td>DMDO Little quizers</Td><Td>Dulen Senevirathna</Td><Td>17</Td></Tr>
                                                            <Tr><Td>2</Td><Td>Fire Team</Td><Td>Saviru Dulen</Td><Td>9</Td></Tr>
                                                            <Tr><Td>3</Td><Td>The Vision</Td><Td>Janidu Nuwanthira</Td><Td>7</Td></Tr>
                                                            <Tr><Td>4</Td><Td>Puzzles</Td><Td>Eeshan Nanayakkara</Td><Td>2</Td></Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Container>

                                <Text textAlign="center" borderRadius="15px 0 15px 0" p="20px 10px" textColor="white" bg="gray.700" fontSize="larger" letterSpacing="wide" mb="70px" mt="50px">Grade 9</Text>
                                <Container maxW="100%" mb="70px">
                                    <Wrap justify="center" spacing="30px">
                                        <Container maxW="100%">
                                            <Container maxW="350px">
                                                <Center flexDir="column">
                                                    <Text fontSize="xl" color="gray.700" mb="5px" align="center">Brainiac Boys</Text>
                                                    <Container bgColor="#FFD700" p="10px 20px" borderRadius="10px">
                                                        <Text fontSize="s" color="gray.700" align="center">Winner</Text>
                                                    </Container>
                                                    <Container p="10px 20px">
                                                        <Text fontSize="s" color="gray.700" align="center">Yumeth Silva</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Sandanu De Silva</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Gokith Bonuka</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Sanuth Dulwan</Text>
                                                    </Container>
                                                </Center>
                                            </Container>
                                        </Container>
                                        <Container maxW="100%" flexDir="column">
                                            <Wrap justify="space-around" alignContent="center" spacing="30px">
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">9<sup>6</sup> : Team A</Text>
                                                        <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Ovindu Nimsara</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Kisandu </Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Thejan</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Senuth Neththaru</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">9<sup>6</sup> : Team B</Text>
                                                        <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Buddhima</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Nethra</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Kemiru</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Siyath</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                            </Wrap>
                                        </Container>
                                    </Wrap>
                                </Container>

                                <Container maxW="100%">
                                    <Accordion allowToggle>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        See the Full Leaderboard
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size='md' variant='striped' colorScheme='blackAlpha'>
                                                        <Thead>
                                                            <Tr>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Rank</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Team Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Captain's Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Score<br /><Text fontSize="small">(Out of 25)</Text></Td>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr><Td>1</Td><Td>Brainiac Boys</Td><Td>Yumeth Silva</Td><Td>10</Td></Tr>
                                                            <Tr><Td>2</Td><Td>9<sup>6</sup> : A Team</Td><Td>Ovindu Nimsara</Td><Td>7</Td></Tr>
                                                            <Tr><Td>2</Td><Td>9<sup>6</sup> : B Team</Td><Td>Buddhima</Td><Td>7</Td></Tr>
                                                            <Tr><Td>4</Td><Td>Drago Warriors</Td><Td>Ramiru Wijayasiriwardhane</Td><Td>6</Td></Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Container>
                            </Container>
                        </TabPanel>

                        <TabPanel maxW="100%" p="0" m="0">
                            <Container maxW="100%" bg="gray.100" p={['50px 30px 70px 30px']}>
                                <Text textAlign="center" borderRadius="15px 0 15px 0" p="20px 10px" textColor="white" bg="gray.700" fontSize="larger" letterSpacing="wide" mb="70px">Grade 10</Text>
                                <Container maxW="100%" mb="70px">
                                    <Wrap justify="center" spacing="30px">
                                        <Container maxW="100%">
                                            <Container maxW="350px">
                                                <Center flexDir="column">
                                                    <Text fontSize="xl" color="gray.700" mb="5px" align="center">Maroon 4</Text>
                                                    <Container bgColor="#FFD700" p="10px 20px" borderRadius="10px">
                                                        <Text fontSize="s" color="gray.700" align="center">Winner</Text>
                                                    </Container>
                                                    <Container p="10px 20px">
                                                        <Text fontSize="s" color="gray.700" align="center">Hansira Wijewantha</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Duminda Dissanayke</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Oshara Kumarapeli</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Saviru Agalakada</Text>
                                                    </Container>
                                                </Center>
                                            </Container>
                                        </Container>
                                        <Container maxW="100%" flexDir="column">
                                            <Wrap justify="space-around" alignContent="center" spacing="30px">
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">The Quizzing Spirit</Text>
                                                        <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Artha Rathnayaka</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Gangul Deusara </Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Savinu Dinhas</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Pasindu Nimsara</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">SMSC</Text>
                                                        <Container bgColor="#CD7F32" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">2nd Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Sanuga Ubhayarathne</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Malshan Jayanath</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Sithura Nimsara</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Chanith Dilanka</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                            </Wrap>
                                        </Container>
                                    </Wrap>
                                </Container>

                                <Container maxW="100%">
                                    <Accordion allowToggle>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        See the Full Leaderboard
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size='md' variant='striped' colorScheme='blackAlpha'>
                                                        <Thead>
                                                            <Tr>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Rank</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Team Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Captain's Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Score<br /><Text fontSize="small">(Out of 30)</Text></Td>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr><Td>1</Td><Td>Maroon 4</Td><Td>Hansira Wijewantha</Td><Td>19</Td></Tr>
                                                            <Tr><Td>2</Td><Td>The Quizzing Spirit</Td><Td>Artha Rathnayaka</Td><Td>17</Td></Tr>
                                                            <Tr><Td>3</Td><Td>SMSC</Td><Td>Sanuga Ubhayarathne</Td><Td>6</Td></Tr>
                                                            <Tr><Td>4</Td><Td>Fearless</Td><Td>Manuth Ranawaka</Td><Td>5</Td></Tr>
                                                            <Tr><Td>5</Td><Td>Grade 10-8 class</Td><Td>Dinada Colanbage</Td><Td>4</Td></Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Container>

                                <Text textAlign="center" borderRadius="15px 0 15px 0" p="20px 10px" textColor="white" bg="gray.700" fontSize="larger" letterSpacing="wide" mb="70px" mt="50px">Grade 11</Text>
                                <Container maxW="100%" mb="70px">
                                    <Wrap justify="center" spacing="30px">
                                        <Container maxW="100%">
                                            <Container maxW="350px">
                                                <Center flexDir="column">
                                                    <Text fontSize="xl" color="gray.700" mb="5px" align="center">11<sup>3</sup> : Team A</Text>
                                                    <Container bgColor="#FFD700" p="10px 20px" borderRadius="10px">
                                                        <Text fontSize="s" color="gray.700" align="center">Winner</Text>
                                                    </Container>
                                                    <Container p="10px 20px">
                                                        <Text fontSize="s" color="gray.700" align="center">Lithika Senavirathne</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Chirana Methnuka</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Kusal Siriwardhana</Text>
                                                        <Text fontSize="s" color="gray.700" align="center">Jeewaka Jayashan</Text>
                                                    </Container>
                                                </Center>
                                            </Container>
                                        </Container>
                                        <Container maxW="100%" flexDir="column">
                                            <Wrap justify="space-around" alignContent="center" spacing="30px">
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">Conspiracists 2.0</Text>
                                                        <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Tharuk Jagoda</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Maneth Perera </Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Dinil Mendis</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Sasandu Yenul</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                                <Container maxW="350px">
                                                    <Center flexDir="column">
                                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">The Rolling Stones</Text>
                                                        <Container bgColor="#CD7F32" p="10px 20px" borderRadius="10px">
                                                            <Text fontSize="s" color="gray.700" align="center">2nd Runner Up</Text>
                                                        </Container>
                                                        <Container p="10px 20px">
                                                            <Text fontSize="s" color="gray.700" align="center">Chelaka Amarasiri</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Yugeeth Rathnayake</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Sasindu Senaratne</Text>
                                                            <Text fontSize="s" color="gray.700" align="center">Dinath Mandiv</Text>
                                                        </Container>
                                                    </Center>
                                                </Container>
                                            </Wrap>
                                        </Container>
                                    </Wrap>
                                </Container>

                                <Container maxW="100%">
                                    <Accordion allowToggle>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        See the Full Leaderboard
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size='md' variant='striped' colorScheme='blackAlpha'>
                                                        <Thead>
                                                            <Tr>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Rank</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Team Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Captain's Name</Td>
                                                                <Td border="solid" borderWidth="1px 0 1px 0">Score<br /><Text fontSize="small">(Out of 30)</Text></Td>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr><Td>1</Td><Td>11<sup>3</sup> : Team A</Td><Td>Lithika Senavirathne</Td><Td>22</Td></Tr>
                                                            <Tr><Td>2</Td><Td>Conspiracists 2.0</Td><Td>Tharuk Jagoda</Td><Td>16</Td></Tr>
                                                            <Tr><Td>3</Td><Td>The Rolling Stones</Td><Td>Chelaka Amarasiri</Td><Td>13</Td></Tr>
                                                            <Tr><Td>4</Td><Td>The Conquerors</Td><Td>E.B.H. Dinujaya</Td><Td>12</Td></Tr>
                                                            <Tr><Td>5</Td><Td>Alpha</Td><Td>H.P.Yasas Damsara Pathirage</Td><Td>10</Td></Tr>
                                                            <Tr><Td>6</Td><Td>Master minds</Td><Td>V.M.Watawana</Td><Td>8</Td></Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Container>
                            </Container>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </div>
    )
}

export default Results