import { Input } from '@chakra-ui/input';
import { Center, Heading } from '@chakra-ui/layout'
import React, { useEffect } from 'react'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useContext } from "react";
import { Formik } from "formik";
import { Button } from '@chakra-ui/button';
import { AuthContext } from '../../Services/context';
import { Redirect } from "react-router-dom";
import { useToast } from '@chakra-ui/toast';

const Login = () => {
  useEffect(() => {
    document.title = "ACQC - Login"
  });

  const toast = useToast();
  const { user } = useContext(AuthContext);

  return (
    <>
      {user ? (
        <Redirect to="/admin/squad" />
      ) : (
        <Center h="100vh" bg="gray.100" pl="35%" pr="35%" display="flex" flexDirection="column">

          <Heading as="h1" size="xl" color="gray.700" mb="100px">ACQC <Heading textColor="gray.600" display="inline-block">Admin-Panel</Heading> </Heading>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values, { setSubmitting }) => {
              const auth = getAuth();
              signInWithEmailAndPassword(
                auth,
                values.email,
                values.password
              )


                .then((userCredential) => {
                  // Signed in
                  const user = userCredential.user;
                  console.log(user);
                  toast({
                    title: "Welcome back Admin!",
                    description: "Login Successful",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });
                })
                .catch((error) => {

                  const errorM = error.message;
                  console.log(errorM);

                  if (errorM === "Firebase: Error (auth/user-not-found).") {
                    console.log(errorM);
                    toast({
                      title: "Error.",
                      description: "Oh no, The email you entered is wrong",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }

                  if (errorM === "Firebase: Error (auth/wrong-password).") {
                    console.log(errorM);
                    toast({
                      title: "Error.",
                      description:
                        "Oh no, The Password you entered is incorrect.",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }

                });
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Input
                  placeholder="Your email"
                  variant="filled"
                  bg="white"
                  mb="5"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />

                <Input
                  variant="filled"
                  bg="white"
                  placeholder="Your Password"
                  type="password"
                  name="password"
                  mb="5"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  w="100%"
                  mt="10"
                  colorScheme="teal"
                >
                  Log In
                </Button>


              </form>
            )}
          </Formik>
        </Center>
      )}
      ;
    </>
  );
}

export default Login
