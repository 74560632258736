import React, { useEffect } from 'react'
import Nav from '../Components/Nav'
import Header from '../Components/Projects/Header'
import ProjectsContainer from '../Components/Projects/ProjectsContainer'

const Projects = () => {
    useEffect (()=>{
        document.title = "ACQC - Projects"
    });
    
    return (
        <div>
            <Nav />
            <Header />
            <ProjectsContainer />
        </div>
    )
}

export default Projects