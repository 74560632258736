import React, { useEffect } from 'react'
import Nav from '../Components/Nav'
import Header from '../Components/Squad/Header'
import QuizTeams from '../Components/Squad/QuizTeams'
import Footer from '../Components/Footer'

const Squad = () => {
    useEffect (()=>{
        document.title = "ACQC - Squad"
    });
    
    return (
        <div>
            <Nav />
            <Header />
            <QuizTeams />
            <Footer/>
        </div>
    )
}

export default Squad