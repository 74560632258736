import { Container, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import React from 'react'

import { DbContext } from '../../Services/context'
import { useContext } from 'react'

const MainProjects = () => {

    const { mainProjects } = useContext(DbContext)
    return (
        <div id="about">
            {mainProjects.map(project => {
                return (
                    <Container maxW={["100vw", "90vw", "85vw"]} backgroundImage={project.image} backgroundSize="cover" bgPos="center" mb="5vw" bgGradient="linear(to-tr, blue.900, pink.900)" backgroundBlendMode="overlay" borderRadius="2xl" border="2px white" borderStyle="solid">
                        <Text fontSize="3xl" fontWeight="bold" color="white" w="80vw" p="30px" mt="80px">
                            {project.title}
                        </Text>

                        <Text p="0 30px" color="gray.200" fontSize="xl" pb="30px">
                            {project.description}
                        </Text>
                        <a href={project.buttonLink}>
                            <Button bg="none" color="white" border="1px #FFFFFF80" borderStyle="dashed" _hover={{ bg: "#00000040" }} m="0 30px 40px 30px" p="25px" fontSize="xl">{project.buttonText}</Button>
                        </a>
                    </Container>
                )
            })}

        </div>
    )
}

export default MainProjects
