import {
    Button,
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import React from 'react'
import './MindProbe_About.css';
import { DbContext } from '../../Services/context';
import { useContext } from 'react'
import { useToast } from '@chakra-ui/toast';

const About = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { details } = useContext(DbContext);

    const toast = useToast();

    return (
        <div id='mindprobe_about'>
            <h1 id='title'>About</h1>
            <p id='description'>
                The Ananda College Quiz Club began hosting an annual islandwide inter-school quiz competition in 2001. It was named Mind Probe in 2003 under the slogan “The ultimate odyssey for unique excellence.” Aside from the 2007 edition which was cancelled, Mind Probe has been held every year since 2001. In 2017, the competition was split into 2 categories. The junior category only accepts participants under the age of 15, while the senior category is open to all students under the age of 20. The quiz comprises of 2 rounds. First, a table round is held where all the teams answer a written test. The top 2 scorers then participate in the finals where they provide verbal answers to the questions posed to them on stage. Immediately after the conclusion of the finals, an awarding ceremony is held for all competitors.
            </p>
            <div id='videos'>
                <div id='video'>
                    <iframe id='video_frame' src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Freel%2F298625079900991%2F&show_text=false&width=476&t=0" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                    <p id='video_frame_description'>Message From The 2018-19 Quiz Club President<br /><b>Mr. Nimuthu Nimsith Siriwardhana</b></p>
                </div>
                <div id='video'>
                    <iframe id='video_frame' src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Freel%2F722601346660510%2F&show_text=false&width=476&t=0" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                    <p id='video_frame_description'>Message From The 2013-14 Quiz Club President<br /><b>Mr. Mandapa Pandithage</b></p>
                </div>
            </div>
            <div id='stats'>
                <div id='statCard'>
                    <p id='statNumber'>43</p>
                    <p>Junior Teams</p>
                </div>
                <div id='statCard'>
                    <p id='statNumber'>52</p>
                    <p>Senior Teams</p>
                </div>
                <div id='statCard'>
                    <p id='statNumber'>95</p>
                    <p>Total Teams</p>
                </div>
                <div id='statCard'>
                    <p id='statNumber'>70</p>
                    <p>Total Schools</p>
                </div>
            </div>

            <div id='galleryAndSouvenir'>
                <h1 id='title2'>Souvenir & Gallery</h1>
                <div id='galleryAndSouvenirContainer'>
                    <a onClick={onOpen}>
                        <div id='souvenirWrapper'>
                            <img id='souvenirPhoto' alt='Souvenir Image' src='https://lh3.googleusercontent.com/fife/ALs6j_HQnXrMNUZU8U6_CVOaJcfYg-3fnqY2GSuD9Njioxkk_2maeWGowoHGsXRgNmlXYNV28XbngfFWwHCuerQhPe1WZnIMBEOqA_a0GblBv1LUNGwa1BkjGiii8IaRfZiJf7w4fYSTl_iljzMwoLNwiw3VJrhXXR7Jpf8-h0XkrVuQMgX6ALuQyWlIqloY7j3jMyv-dqNyNdL1xQ8uMM1zNUhpnCa5LMBLUbgLQQe7Aj2uCDRkar_A3Wr3AIa5_FYDJsBNfkF7lGdABDo5b_-Gz8Nt-mi6QCMX7ssLCtxXru97fNd9D-dRUnYV5YIF3IwoA7rlbKtkJVEVaZXb93joroHGcOYwifUBIUP9UGD7kWXQWq8cKojOJZpub2Kuoy3ewhlohz5yjmYb_5GXf7zxaAVec1rRQAPBoqNg6MCYVKxJD4hChS8AMoVxbSKWlDyJCDlyrR6NQIYaBxfcIv4GT91M5VEajkXUVtf_kGgc6dJ0Mlr02IEAC4Chy-1bUOO7-NVMtDNuvNVwm2H_iVZjMewBXUfQfnS7Wa3iiNoW7vozjCCYz8k3s9LPLwOGFnu5LRqGwqpfom93BsVas9RgkPnWe_8eJXMelIaQ7hOI8oyPgqsd9DU3wisV4SS_e1SpRqhsE7tOM_uTksDhLpGyXPwoH9mt03rFWiISWAR5AfYmMhjZrj4A1JII2tM4BboANjLolS3orPyCraVelQESlO8U4fc0Yezh5x_mhWxg2mbDq5xXM8ct1aECVL3et3h9EIzk1KIIAg9pFjg8LLSl6mjLaR662Pa00NwsRkASeT0EXcCV8BMW1zNyI8yYkVKEKlxhIqgfihXPSE4q3vuN2LIUScXK67CuyIgt38P6DyDFPV4v5YIMOLRMO0U5WTipuOU9QpslazIaziuhb5iwWr92oqRl4v0eN7M6w5fMgQ7Zm2fmFZgdnQ96Oz9gUd66LtY18xKWt7l0lwGeqH3cx6e0J8bMD0QcjFcstfuXzuGB-B2YhjX9mN9TlVOIIIgWc7T6zkIZSN3hj1iNRgtdQNmLi1l2jgEsWKvTFyMgxKoqzbuHlkjc-8NyInGMfbmzklP1rqgd5fBVYp47x2KKK-IacODkNVXcATN-jV_NaYa4jmPtMv6m-32VuOojpp3feEeErcWCpqoZ_gF530_CwGJogrf5uVf4C-bEoMpxQmvtKPwZOuwqFq6NxYjSTuuCzrCbS-CMDF2SNQ2PnYeKPsbNXfKOcdCcghZc5sbBgO8y5_Feb6pXNPtI9WUAJY9mbZ83p0nKABjSjK81ZGVMFvcdpT--NJRMfdFj11EJjSBqGm0kdEd4kvbqTXknXlUyHhMIQK9uGc_WPF0GB8x2jn9lPb8R1U6j2s0V0CvWVNmLRMjnHA7Xnum-AKcWfg0FBOvtX4_WFKu7rBd2z71vowzCf1XIA0TyYjsxXCkBRXGNnzPbumH2_KUo39eZJrYGtDF7edQx4Fb3mX4gNnUjXWSR6oym_BRQn_iGWnZ_B_PV9S7XCprgvjc7Gi_0q8mpZ2X4linKLiuKcbxcT429eHEj4010XbcNLgXDl21sjXAukewccC0ooDX97BSsXigO2AKbOeTUfMoO7teym5jpXr0d8OaG26qzupPxNQ2aiNpp4gWZFZicsPi8z-UC7ecf7Jejm3CkY8nwNatD9ZG8t-nhyewshb0wwJ-0C-YDBqU8vpkuHdQa_HhKf_sUoA=w1910-h882'></img>
                            <Button>See the Souvenir</Button>
                        </div>
                    </a>
                    {details && details.map(details => {
                        if (details.mindprobeAlbumLink === "") {
                            return (
                                <a onClick={() => {
                                    toast({
                                        title: 'Photo gallery is currently unavailable',
                                        status: 'warning',
                                        colorScheme: 'yellow',
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                }}>
                                    <div id='galleryWrapper'>
                                        <img
                                            id='gallery'
                                            src='https://lh3.googleusercontent.com/fife/ALs6j_EDolAFYnZ3iLt5hjsxXIVDGAYhUni4t6D175BOdEdlEpunqCuUwYdTC8eYDxwUjLuLNJyfUhQwfbbep0_WaRzh8L2uL2ljDAXghUEmUjskiGDyi6Gv_kmLhM7sPGTSqCMhO7cM1EMje0DOKqJD9pRqzqmds12rJT8yjqrJsHxN6QxCnZ2PbCC0gctpGn_HOqys4mpGLYgXuJC1t74-nWp5feg2ByLm8NLy7Pg8NrUjrNJzZhnuifcuy_JRdZRBPjeGSLmSQkQm0pl_Z4dIUmbbuF4vTGsRZaTRbPoNeLMPROIqUpfvTYyCrCU9VHVPRxpSVc4HZVMpjUzO0FjZ4xd_682UuYH7IaieFSiLKhHaJZvMGCpwGjtW6PBYIMcXyJvvxmPXQRZe5YQhTbGMrhuCSRrQezHf4Z-VKRXZY0hZDyG5O9H9D-EKNgNVrlGAYdVU9HkcT2rm7Y_bVshgsgYW_c_j34OZtHnXGd0AxRIKm23hdhsakVoMFqDNuNzKgDQwq-8h06SQz8YsRtf8rWtJCyK6vrH4x0d-LZ3ofbwpmsJPqVi8OKK8AdC2WoDp7izLp0cQILvMcGcs2eRWBEfw4H-d9LkCJZSjRmvnbPDrN4Mo4v_o42e9jD3NuU56mrVYB8kqp-BnuDm9KHfKb3Eb2rZ_KoTxxOvz11xil0sYmjJpgzyUgvWXJLZ1DP-FWN-sdDjYw25KtOJ1k6Xn95zUzqdwaTiqFGAqrjMf50qSEs8HMoyiDG5TycstCm46-w7hgd73601gKFOp3KgBf8jvtVaJGPL3ZzxZhXBQ3a5OgBHSBcl4SBvQ2qmB8-djkpdYeOf88y4kcKIq6h8mZ5ey-b3cIXtTOFnKmzDN8ZFNvjAfl_VXxZUxWdyE0GLZmMnofYGcvdoM8z-PpAS7UD6VrV0g_McJJ9Ytvm_Fv9fAICfmAwf0GDxm470-NUSYdVY7PTMo6DzdoZkB8o8pptdrnrPlwEQ3oZBUG2gdNPKNqt3M8s7KaqSdc_MT9IGwTAOWxkghc6fJr_tgzVpwH5a8WxKqEPUGRd4_fiGSeOWKfY7WJax7TmTl5ylvb0hZuZuLimdfDJyeq2n2vsKKeOnPMu9GPi798DmfQZ0ZD05ips-cY5YM6s5p5I01P8RdG_VeEHQMb054HqLQsbcqOWXIstDTIWtGMyM1lE3Gz5VZVXwQ5YGoN2WtH4XdeD7NJ0aK4Frrug3TJa0ax-KaCJU8KZ82lA5Nwde7hnzzRx9p8TBxSnbncUdgFpneNVFkyA9ctjYISxT3ZFRV-I6mkBQtRe8ymDqGmyQCzSgwxQRviaWRv_pNg5mielXEghpDVUjqjGQJ6bZA2sE3A89mj1yxXpJN3FWN_JcUKq_DieuWF_xrEHJbJqC7hPzckFJiRX5Kjxv0oMIMHiNEQCxDSkPSpA8J4FKTtnvukvXQS8bIH7IZvtuHUJHK-66EFIWkY3fU9jE805YXbZSt2LrtmtOOQONPIYbpJk-FHvyaWxbgaWUglOcrdwRVK1k7q-BJ-nWN4d9WOjdMPxRpwoObMAoFGgsWzabIrS0FAoYc-uxBUeglOXrSv6qJ8hJOwLEf2r-Rm0PLxkjoQuKSHd1wXIQ9FpWywNATOhuGrmdV-icmzR2p9VBg0oM9wbfwfjM2EWx1AP2se0HmvEcVrlG_Q3TsPH-4YvdoMrep_GWJubB8fTZ_-pP1fl8voQijBDc=w1910-h882'
                                            alt='Gallery Image'
                                        />
                                        <Button>Go to Photo Gallery</Button>
                                    </div>
                                </a>
                            );
                        } else {
                            return (
                                <a target='_blank' href={details.mindprobeAlbumLink} rel="noreferrer">
                                    <div id='galleryWrapper'>
                                        <img
                                            id='gallery'
                                            src='https://lh3.googleusercontent.com/fife/ALs6j_EDolAFYnZ3iLt5hjsxXIVDGAYhUni4t6D175BOdEdlEpunqCuUwYdTC8eYDxwUjLuLNJyfUhQwfbbep0_WaRzh8L2uL2ljDAXghUEmUjskiGDyi6Gv_kmLhM7sPGTSqCMhO7cM1EMje0DOKqJD9pRqzqmds12rJT8yjqrJsHxN6QxCnZ2PbCC0gctpGn_HOqys4mpGLYgXuJC1t74-nWp5feg2ByLm8NLy7Pg8NrUjrNJzZhnuifcuy_JRdZRBPjeGSLmSQkQm0pl_Z4dIUmbbuF4vTGsRZaTRbPoNeLMPROIqUpfvTYyCrCU9VHVPRxpSVc4HZVMpjUzO0FjZ4xd_682UuYH7IaieFSiLKhHaJZvMGCpwGjtW6PBYIMcXyJvvxmPXQRZe5YQhTbGMrhuCSRrQezHf4Z-VKRXZY0hZDyG5O9H9D-EKNgNVrlGAYdVU9HkcT2rm7Y_bVshgsgYW_c_j34OZtHnXGd0AxRIKm23hdhsakVoMFqDNuNzKgDQwq-8h06SQz8YsRtf8rWtJCyK6vrH4x0d-LZ3ofbwpmsJPqVi8OKK8AdC2WoDp7izLp0cQILvMcGcs2eRWBEfw4H-d9LkCJZSjRmvnbPDrN4Mo4v_o42e9jD3NuU56mrVYB8kqp-BnuDm9KHfKb3Eb2rZ_KoTxxOvz11xil0sYmjJpgzyUgvWXJLZ1DP-FWN-sdDjYw25KtOJ1k6Xn95zUzqdwaTiqFGAqrjMf50qSEs8HMoyiDG5TycstCm46-w7hgd73601gKFOp3KgBf8jvtVaJGPL3ZzxZhXBQ3a5OgBHSBcl4SBvQ2qmB8-djkpdYeOf88y4kcKIq6h8mZ5ey-b3cIXtTOFnKmzDN8ZFNvjAfl_VXxZUxWdyE0GLZmMnofYGcvdoM8z-PpAS7UD6VrV0g_McJJ9Ytvm_Fv9fAICfmAwf0GDxm470-NUSYdVY7PTMo6DzdoZkB8o8pptdrnrPlwEQ3oZBUG2gdNPKNqt3M8s7KaqSdc_MT9IGwTAOWxkghc6fJr_tgzVpwH5a8WxKqEPUGRd4_fiGSeOWKfY7WJax7TmTl5ylvb0hZuZuLimdfDJyeq2n2vsKKeOnPMu9GPi798DmfQZ0ZD05ips-cY5YM6s5p5I01P8RdG_VeEHQMb054HqLQsbcqOWXIstDTIWtGMyM1lE3Gz5VZVXwQ5YGoN2WtH4XdeD7NJ0aK4Frrug3TJa0ax-KaCJU8KZ82lA5Nwde7hnzzRx9p8TBxSnbncUdgFpneNVFkyA9ctjYISxT3ZFRV-I6mkBQtRe8ymDqGmyQCzSgwxQRviaWRv_pNg5mielXEghpDVUjqjGQJ6bZA2sE3A89mj1yxXpJN3FWN_JcUKq_DieuWF_xrEHJbJqC7hPzckFJiRX5Kjxv0oMIMHiNEQCxDSkPSpA8J4FKTtnvukvXQS8bIH7IZvtuHUJHK-66EFIWkY3fU9jE805YXbZSt2LrtmtOOQONPIYbpJk-FHvyaWxbgaWUglOcrdwRVK1k7q-BJ-nWN4d9WOjdMPxRpwoObMAoFGgsWzabIrS0FAoYc-uxBUeglOXrSv6qJ8hJOwLEf2r-Rm0PLxkjoQuKSHd1wXIQ9FpWywNATOhuGrmdV-icmzR2p9VBg0oM9wbfwfjM2EWx1AP2se0HmvEcVrlG_Q3TsPH-4YvdoMrep_GWJubB8fTZ_-pP1fl8voQijBDc=w1910-h882'
                                            alt='Gallery Image'
                                        />
                                        <Button>Go to Photo Gallery</Button>
                                    </div>
                                </a>
                            );
                        }
                    })}
                </div>
            </div>

            <Drawer
                isOpen={isOpen}
                placement='right'
                size='full'
                onClose={onClose}
            // finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>MindProbe23 Souvenir</DrawerHeader>

                    <DrawerBody>
                        <iframe id='mindprobeSouvenir' allowfullscreen="allowfullscreen" scrolling="no" class="fp-iframe" src="https://heyzine.com/flip-book/57589af590.html"></iframe>
                    </DrawerBody>

                </DrawerContent>
            </Drawer>
        </div>
    )
}

export default About
