import { React } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  IconButton,
  VStack,
  HStack,
} from "@chakra-ui/react"
import { Image } from '@chakra-ui/image';
import logo from '../../Images/navLogo.png'
import { Link } from '@chakra-ui/layout';
import { ArrowRightIcon } from '@chakra-ui/icons'
import { signOut } from '@firebase/auth';
import { auth } from '../../Services/firebase';
import { useToast } from '@chakra-ui/toast';
import { NavLink } from 'react-router-dom';

const Nav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast();

  const handleLogout = () => {
    onClose();
    try {
      signOut(auth);
      toast({
        title: "Successfully logged out",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <div>
      <HStack w="100vw" justify="space-between">
        <IconButton icon={<ArrowRightIcon textColor="white" />} bg="black" onClick={onOpen} m="20px" _hover={{ background: "gray.800" }} />
        <Image src={logo} alt="logo" p="20px" />
      </HStack>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}

      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>ACQC Admin Panel</DrawerHeader>

          <DrawerBody alignItems="flex-start">
            <VStack mt="50px">
              <NavLink

                to="/admin/squad"
                exact
                style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', color: '#4a5568' }}
                activeStyle={{ color: '#151515', fontWeight: 'bold' }}>
                Squad
              </NavLink>

              <NavLink

                to="/admin/main-achievements"
                exact
                style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', color: '#4a5568' }}
                activeStyle={{ color: '#151515', fontWeight: 'bold' }}>
                Main Achievements
              </NavLink>

              <NavLink

                to="/admin/achievements"
                exact
                style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', color: '#4a5568' }}
                activeStyle={{ color: '#151515', fontWeight: 'bold' }}>
                Other Achievements
              </NavLink>

              <NavLink

                to="/admin/history"
                exact
                style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', color: '#4a5568' }}
                activeStyle={{ color: '#151515', fontWeight: 'bold' }}>
                History
              </NavLink>

              <NavLink


                to="/admin/projects"
                exact
                style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', color: '#4a5568' }}
                activeStyle={{ color: '#151515', fontWeight: 'bold' }}>
                Projects
              </NavLink>

              <NavLink

                to="/admin/tic"
                exact
                style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', color: '#4a5568' }}
                activeStyle={{ color: '#151515', fontWeight: 'bold' }}>
                Teachers in Charge
              </NavLink>

              <NavLink

                to="/admin/board"
                exact
                style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', color: '#4a5568' }}
                activeStyle={{ color: '#151515', fontWeight: 'bold' }}>
                Board
              </NavLink>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={handleLogout}>
              Log Out
            </Button>
            <Link href="/">
              <Button bg="black" textColor="white" _hover={{ background: "gray.800" }}>View Site</Button>
            </Link>


          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  )
}

export default Nav
