import { Center, Container } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/layout'
import React from 'react'

const About = () => {
    return (
        <div>
            <Container maxW="100%" bg="gray.900" p={['50px 30px', '80px']} id="about">
                <Center flexDir="column">
                    <Text textColor="white"  fontSize={['4xl', '5xl', '6xl']} fontWeight="bold" letterSpacing="wider">About</Text>
                    <Text textColor="gray.300" fontSize={['xl', 'xl', '2xl']} mt="30px" p={['0px', '0 50px']} textAlign="center">
                    The Ananda College Quiz Club was founded in 1974 with the goal of taking young Anandians to the highest levels of competitive quizzing. We strive to broaden the horizons of our members’ knowledge by introducing them to information from beyond the strict confines of the school curriculum. Our efforts have borne fruit in the past and former members of the Ananda College quiz team often end up being knowledgeable professionals, capable of taking an innovative and informed approach to their careers.

                    </Text>
                </Center>
                
            </Container>
        </div>
    )
}

export default About
