import { Image } from '@chakra-ui/image'
import { Link } from '@chakra-ui/react'
import { Container, HStack, Text, Wrap } from '@chakra-ui/layout'
import { Center } from '@chakra-ui/react'
import { React, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import logoBlack from '../../Images/Logo-Black.png'
import logoWhite from '../../Images/Logo-white.png'
import { FaFacebookSquare, FaInstagram, FaYoutube, } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { DbContext } from '../../Services/context';
import './MindProbe_Home.css';

const Footer = (props) => {
    const { details } = useContext(DbContext);
    let boardImg = "";


    // eslint-disable-next-line array-callback-return
    details && details.map(details => {
        boardImg = details.boardImg;
    })

    return (
        <div>
            <Container id='mindprobe_footer' overflow="hidden" maxW="100%">
                <div id='footer_background'></div>
                <div id='footer_wrapper'>
                    <Container id='mindprobe_footer_img' maxW="100%" className="groupPhoto" p="0" mb="50px">
                        <Center flexDir={["column", "column", "row"]} alignItems="center">
                            <Image
                                src={boardImg}
                                maxW="100%"
                                w="1000px"
                                borderRadius="15px"
                            />
                        </Center>
                    </Container>


                    <Center flexDir="column">
                        <Image src={props.color === "white" ? logoBlack : logoWhite} alt="logo" w="100px" />

                        <Text textColor={props.color === "white" ? "gray.500" : "gray.200"} w={['300px', '400px', '500px']} textAlign="center" mt="30px">Errand From the Unique</Text>

                        <Wrap spacing="80px" mt="50px" display={['none', 'block']}>
                            <NavLink
                                to="/"
                                exact
                                activeStyle={{
                                    fontWeight: "bold",
                                    color: props.color === "white" ? "black" : "white"
                                }}
                            >
                                <Text fontSize="xl" textColor={props.color === "white" ? "gray.900" : "gray.100"}>Home</Text>
                            </NavLink>

                            <NavLink
                                to="/history"
                                activeStyle={{
                                    fontWeight: "bold",
                                    color: props.color === "white" ? "black" : "white"
                                }}
                            >
                                <Text fontSize="xl" textColor={props.color === "white" ? "gray.900" : "gray.100"}>History</Text>
                            </NavLink>

                            <NavLink
                                to="/achievements"
                                activeStyle={{
                                    fontWeight: "bold",
                                    color: props.color === "white" ? "black" : "white"
                                }}
                            >
                                <Text fontSize="xl" textColor={props.color === "white" ? "gray.900" : "gray.100"}>Achievements</Text>
                            </NavLink>

                            <NavLink
                                to="/projects"
                                activeStyle={{
                                    fontWeight: "bold",
                                    color: props.color === "white" ? "black" : "white"
                                }}
                            >
                                <Text fontSize="xl" textColor={props.color === "white" ? "gray.900" : "gray.100"}>Projects</Text>
                            </NavLink>

                            <NavLink
                                to="/squad"
                                activeStyle={{
                                    fontWeight: "bold",
                                    color: props.color === "white" ? "black" : "white"
                                }}
                            >
                                <Text fontSize="xl" textColor={props.color === "white" ? "gray.900" : "gray.100"}>Squad</Text>
                            </NavLink>

                            <NavLink
                                to="/mindprobe"
                                activeStyle={{
                                    fontWeight: "bold",
                                    color: props.color === "white" ? "black" : "white"
                                }}
                            >
                                <Text fontSize="xl" textColor={props.color === "white" ? "gray.900" : "gray.100"}>MindProbe</Text>
                            </NavLink>
                        </Wrap>
                        <HStack w={["60vw", "50vw", "30vw"]} justifyContent="space-between" mt="30px">
                            <Link href="https://bit.ly/ACQC_Facebook" target="_blank"><FaFacebookSquare style={{ fontSize: "25px", color: props.color === "white" ? "#171923" : "#fefefe" }} /></Link>
                            <Link href="mailto:acqc1974@gmail.com" target="_blank"><MdEmail style={{ fontSize: "25px", color: props.color === "white" ? "#171923" : "#fefefe" }} /></Link>
                            <Link href="https://bit.ly/ACQC_Instagram" target="_blank"><FaInstagram style={{ fontSize: "25px", color: props.color === "white" ? "#171923" : "#fefefe" }} /></Link>
                            <Link href="https://bit.ly/ACQC_YouTube" target="_blank"><FaYoutube style={{ fontSize: "25px", color: props.color === "white" ? "#171923" : "#fefefe" }} /></Link>
                        </HStack>
                        <Text w="90vw" textAlign="center" fontSize="md" mt="50px" textColor={props.color === "white" ? "gray.500" : "gray.500"}>Copyright © 2024. ACQC. All rights reserved.</Text>

                        <Text w="90vw" textAlign="center" fontSize="md" mt="0px" textColor={props.color === "white" ? "gray.500" : "gray.500"}>A project by ACQC 2023-2024 Board of Officials</Text>
                        <Link><Text w="90vw" textAlign="center" fontSize="md" mt="10px" textColor={props.color === "white" ? "gray.800" : "gray.200"}>Webpage designed and developed by Nethula Peiris</Text></Link>
                    </Center>
                </div>
            </Container>
        </div>
    )
}

export default Footer
