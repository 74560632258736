import {
    Heading, Link, keyframes, usePrefersReducedMotion, Button,
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import { Center, Container, Text } from '@chakra-ui/layout'
import React from 'react'
import { motion } from 'framer-motion'
import image from '../../Images/homeBG.jpg'
import { ChevronDownIcon } from '@chakra-ui/icons'
import './BrainiacBlitz_About.css';

const About = () => {
    return (
        <div id='brainiacBlitz_about'>
            <p id='description'>
                {/* <h1>About</h1> */}
                Brainiac Blitz is an all island online individual quiz competition organized by the Quiz Club of Ananda College in collaboration with the Quiz Club of Mahamaya Girls' College.
            </p>
            <div id='brainiacBlitz_timeline'>
                <div id='container'>
                    <div id='card'>
                        <div id='circle'></div>
                        <h1>Registration Open</h1>
                        <p>22-05-2024</p>
                    </div>
                    <div id='card'>
                        <div id='circle'></div>
                        <h1>Registration Close</h1>
                        <p>01-06-2024</p>
                    </div>
                    <div id='card'>
                        <div id='circle'></div>
                        <h1>Round One</h1>
                        <p>02-06-2024</p>
                    </div>
                    <div id='card'>
                        <div id='circle'></div>
                        <h1>Round 2,3,4</h1>
                        <p>08-06-2024</p>
                    </div>
                    <div id='card'>
                        <div id='circle'></div>
                        <h1>Winners Announced</h1>
                        <p>08-06-2024</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
