import React, { useEffect } from 'react'
import Header from '../Components/History/Header'
import Nav from '../Components/Nav'
import Description from '../Components/History/Description'
import Points from '../Components/History/Points'
import Footer from '../Components/Footer'

const History = () => {
    useEffect (()=>{
        document.title = "ACQC - History"
    });
    
    return (
        <div>
            <Nav />
            <Header />
            <Description />
            <Points />
            <Footer />
        </div>
    )
}

export default History

