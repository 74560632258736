import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import {
  Input, Textarea, Button, VStack, Select, IconButton, Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { db } from '../../Services/firebase';
import { Redirect } from "react-router"
import { doc, setDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import { useToast } from '@chakra-ui/toast';
import { AuthContext, DbContext } from '../../Services/context';
import { DeleteIcon } from '@chakra-ui/icons';
import { Container, HStack } from '@chakra-ui/layout';
import Nav from './Menu';

const AchievementsEdit = () => {
  useEffect(() => {
    document.title = "ACQC - Admin | Other Achievements Update"
  });
  
  const toast = useToast();
  const { user } = useContext(AuthContext)

  const [deleteRef, setDeleteRef] = useState('');
  const { achievements } = useContext(DbContext);

  const [isOpenAlert, setIsOpen] = React.useState(false)
  const onCloseAlert = () => {
    setIsOpen(false);
    setDeleteRef('');
  }
  const cancelRef = React.useRef()

  const deleteRank = async () => {
    try {
      await deleteDoc(doc(db, "Achievements", deleteRef));
      toast({
        title: "Achievement Deleted",
        description: `Achievement ${deleteRef} has been deleted`,
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }

    catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsOpen(false)
  }

  const handleDelete = async (rankName) => {

    setDeleteRef(rankName);

    setIsOpen(true)


  }

  let year = (new Date()).getFullYear();

  let yearlist = year - 2000;
  console.log(year)
  const years = Array.from(new Array(yearlist), (val, index) => year - index);

  return (
    <>
      {!!user ? (
        <>
          <Nav />

          <AlertDialog
            isOpen={isOpenAlert}
            leastDestructiveRef={cancelRef}
            onClose={onCloseAlert}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete achievement?
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onCloseAlert}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={deleteRank} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <Container maxW="90vw" display="flex" flexDir="row">
            <Container maxW="45vw" p="50px 50px" display="flex" flexDir="column" alignItems="center" justifyContent="flex-start" m="0">
              <Text fontSize="3xl" mb="50px" fontWeight="semibold" w="50vw" textAlign="left" p="0px 50px">Add/Change Achievements</Text>
              <Formik
                initialValues={{ title: '', description: '', place: '', year: '' }}
                validate={values => {
                  const errors = {};
                  return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {

                  values.time = serverTimestamp();

                  try {

                    setDoc(doc(db, "Achievements", values.title), values);

                    toast({
                      title: 'Ratings updated',
                      description: '',
                      status: 'success',
                      duration: 5000,
                      isClosable: true,
                    });

                  }

                  catch (error) {
                    toast({
                      title: 'OOPS, something happened',
                      description: error.message,
                      status: 'error',
                      duration: 9000,
                      isClosable: true,
                    });
                  }

                  setSubmitting(false);

                  resetForm();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack spacing={4} w="45vw">
                      <Input
                        type="text"
                        name="title"
                        w="45vw"
                        placeholder="Title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />

                      <HStack w="45vw" justify="space-between">
                        <Select placeholder="Select Place"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="place"
                          w="20vw"
                          value={values.place}
                        >
                          <option value="1">1st</option>
                          <option value="2">2nd</option>
                          <option value="3">3rd</option>
                        </Select>

                        <Select placeholder="Select Year"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          w="20vw"
                          name="year"
                          value={values.year}
                        >

                          {years.map(year => {
                            return <option value={year}>{year}</option>
                          })}

                        </Select>
                      </HStack>



                      <Textarea
                        type="text"
                        name="description"
                        w="45vw"
                        placeholder="Description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                    </VStack>

                    {errors.password && touched.password && errors.password}
                    <Button type="submit" disabled={isSubmitting} mt="20px">
                      Submit
                    </Button>

                  </form>
                )}
              </Formik>
            </Container>

            <Container maxW="45vw" bg="white" h="80vh" borderRadius="2xl" boxShadow='lg' position="fixed" right="50px" top="150px" p="50px" overflowY="scroll">
              {achievements && achievements.map((achievement) => {
                return (

                  <Container className="taskCard" display="flex" flexDirection="row" justifyContent="space-between" mb="20px" p="0" maxW="45vw" borderBottom="1px" borderColor="gray.200" pb="10px" position="relative" >
                    <Container maxW="45vw" display="flex" flexDirection="column">
                      <Text as="h1" fontSize="xl" textColor="black" mr="30px" fontWeight="medium">{achievement.title}</Text>

                      <Text as="h1" fontSize="lg" textColor="gray.600" cursor="pointer">{achievement.description}</Text>
                    </Container>

                    <IconButton
                      icon={<DeleteIcon />}
                      value={achievement.title} onClick={() => {
                        handleDelete(achievement.title)
                      }}
                    />


                  </Container>
                )

              })}
            </Container>
          </Container>
        </>
      ) : (
        <Redirect to="/login" />
      )}
    </>
  )
};

export default AchievementsEdit;