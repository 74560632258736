import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import {
  Input, Textarea, Button, VStack, IconButton, Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Checkbox,
} from '@chakra-ui/react';
import { db } from '../../Services/firebase';
import { Redirect } from "react-router"

import { doc, setDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import { useToast } from '@chakra-ui/toast';
import { AuthContext, DbContext } from '../../Services/context';
import { DeleteIcon } from '@chakra-ui/icons';
import { Container, HStack } from '@chakra-ui/layout';
import Nav from './Menu';

const ProjectsUpdate = () => {
  useEffect(() => {
    document.title = "ACQC - Admin | Projects Update"
  });

  const toast = useToast();
  const { user } = useContext(AuthContext)

  const [deleteRef, setDeleteRef] = useState('');
  const { projects, mainProjects } = useContext(DbContext);

  const [isOpenAlert, setIsOpen] = React.useState(false)
  const onCloseAlert = () => {
    setIsOpen(false);
    setDeleteRef('');
  }
  const cancelRef = React.useRef()
  const deleteRank = async () => {
    try {
      await deleteDoc(doc(db, "projects", deleteRef));
      await deleteDoc(doc(db, "mainProjects", deleteRef));
      toast({
        title: "Project Deleted",
        description: `Project ${deleteRef} has been deleted`,
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }

    catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsOpen(false)
  }

  const handleDelete = async (rankName) => {

    setDeleteRef(rankName);

    setIsOpen(true)

  }

  return (
    <>
      {!!user ? (
        <>
          <Nav />

          <AlertDialog
            isOpen={isOpenAlert}
            leastDestructiveRef={cancelRef}
            onClose={onCloseAlert}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Project?
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onCloseAlert}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={deleteRank} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <Container maxW="90vw" display="flex" flexDir="row">
            <Container maxW="45vw" p="50px 50px" display="flex" flexDir="column" alignItems="center" justifyContent="flex-start" m="0">
              <Text fontSize="3xl" mb="50px" fontWeight="semibold" w="50vw" textAlign="left" p="0px 50px">Manage Projects</Text>
              <Formik
                initialValues={{ title: '', description: '', image: '', time: '', mainCheck: false }}
                validate={values => {
                  const errors = {};
                  return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {

                  values.time = serverTimestamp();

                  try {

                    if (values.mainCheck === true) {
                      setDoc(doc(db, "mainProjects", values.title), values);

                    } else {
                      setDoc(doc(db, "projects", values.title), values);
                    }

                    toast({
                      title: 'Projects updated',
                      description: '',
                      status: 'success',
                      duration: 5000,
                      isClosable: true,
                    });

                  }

                  catch (error) {
                    toast({
                      title: 'OOPS, something happened',
                      description: error.message,
                      status: 'error',
                      duration: 9000,
                      isClosable: true,
                    });
                  }

                  setSubmitting(false);

                  resetForm();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack spacing={4} w="45vw">
                      <Input
                        type="text"
                        name="title"
                        w="45vw"
                        placeholder="Title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />

                      <Textarea
                        type="text"
                        name="description"
                        w="45vw"
                        placeholder="Description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />



                      <Input
                        type="text"
                        name="image"
                        w="45vw"
                        placeholder="Image URL"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.image}
                      />

                      <HStack mt="30px" w="45vw">
                        <Checkbox as="Field" size="lg" type="checkbox" name="mainCheck" value="mainCheck"
                          checked={values.mainCheck = !values.mainCheck}
                          onChange={handleChange}
                          onBlur={handleBlur} />
                        <Text ml="20px" textColor="gray.600">Main Project</Text>
                      </HStack>
                    </VStack>

                    {errors.password && touched.password && errors.password}
                    <Button type="submit" disabled={isSubmitting} mt="20px">
                      Submit
                    </Button>

                  </form>
                )}
              </Formik>
            </Container>

            <Container maxW="45vw" bg="white" h="80vh" borderRadius="2xl" boxShadow='lg' position="fixed" right="50px" top="150px" p="50px" overflowY="scroll">
              {mainProjects && mainProjects.map((project) => {
                return (

                  <Container className="taskCard" display="flex" flexDirection="row" justifyContent="space-between" mb="20px" p="0" maxW="45vw" borderBottom="1px" borderColor="gray.200" pb="10px" position="relative" >
                    <Container maxW="45vw" display="flex" flexDirection="column">
                      <Text textColor="white" bg="black" position="absolute" top="-12px" left="-25px" fontSize="13px" p="2px 5px" borderRadius="5px">Main</Text>
                      <Text as="h1" fontSize="xl" textColor="black" mr="30px" fontWeight="medium">{project.title}</Text>

                      <Text as="h1" fontSize="lg" textColor="gray.600" cursor="pointer">{project.description}</Text>
                    </Container>

                    <IconButton
                      icon={<DeleteIcon />}
                      value={project.title} onClick={() => {
                        handleDelete(project.title)
                      }}
                    />


                  </Container>
                )

              })}

              {projects && projects.map((project) => {
                return (

                  <Container className="taskCard" display="flex" flexDirection="row" justifyContent="space-between" mb="20px" p="0" maxW="45vw" borderBottom="1px" borderColor="gray.200" pb="10px" position="relative" >
                    <Container maxW="45vw" display="flex" flexDirection="column">

                      <Text as="h1" fontSize="xl" textColor="black" mr="30px" fontWeight="medium">{project.title}</Text>

                      <Text as="h1" fontSize="lg" textColor="gray.600" cursor="pointer">{project.description}</Text>
                    </Container>

                    <IconButton
                      icon={<DeleteIcon />}
                      value={project.title} onClick={() => {
                        handleDelete(project.title)
                      }}
                    />


                  </Container>
                )

              })}
            </Container>
          </Container>
        </>
      ) : (
        <Redirect to="/login" />
      )}
    </>
  )
};

export default ProjectsUpdate;

