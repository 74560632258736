import React, { useEffect } from 'react'
import Header from '../Components/Achievements/Header'
import MainAchievements from '../Components/Achievements/MainAchievements'
import Footer from '../Components/Footer'
import OtherAchievements from '../Components/Achievements/OtherAchievements'
import Nav from '../Components/Nav'

const Achievements = () => {
    useEffect (()=>{
        document.title = "ACQC - Achievements"
    });
    
    return (
        <div>
            <Nav />
            <Header />
            <MainAchievements />
            <OtherAchievements />
            <Footer color="black" />
        </div>
    )
}

export default Achievements
