import React, { useEffect, useState } from 'react';
import { Center, Container, Wrap } from '@chakra-ui/layout';
import { Table, Thead, Tbody, Tr, Td, TableContainer, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Tabs, TabList, TabPanels, Tab, TabPanel, Button } from '@chakra-ui/react';
import { Text } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';

const Results = () => {
    useEffect(() => {
        document.title = "ACQC - MindProbe23 | Results"
    });

    const toast = useToast();
    const [toastShown, setToastShown] = useState(false);

    useEffect(() => {
        const handleLoad = () => {
            if (!toastShown) {
                toast({
                    status: 'info',
                    colorScheme: 'green',
                    title: 'Congratulations to all the Winners',
                    duration: 10000,
                    isClosable: true,
                    onCloseComplete: () => {
                        setToastShown(true);
                    },
                });
            }
        };

        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, [toast, toastShown]);

    const RoyalLogo = "https://lh3.googleusercontent.com/fife/ALs6j_HaByG8ISczEmhyIw8kUvU-nA1mnl242mNCSXOnU8DBZxs37Svb8prk_yEAvUq80N3Q5iKk1HwxnsU19iYOJRioSjPBlBdB0codUH_K69FAYoI_IVG965R_43Mg5UEwQo7msSzObL-kzQDH7o8En3A1j_eO2dUlULkKgzqQ_KVqPds9NK8hmcYRYZ6_kYPx-vwxqmm6V5aStyzvzlxsfK1hAuzT5VRxGsZPI0JH58__CRScGd0nlD4EgzvDuTwdQDUzePYFwsSxxBSd9fYq2I3VMk8Hw1pSL7-qNJcEP8HNdHVY6vR5Vvsf3OpYNnLyBHX1e09gImsYcHKss6TytZM92Zx41FIeFf98mLyROcG4tsOQgzqQciJu_2UiygDkd7VUf80oPNf5SZdyt0b2KsRO3IidUchyTipBQmV1FSM1ZlGJIWon0tQJxYQ8U3TwIoPoL8VGhbdyocw6pQhh5LNEetdjW2TDf4_js8tr6JjdlqYoogeURa3Z-aSmB52wetY45mr-21Fhxm8ZDEl5sEik8Fu87hGOS_9vy2UFl_LiElYRpOT4zjvd07FIZl5PW33c-JqCzSqkBU5yc1hvRjMVlaDS_NJN3rW1n59jUP-TZVr1AbxkBZgxr3oLi5JgC1EeTZ0VxYp556DFEejzt2OVsPV61cceVInZCAOq9jLnsl2dclpoHYqdT2PwCHvfeYY9Z7-IWmi_gLcToi_5c6vqnxw32-ufMujMlZCsMUi0QIa2-vl9CF32Pdw2MWG0k1_MPakp66Qi3gMDxhU-DSb_I12HM_g_DhJAznbBW50hd44hUq9mJtwzTcKGux4O6Z5tuqdJTPr_0XloXsPDM9-Mh1AjkXGyZW7ALJqx6lJ2uqpj1CyYGTQCpc4AFhTKPujjnOsXOZi8l5pgyMmzpmQOXBHMzzruJrMCwsaTQ1-_l4-0NcdFk1L5BwawTtnwRy7m2-fOOf-35eOknFNEZhg89CyaZ-RYbOVR8v0I_XLdY2dFaLh5Oew9UsRxH-XOPBpopHcZCRfdKyFNZ4rDZYqpNraodD4iHhXP0FrOStalKIlKYx6_MCsrcuaADTZX5-gK2qH3igLM_xCwaK2ZFrnmvuC79atzs_HMSEfbFLnusuXledsViaj4WpwEAjx_PYkWfBrXYN0wNfkHnzR9iFfOJnBddOFiIW4E6uKCeh4gJy2VHLiACCs_mf41TrQE0rH__EQfrWpFSdzOR-qmiXgplKI_gNQN-FCtqoVmUJRgb9UG4d2lF77-itUQBwQUrFF70EsZmvU7lTPNbi6SP51B6S-5my8GSoeJ720k6IXBUsdntUhW-hMERGSoTx_W8IwrZDcqOLjfPT81QX9GNki-IdNCVasPbaoXPCeET8DkTKfKAJcd6_fjk4muaacIYAaUcs_73Xn-4Q5JCMXKvHVBSW8C64AQI5dzu0xaeDn5-vj_zLEpCt2mIu7NpAErFcBkoy1R5ToLF--Rbe5FXYoK7Ka9Xalwcs-P4GaDHpBiUj3rxZasOEdl316sw6xRPN3tZh5BlbuTiWqdFvGkTUqtiQInx7MZFEsN32bsL3GAbn2d___KjCTxLmGfAmbQLZ3Kc-CS_3OJFLAIgaZXwsq8uO2Ux_g4gnkXGyj0gu8S_fraovmWtQ_YqEqReUCeffaGNBPoJplQVv2nHHjHIpIq1CN8j4rKWDkveatRTtZa5bw88aojpeOPUJwUfg=w1910-h882";
    const RichmondLogo = "https://lh3.googleusercontent.com/fife/ALs6j_FEB_8R7vpLtvztNccGdOIgcvgFIjVBW2ZptfwnO38t965Z0Zmvy6TKyQuD-UhHEwk-Vj1Ch3YllHwKt54oqVr4DhmHVRA146N-PnVH5LS4fy_5gqVj6uCegIGyeJpnuaNIWA3tTTnDtlOgXmNJOjikiiBfd6sTUVHxUBZd1l5jvSAf_JY21KVs3pvIU8G82keqb_etcIzj5myX-PPVVd-uriG19914t_WE0ognU0ATKDyz6OI9juEVr9d5ycLHn5r6c7KCsnS6xFrhCdOx0mbB2_3SsaKwx8HR18AbbiJsgbZmyI3lCWoDuRiX57aTmaKzxPm1aB2x6N8W3hxBnhMSCQ_eFbyLIg-KevYIznx7PANJP5OZN-RSaQ7de-Fv2qEfGJ_HiR0hlizJ6_WSzLx0ehbTtgkrcDI7aCWUXZwAXpNx9ctk5_S4N5QsYVXZ8e9zmfJAsMalJWLgSfwoNcNZ_0mEiTJZFWDJczUPtpyh6qc-KtMaCMC2VH_ne0j5xJWWecsSwKhCQtTRsCx_Ia6vDUritT4dXSzC1fMlSz6_Kv6-OnuRdB3YZkZFGOxxMKe9VLBb4VqzwYKiPVIGKcQYYp4pgAytJ1GfciOLIDhYuOJl_2OPkWHBWHPBHBZ5cWpwvSud8IJdKaTH00ffD_-3zx9gJc1SJJyewPc-OZIjQ-g-UOjEpwo_qz0SdsKvnBtHGiPwBDhQObTJrIAwvB9mktPz3I8YrVl-O2Y5ninsPebjjTItE8YK8zqjfG8SYwy3k93292WMrfKizsQdN_lgFpmIgA8sPIhSaUJcga_J7hS0jwtVhShndVQkH5U6YoRl6-iNOqHjajCE0zcK-aM8weCWOHKMAfCRaNHw6TL2MsD95a3crHZSyEphojq5c2o0IjfPlbtMr4Ow9UTEBaD4YqmD4JLAVSseISuAJPKBrsrIguv0FbJ7nbhi74MbfT2GADifS1Cteuc2Zc2ZdWG2oTxuAN3Gp3Ba7O7dgkUqs5wu1rOymHYfHN5YbskMqeL3UA5Fdq2t_BfUA9hwOWenD538vIJMcYRaEyoTpi1aWsp9TQ-5dWb6nWrDgPXGKa7XcpiRWbvKJBT8zNKF6kURuHIQOtAmN2DA82rkx32PcdsLGETpKaeND5OErPapgvlJxbMBPnxoWqsJqL24b0dDz8PICJkPak3B9Vc-FIwA_9tAEaN2-yEZM1WS0pxM4TcDSyXGLueC-fC5zFx9ai5l3gGSdMmAOmU8Tshbt8Ks3BCNbH_qZbj-GNFmkJGDL86QS2xIrfcMeMZu9tT9r4WiSl6o92-7HlqdECCklUQtd5fTq3qz1Igh_MfUKbE0OWv9evxj6s8gVJZePMrzeG6yybRtVUeJQJtEwfr0veViTJBW6PP50-oQKvHOZH5gcxAPro3sA78BQOeqroSthCBsfQYKLVGanJO4-iHx8GayEef4L5E4tVNUvA1TlCPBhX9OlZHf86yZjzO5sYkAUs44ZA7ekxebDaUz5Q-c571tpWXpDKEuvlSK08pDpzFhYvtxSi_MUJyzSB0fci3ECTczHLkffx1gtv-aNNPxtM9ootwPLAhjQjpyMZsrhcY8mu3bmkGjXMXAYJ5PMG4ZPcb97_QDEd25KxgHhR-WtHRSmM5f7yjglnGuny54vIhHPfUBKT3P7Z5cf580GxVi2AKANXKU1Hew7bMO8Bi_LaRvl5sRSiMpEhT8D-TGeA=w1910-h882";
    const DSLogo = "https://lh3.googleusercontent.com/fife/ALs6j_GAzoYohNuYihd3SbQ2RPU-M7x3FDvoPckP4cwKHrVoJ0edy-Y204zCiI6bY3VaYZ01N-q5-7kz9BtyslC2eh9cGvM7wbjpEQZhdO1B4ppVLAfE28EJVx_Cs3nT8WicNVUKzf5IXUWJGd2vikGG90u7cIJ4Ubl9biQ6KSqYfRMgjAfVRoxFb4faKwdU5n8IuhEAlh6PtCODZb_5BXc3FjhnNPYgqrr7lqvCaNnV3Ccp7jed95FZxfsWLh2MVfZUW8Ht2eIIcTNm_ibcgVzEx7WboFC-agIEelrt9L-ECyC8MSPKa4lQw8s98kiWMfIpTv4GSiRm6njLVWFmrh-ehdp430ASWJC03M1SSfhQ8Ze6SHykvCYI1Gai2R6mdAX7ZneFJRL9R-gXxso8gqN6vvzaG1hFyQPqgrqXXAj64EmLcNo3D-36pDjD2G4hE61gnTJiMXWMxHOYImSbWwB7k8Sc9cSnbjGX48H-Zmbfnje93xm5cKNcP5cbB0ggyRMa9YYRRAYQzH3BG7MAvsWfc9mQoXQmlbHlbAgOga8KZ1LK6OPklBJI_qaihedAv9pHBRhCXhg6NPygf9MmherQIai2ddp74E6mwtkGe8rOh8uMMlMUwGDuKgkW_BrDJGEvT7l3kumtxRhezOlVLg6l_FU8gz9UW1zu4MqUfRrK-KGHZa-pTccXgesqCq-VevYVhiPVwLU0I__D-vJkwESJpSD4wbkAGwARIAu5NTdT66_-REqWUFJhJ9eadVGCvS_ZapAjVwdtsV-0SOfn8j3I149IrKeHHf_nXnIyH57am-l71Dw4bH_xSAhBLu93qTsl8IlhrhmOJD6OgJg2C5QnrLbBLb2fdJ44K7EJOrrGy8T7D6KEN8ngOxLsRK_AFc1hhMDUoqMTFIYfv6Uz3wUP7rpNYgEQCGzNC5EsWOlDX6xydD6F9JGZ8mN-Y524MA9yOziwpwtnKTMtYEjjLV3iQp5ep26Q1q-g8OefIxQCBVRQRRHUp1ow_VlSemSgTvWYzy5szVEAiHP3PRS4oVSESGI1O4Pg0-DZLDlfJfpdxAXat2jPuK-cCMSdyYmiPZ2Jx8VW6S3EIhEaQr62i3SqK4BdrAuapSeuqaOqq8C5grymWOyTcEksC6rnXEFn5se_UguYsJoBJ_Ba4YucCq-TJTiJARIvjy0GvbX-6ZBaa2_K8m6XE9HfD1JnWnGdOgE2dvx6MmMP5nvsQsa2LBQk099E_jEhMMImfAAm2zBXZBsJG2VXTXCYY1G7mTFQv5qyVEax2WtDY7kvoQqKYmlGqQ48fZI8kWce7siluvPk7Ei4x6pzteoPDFO2jYl5mXaDjHw4RF-7e004NMP5H-PHRHO2wtbAmGLLUrGINLU4cZJ4v1HJMTwo4R3NM-TeZsk6ZpIOl8aEwpL4MPGC6zzURUn9lFJGSzA3ToA7AiQjShjy8-JQE4HnvqIqbf2qnjlAAAN9K3AEGcgTsQdDGmxfg_w9cIlk0hQN1iQpAlul_EZ9I0NjTXVk1p9RD9q8izS2cMQODFj7V_vCrXRT4o8zURG02SwF2WhLxBLjwGHUGen7jF_CUUogEtOIgbyJ0OSwjKt7ZFsGoJbs0FJVA8X06m_HoBPuPgLPGrDGIvW953qUQA1URh0P0epV1UUT7Nv9f_EtyMn9y2E0k_89a8LGgD1euSjsX-ocZ7NYkf19oJOJVvaaQcRJmz_wxEGjnAs=w1910-h882";

    return (
        <div>
            <Container maxW="100%" id="results">
                <a href='/mindprobe'><Button m="20px 10px 0 10px">{"<< Home <<"}</Button></a>
                <Text textAlign="center" fontWeight="bold" textColor="gray.700" fontSize={['3xl', '4xl', '5xl']} letterSpacing="wider" pt="30px">Results</Text>
                {/* <Tabs isFitted variant='enclosed' maxW="100%" margin="auto" pt="30px">
                    <Container border="1.5px solid" borderColor="#00000010" borderRadius="20px" p="10px" maxW="95%">
                        <Text fontSize="lg" color="gray.700" align="center" pb="15px">Choose the Category :</Text>
                        <TabList mb='1em' maxW="100%" margin="auto" display="grid" gridGap="6px" gridTemplateColumns="repeat(auto-fit, minmax(160px, 1fr))">
                            <Tab p="20px 0" _selected={{ color: 'white', bg: 'gray.700', letterSpacing: 'wide', border: 'none' }} bg="#00000003" border="1px solid" borderColor="#00000010" borderRadius="20px">Senior Category</Tab>
                            <Tab p="20px 0" _selected={{ color: 'white', bg: 'gray.700', letterSpacing: 'wide', border: 'none' }} bg="#00000003" border="1px solid" borderColor="#00000010" borderRadius="20px">Junior Category</Tab>
                        </TabList>
                    </Container>
                    <TabPanels>
                        <TabPanel maxW="100%" p="0" m="0"> */}
                                        <Text textAlign="center" fontWeight="bold" textColor="gray.700" fontSize={['1xl', '2xl', '3xl']} letterSpacing="wider" borderBottom="1px" borderColor="#00000040" width="fit-content" margin=" 30px auto 0 auto" padding="10px 30px" borderRadius="10px">Senior Category</Text>

                <Container maxW="100%" p={['50px 30px 70px 30px']}>
                    <Container maxW="100%" mb="70px">
                        <Wrap justify="center" spacing="30px">
                            <Container maxW="100%">
                                <Container maxW="350px">
                                    <Center flexDir="column">
                                        <img alt='' style={{ height: "100px" }} src={DSLogo}></img>
                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">D.S. Senanayake College</Text>
                                        <Container bgColor="#FFD700" p="10px 20px" borderRadius="10px">
                                            <Text fontSize="s" color="gray.700" align="center">Winner</Text>
                                        </Container>
                                    </Center>
                                </Container>
                            </Container>
                            <Container maxW="100%" flexDir="column">
                                <Wrap justify="space-around" alignContent="center" spacing="30px">
                                    <Container maxW="350px">
                                        <Center flexDir="column">
                                            <img alt='' style={{ height: "100px" }} src={RichmondLogo}></img>
                                            <Text fontSize="xl" color="gray.700" mb="5px" align="center">Richmond College</Text>
                                            <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                            </Container>
                                        </Center>
                                    </Container>
                                    <Container maxW="350px">
                                        <Center flexDir="column">
                                            <img alt='' style={{ height: "100px" }} src={RoyalLogo}></img>
                                            <Text fontSize="xl" color="gray.700" mb="5px" align="center">Royal College</Text>
                                            <Container bgColor="#CD7F32" p="10px 20px" borderRadius="10px">
                                                <Text fontSize="s" color="gray.700" align="center">2nd Runner Up</Text>
                                            </Container>
                                        </Center>
                                    </Container>
                                </Wrap>
                            </Container>
                        </Wrap>
                    </Container>

                    <Container maxW="100%">
                        <Accordion allowToggle>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left'>
                                            Table Round Leaderboard
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <TableContainer>
                                        <Table size='md' variant='striped' colorScheme='blackAlpha'>
                                            <Thead>
                                                <Tr><a target='_blank' href='https://drive.google.com/file/d/10omcTSFCvzfdkMkImp3I3Fqrd4R49PsG/view?usp=sharing' rel="noreferrer"><Button m={"12px 0"} >View as a PDF</Button></a></Tr>
                                                <Tr>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">School</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Round 1</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Round 2</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Round 3</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Round 4</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Round 5</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Total</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Rank</Td>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <Tr><Td>D.S. Senanayake College </Td><Td>7</Td><Td>6</Td><Td>8</Td><Td>6</Td><Td>5</Td><Td>32</Td><Td>1</Td></Tr>
                                                <Tr><Td>Richmond College, Galle</Td><Td>6</Td><Td>3</Td><Td>6</Td><Td>5</Td><Td>5</Td><Td>25</Td><Td>2</Td></Tr>
                                                <Tr><Td>Royal College, Colombo</Td><Td>6</Td><Td>2</Td><Td>6</Td><Td>7</Td><Td>4</Td><Td>25</Td><Td>2</Td></Tr>
                                                <Tr><Td>Nalanda College, Colombo </Td><Td>7</Td><Td>2</Td><Td>4</Td><Td>4</Td><Td>5</Td><Td>22</Td><Td>4</Td></Tr>
                                                <Tr><Td>Gateway College, Colombo</Td><Td>5</Td><Td>1</Td><Td>4</Td><Td>7</Td><Td>4</Td><Td>21</Td><Td>5</Td></Tr>
                                                <Tr><Td>Bandaranayake College, Gampaha</Td><Td>8</Td><Td>3</Td><Td>3</Td><Td>4</Td><Td>3</Td><Td>21</Td><Td>5</Td></Tr>
                                                <Tr><Td>Mahinda College, Galle</Td><Td>7</Td><Td>2</Td><Td>6</Td><Td>5</Td><Td>1</Td><Td>21</Td><Td>5</Td></Tr>
                                                <Tr><Td>Zahira College, Colombo </Td><Td>8</Td><Td>1</Td><Td>1</Td><Td>6</Td><Td>2</Td><Td>18</Td><Td>8</Td></Tr>
                                                <Tr><Td>Dharmaraja College, Kandy</Td><Td>4</Td><Td>2</Td><Td>2</Td><Td>5</Td><Td>4</Td><Td>17</Td><Td>9</Td></Tr>
                                                <Tr><Td>Stafford International School</Td><Td>3</Td><Td>2</Td><Td>0</Td><Td>6</Td><Td>3</Td><Td>14</Td><Td>10</Td></Tr>
                                                <Tr><Td>Royal Institute, Nugegoda</Td><Td>3</Td><Td>3</Td><Td>3</Td><Td>3</Td><Td>2</Td><Td>14</Td><Td>10</Td></Tr>
                                                <Tr><Td>Mahamaya Girls' College, Kandy</Td><Td>5</Td><Td>3</Td><Td>4</Td><Td>0</Td><Td>2</Td><Td>14</Td><Td>10</Td></Tr>
                                                <Tr><Td>Lyceum International School, Wattala </Td><Td>6</Td><Td>1</Td><Td>1</Td><Td>5</Td><Td>1</Td><Td>14</Td><Td>10</Td></Tr>
                                                <Tr><Td>Visakha Vidyalaya, Colombo</Td><Td>4</Td><Td>3</Td><Td>2</Td><Td>3</Td><Td>1</Td><Td>13</Td><Td>14</Td></Tr>
                                                <Tr><Td>Mahinda Rajapaksha College, Homagama.</Td><Td>2</Td><Td>1</Td><Td>1</Td><Td>4</Td><Td>3</Td><Td>11</Td><Td>15</Td></Tr>
                                                <Tr><Td>Vidyartha College, Kandy </Td><Td>3</Td><Td>0</Td><Td>4</Td><Td>1</Td><Td>3</Td><Td>11</Td><Td>15</Td></Tr>
                                                <Tr><Td>Bishop's College, Colombo</Td><Td>2</Td><Td>2</Td><Td>1</Td><Td>4</Td><Td>2</Td><Td>11</Td><Td>15</Td></Tr>
                                                <Tr><Td>Ferguson High School, Ratnapura </Td><Td>3</Td><Td>3</Td><Td>1</Td><Td>3</Td><Td>1</Td><Td>11</Td><Td>15</Td></Tr>
                                                <Tr><Td> St.Thomas' College, Matara</Td><Td>6</Td><Td>1</Td><Td>0</Td><Td>4</Td><Td>0</Td><Td>11</Td><Td>15</Td></Tr>
                                                <Tr><Td>Rahula College, Matara</Td><Td>4</Td><Td>1</Td><Td>0</Td><Td>3</Td><Td>2</Td><Td>10</Td><Td>20</Td></Tr>
                                                <Tr><Td>Wesley College, Colombo</Td><Td>2</Td><Td>0</Td><Td>1</Td><Td>6</Td><Td>1</Td><Td>10</Td><Td>20</Td></Tr>
                                                <Tr><Td>Maliyadeva Balika Vidyalaya, Kurunegala </Td><Td>5</Td><Td>0</Td><Td>1</Td><Td>4</Td><Td>0</Td><Td>10</Td><Td>20</Td></Tr>
                                                <Tr><Td>Trinity College, Kandy</Td><Td>4</Td><Td>2</Td><Td>0</Td><Td>4</Td><Td>0</Td><Td>10</Td><Td>20</Td></Tr>
                                                <Tr><Td>Kingswood Collge, Kandy</Td><Td>3</Td><Td>2</Td><Td>2</Td><Td>3</Td><Td>0</Td><Td>10</Td><Td>20</Td></Tr>
                                                <Tr><Td>Devi Balika Vidyalaya, Colombo</Td><Td>4</Td><Td>1</Td><Td>2</Td><Td>1</Td><Td>1</Td><Td>9</Td><Td>25</Td></Tr>
                                                <Tr><Td>Sangamiththa Balika Vidyalaya, Galle</Td><Td>4</Td><Td>1</Td><Td>2</Td><Td>2</Td><Td>0</Td><Td>9</Td><Td>25</Td></Tr>
                                                <Tr><Td>St.Joseph Vaz College, Wennappuwa</Td><Td>3</Td><Td>1</Td><Td>0</Td><Td>3</Td><Td>0</Td><Td>7</Td><Td>27</Td></Tr>
                                                <Tr><Td>Central College, Kuliyapitiya</Td><Td>3</Td><Td>1</Td><Td>1</Td><Td>2</Td><Td>0</Td><Td>7</Td><Td>27</Td></Tr>
                                                <Tr><Td>ST.Aloysius' College, Galle </Td><Td>5</Td><Td>0</Td><Td>1</Td><Td>1</Td><Td>0</Td><Td>7</Td><Td>27</Td></Tr>
                                                <Tr><Td>Asian Grammar School, Gothatuwa</Td><Td>1</Td><Td>1</Td><Td>2</Td><Td>1</Td><Td>1</Td><Td>6</Td><Td>30</Td></Tr>
                                                <Tr><Td>St.Joseph's College, Colombo</Td><Td>2</Td><Td>1</Td><Td>1</Td><Td>1</Td><Td>1</Td><Td>6</Td><Td>30</Td></Tr>
                                                <Tr><Td>St. Bridget's Convent, Colombo</Td><Td>1</Td><Td>0</Td><Td>2</Td><Td>1</Td><Td>1</Td><Td>5</Td><Td>32</Td></Tr>
                                                <Tr><Td>Taxila Central College, Horana</Td><Td>2</Td><Td>0</Td><Td>1</Td><Td>2</Td><Td>0</Td><Td>5</Td><Td>32</Td></Tr>
                                                <Tr><Td>Hindu Ladies College, Colombo</Td><Td>1</Td><Td>0</Td><Td>1</Td><Td>0</Td><Td>2</Td><Td>4</Td><Td>34</Td></Tr>
                                                <Tr><Td>Kalutara Vidyalaya</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>2</Td><Td>1</Td><Td>4</Td><Td>34</Td></Tr>
                                                <Tr><Td>Thurstan College, Colombo</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>2</Td><Td>1</Td><Td>4</Td><Td>34</Td></Tr>
                                                <Tr><Td>Methodist College, Colombo</Td><Td>1</Td><Td>1</Td><Td>0</Td><Td>1</Td><Td>1</Td><Td>4</Td><Td>34</Td></Tr>
                                                <Tr><Td>Ananda Balika Vidyalaya, Colombo</Td><Td>2</Td><Td>0</Td><Td>0</Td><Td>1</Td><Td>1</Td><Td>4</Td><Td>34</Td></Tr>
                                                <Tr><Td>St.Joseph's Balika Vidyalaya, Kegalle</Td><Td>1</Td><Td>1</Td><Td>1</Td><Td>0</Td><Td>1</Td><Td>4</Td><Td>34</Td></Tr>
                                                <Tr><Td>Rathnavali Balika Vidyalaya, Gampaha</Td><Td>2</Td><Td>1</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>4</Td><Td>34</Td></Tr>
                                                <Tr><Td>Viharamahadevi Balika Vidyalaya, Kiribathgoda</Td><Td>1</Td><Td>0</Td><Td>1</Td><Td>0</Td><Td>1</Td><Td>3</Td><Td>41</Td></Tr>
                                                <Tr><Td>Isipathana College, Colombo</Td><Td>1</Td><Td>0</Td><Td>1</Td><Td>1</Td><Td>0</Td><Td>3</Td><Td>41</Td></Tr>
                                                <Tr><Td>Bandaranayake Central College, Veyangoda</Td><Td>1</Td><Td>1</Td><Td>0</Td><Td>1</Td><Td>0</Td><Td>3</Td><Td>41</Td></Tr>
                                                <Tr><Td>Sri Dharmaloka College</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>0</Td><Td>1</Td><Td>2</Td><Td>44</Td></Tr>
                                                <Tr><Td>Dharmapala College, Pannipitiya</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>0</Td><Td>1</Td><Td>2</Td><Td>44</Td></Tr>
                                                <Tr><Td>Sivali Central College</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>1</Td><Td>0</Td><Td>2</Td><Td>44</Td></Tr>
                                                <Tr><Td>Galahitiyawa Central College</Td><Td>0</Td><Td>1</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>2</Td><Td>44</Td></Tr>
                                                <Tr><Td>Sujatha Vidyalaya, Nugegoda </Td><Td>1</Td><Td>0</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>2</Td><Td>44</Td></Tr>
                                                <Tr><Td>St.Sebastian's College, Moratuwa</Td><Td>0</Td><Td>0</Td><Td>0</Td><Td>0</Td><Td>1</Td><Td>1</Td><Td>49</Td></Tr>
                                                <Tr><Td>Yasodara Devi Balika Vidyalaya, Gampaha</Td><Td>0</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>0</Td><Td>1</Td><Td>49</Td></Tr>
                                                <Tr><Td>Sirimavo Bandaranaike Vidyalaya, Colombo</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>0</Td><Td>0</Td><Td>1</Td><Td>49</Td></Tr>
                                                <Tr><Td>Asoka College, Horana</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>0</Td><Td>0</Td><Td>1</Td><Td>49</Td></Tr>
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Container>
                </Container>
                {/* </TabPanel>
                        <TabPanel maxW="100%" p="0" m="0"> */}
                                        <Text textAlign="center" fontWeight="bold" textColor="gray.700" fontSize={['1xl', '2xl', '3xl']} letterSpacing="wider" borderBottom="1px" borderColor="#00000040" width="fit-content" margin=" 30px auto 0 auto" padding="10px 30px" borderRadius="10px">Junior Category</Text>

                <Container maxW="100%" p={['50px 30px 70px 30px']}>
                    <Container maxW="100%" mb="70px">
                        <Wrap justify="center" spacing="30px">
                            <Container maxW="100%">
                                <Container maxW="350px">
                                    <Center flexDir="column">
                                        <img alt='' style={{ height: "100px" }} src={RichmondLogo}></img>
                                        <Text fontSize="xl" color="gray.700" mb="5px" align="center">Richmond College</Text>
                                        <Container bgColor="#FFD700" p="10px 20px" borderRadius="10px">
                                            <Text fontSize="s" color="gray.700" align="center">Winner</Text>
                                        </Container>
                                    </Center>
                                </Container>
                            </Container>
                            <Container maxW="100%" flexDir="column">
                                <Wrap justify="space-around" alignContent="center" spacing="30px">
                                    <Container maxW="350px">
                                        <Center flexDir="column">
                                            <img alt='' style={{ height: "100px" }} src={DSLogo}></img>
                                            <Text fontSize="xl" color="gray.700" mb="5px" align="center">D.S. Senanayake College</Text>
                                            <Container bgColor="#C0C0C0" p="10px 20px" borderRadius="10px">
                                                <Text fontSize="s" color="gray.700" align="center">1st Runner Up</Text>
                                            </Container>
                                        </Center>
                                    </Container>
                                    <Container maxW="350px">
                                        <Center flexDir="column">
                                            <img alt='' style={{ height: "100px" }} src={RoyalLogo}></img>
                                            <Text fontSize="xl" color="gray.700" mb="5px" align="center">Royal College</Text>
                                            <Container bgColor="#CD7F32" p="10px 20px" borderRadius="10px">
                                                <Text fontSize="s" color="gray.700" align="center">2nd Runner Up</Text>
                                            </Container>
                                        </Center>
                                    </Container>
                                </Wrap>
                            </Container>
                        </Wrap>
                    </Container>

                    <Container maxW="100%">
                        <Accordion allowToggle>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left'>
                                            Table Round Leaderboard
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <TableContainer>
                                        <Table size='md' variant='striped' colorScheme='blackAlpha'>
                                            <Thead>
                                                <Tr><a target='_blank' href='https://drive.google.com/file/d/1OXRZIN29b-J65mOqqqWyZ5P48dsPBTHI/view?usp=drive_link' rel="noreferrer"><Button m={"12px 0"} >View as a PDF</Button></a></Tr>
                                                <Tr>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">School</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Current Affairs</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">History and<br />Geography</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Sports and<br />Games</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Science and<br />Technology</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Arts and<br />Literature</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Total</Td>
                                                    <Td border="solid" borderWidth="1px 0 1px 0">Rank</Td>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <Tr><Td>D.S. Senanayake College</Td><Td>9</Td><Td>7</Td><Td>10</Td><Td>8</Td><Td>5</Td><Td>39</Td><Td>1</Td></Tr>
                                                <Tr><Td>Richmond College</Td><Td>9</Td><Td>7</Td><Td>6</Td><Td>9</Td><Td>4</Td><Td>35</Td><Td>2</Td></Tr>
                                                <Tr><Td>Royal College</Td><Td>8</Td><Td>7</Td><Td>5</Td><Td>9</Td><Td>5</Td><Td>34</Td><Td>3</Td></Tr>
                                                <Tr><Td>Nalanda College Colombo</Td><Td>9</Td><Td>5</Td><Td>4</Td><Td>3</Td><Td>5</Td><Td>26</Td><Td>4</Td></Tr>
                                                <Tr><Td>Gateway College Colombo</Td><Td>5</Td><Td>6</Td><Td>4</Td><Td>6</Td><Td>2</Td><Td>23</Td><Td>5</Td></Tr>
                                                <Tr><Td>Devi Balika Vidyalaya</Td><Td>7</Td><Td>4</Td><Td>0</Td><Td>6</Td><Td>1</Td><Td>18</Td><Td>6</Td></Tr>
                                                <Tr><Td>Lyceum International School Wattala</Td><Td>5</Td><Td>6</Td><Td>4</Td><Td>3</Td><Td>0</Td><Td>18</Td><Td>6</Td></Tr>
                                                <Tr><Td>Dharmaraja college Kandy</Td><Td>4</Td><Td>2</Td><Td>4</Td><Td>6</Td><Td>1</Td><Td>17</Td><Td>8</Td></Tr>
                                                <Tr><Td>Rahula College, Matara</Td><Td>4</Td><Td>1</Td><Td>4</Td><Td>5</Td><Td>3</Td><Td>17</Td><Td>8</Td></Tr>
                                                <Tr><Td>ST.Aloysius' College Galle</Td><Td>5</Td><Td>4</Td><Td>4</Td><Td>4</Td><Td>0</Td><Td>17</Td><Td>8</Td></Tr>
                                                <Tr><Td>St.Joseph Vaz College</Td><Td>3</Td><Td>3</Td><Td>1</Td><Td>8</Td><Td>2</Td><Td>17</Td><Td>8</Td></Tr>
                                                <Tr><Td>Bandaranayake College, Gampaha</Td><Td>6</Td><Td>3</Td><Td>1</Td><Td>2</Td><Td>4</Td><Td>16</Td><Td>12</Td></Tr>
                                                <Tr><Td>Ferguson High School, Ratnapura</Td><Td>5</Td><Td>0</Td><Td>3</Td><Td>5</Td><Td>3</Td><Td>16</Td><Td>12</Td></Tr>
                                                <Tr><Td>Kingswood Collge</Td><Td>6</Td><Td>2</Td><Td>2</Td><Td>5</Td><Td>1</Td><Td>16</Td><Td>12</Td></Tr>
                                                <Tr><Td>Vidura College Colombo</Td><Td>1</Td><Td>4</Td><Td>3</Td><Td>6</Td><Td>2</Td><Td>16</Td><Td>12</Td></Tr>
                                                <Tr><Td>Zahira College Colombo 10</Td><Td>3</Td><Td>4</Td><Td>5</Td><Td>2</Td><Td>2</Td><Td>16</Td><Td>12</Td></Tr>
                                                <Tr><Td>Ashoka College Horana</Td><Td>5</Td><Td>2</Td><Td>2</Td><Td>3</Td><Td>0</Td><Td>12</Td><Td>17</Td></Tr>
                                                <Tr><Td>Royal Institute of nugegoda</Td><Td>4</Td><Td>1</Td><Td>3</Td><Td>3</Td><Td>1</Td><Td>12</Td><Td>17</Td></Tr>
                                                <Tr><Td>Mahinda Rajapaksha College,Homagama.</Td><Td>4</Td><Td>3</Td><Td>2</Td><Td>1</Td><Td>1</Td><Td>11</Td><Td>19</Td></Tr>
                                                <Tr><Td>Trinity College Kandy</Td><Td>2</Td><Td>0</Td><Td>5</Td><Td>4</Td><Td>0</Td><Td>11</Td><Td>19</Td></Tr>
                                                <Tr><Td>Mahamaya Girls' College, Kandy.</Td><Td>5</Td><Td>1</Td><Td>1</Td><Td>3</Td><Td>0</Td><Td>10</Td><Td>21</Td></Tr>
                                                <Tr><Td>Sivali Central College, Ratnapura</Td><Td>1</Td><Td>3</Td><Td>1</Td><Td>4</Td><Td>1</Td><Td>10</Td><Td>21</Td></Tr>
                                                <Tr><Td>St. Bridget's Convent</Td><Td>2</Td><Td>2</Td><Td>2</Td><Td>3</Td><Td>1</Td><Td>10</Td><Td>21</Td></Tr>
                                                <Tr><Td>Thurstan College</Td><Td>3</Td><Td>2</Td><Td>1</Td><Td>2</Td><Td>2</Td><Td>10</Td><Td>21</Td></Tr>
                                                <Tr><Td>St.Sebastian's College Moratuwa</Td><Td>1</Td><Td>1</Td><Td>2</Td><Td>4</Td><Td>1</Td><Td>9</Td><Td>25</Td></Tr>
                                                <Tr><Td>Central College Kuliyapitiya</Td><Td>4</Td><Td>0</Td><Td>1</Td><Td>2</Td><Td>1</Td><Td>8</Td><Td>26</Td></Tr>
                                                <Tr><Td>Maliyadeva Balika Vidyalaya Kurunegala</Td><Td>2</Td><Td>0</Td><Td>1</Td><Td>4</Td><Td>1</Td><Td>8</Td><Td>26</Td></Tr>
                                                <Tr><Td>St.Joseph's College, Colombo 10</Td><Td>2</Td><Td>1</Td><Td>3</Td><Td>2</Td><Td>0</Td><Td>8</Td><Td>26</Td></Tr>
                                                <Tr><Td>Visakha Vidyalaya, Colombo 5</Td><Td>3</Td><Td>0</Td><Td>3</Td><Td>2</Td><Td>0</Td><Td>8</Td><Td>26</Td></Tr>
                                                <Tr><Td>Asian Grammar School, Gothatuwa</Td><Td>0</Td><Td>0</Td><Td>2</Td><Td>5</Td><Td>0</Td><Td>7</Td><Td>30</Td></Tr>
                                                <Tr><Td>G/Sanghamitta Balika Vidyalaya</Td><Td>3</Td><Td>1</Td><Td>0</Td><Td>2</Td><Td>1</Td><Td>7</Td><Td>30</Td></Tr>
                                                <Tr><Td>Sirimavo Bandaranaike Vidyalaya , colombo 7</Td><Td>4</Td><Td>0</Td><Td>0</Td><Td>2</Td><Td>1</Td><Td>7</Td><Td>30</Td></Tr>
                                                <Tr><Td>WP/KE/Viharamahadevi Balika Vidyalaya</Td><Td>2</Td><Td>1</Td><Td>1</Td><Td>1</Td><Td>2</Td><Td>7</Td><Td>30</Td></Tr>
                                                <Tr><Td>Mahinda College, Galle</Td><Td>2</Td><Td>1</Td><Td>3</Td><Td>0</Td><Td>1</Td><Td>7</Td><Td>30</Td></Tr>
                                                <Tr><Td>ASOKA VIDYALAYA</Td><Td>3</Td><Td>0</Td><Td>1</Td><Td>1</Td><Td>1</Td><Td>6</Td><Td>35</Td></Tr>
                                                <Tr><Td>Defence Services College Colombo 02</Td><Td>3</Td><Td>0</Td><Td>1</Td><Td>2</Td><Td>0</Td><Td>6</Td><Td>35</Td></Tr>
                                                <Tr><Td>Pannipitiya Dharmapala Vidylaya</Td><Td>3</Td><Td>2</Td><Td>0</Td><Td>0</Td><Td>1</Td><Td>6</Td><Td>35</Td></Tr>
                                                <Tr><Td>Kg/St.Joseph's Balika Vidyalaya</Td><Td>0</Td><Td>2</Td><Td>0</Td><Td>3</Td><Td>0</Td><Td>5</Td><Td>38</Td></Tr>
                                                <Tr><Td>WP/ Sri Dharmaloka College</Td><Td>1</Td><Td>1</Td><Td>0</Td><Td>3</Td><Td>0</Td><Td>5</Td><Td>38</Td></Tr>
                                                <Tr><Td>Yasodara Devi Balika Vidyalaya, Gampaha</Td><Td>2</Td><Td>0</Td><Td>0</Td><Td>2</Td><Td>1</Td><Td>5</Td><Td>38</Td></Tr>
                                                <Tr><Td>Vidyartha College Kandy</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>1</Td><Td>2</Td><Td>4</Td><Td>41</Td></Tr>
                                                <Tr><Td>Taxila College, Horana</Td><Td>2</Td><Td>0</Td><Td>0</Td><Td>2</Td><Td>0</Td><Td>4</Td><Td>41</Td></Tr>
                                                <Tr><Td>Bandaranayake Central College,Veyangoda</Td><Td>2</Td><Td>1</Td><Td>0</Td><Td>0</Td><Td>0</Td><Td>3</Td><Td>43</Td></Tr>
                                                <Tr><Td>Hanwella Rajasingha Central College.</Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td>0</Td><Td>44</Td></Tr>
                                                <Tr><Td>Mayurapada Central College Narammala</Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td>0</Td><Td>44</Td></Tr>
                                                <Tr><Td>Minuwangoda Nalanda Balika Vidylaya</Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td>0</Td><Td>44</Td></Tr>
                                                <Tr><Td>St. Joseph's Girls School Nugegoda</Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td>0</Td><Td>44</Td></Tr>
                                                <Tr><Td>Yasodara Vidyalaya,Colombo</Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td>0</Td><Td>44</Td></Tr>
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Container>
                </Container>
                {/* </TabPanel>
                    </TabPanels>
                </Tabs> */}
            </Container>
        </div>
    )
}

export default Results