import React, { useContext, useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
  Input, Button, VStack, IconButton, Textarea, Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { db } from '../../Services/firebase';
import { setDoc, deleteDoc, doc, serverTimestamp } from "firebase/firestore";
import { useToast } from '@chakra-ui/toast';
import { Container } from '@chakra-ui/layout';
import { DbContext } from '../../Services/context';
import { DeleteIcon } from '@chakra-ui/icons';
import Nav from './Menu';

const HistoryEdit = () => {
  useEffect(() => {
    document.title = "ACQC - Admin | History Update"
  });

  const toast = useToast();
  const [deleteRef, setDeleteRef] = useState('');
  const { history } = useContext(DbContext);

  const [isOpenAlert, setIsOpen] = React.useState(false)
  const onCloseAlert = () => {
    setIsOpen(false);
    setDeleteRef('');
  }
  const cancelRef = React.useRef()

  const deleteRank = async () => {
    try {
      await deleteDoc(doc(db, "history", deleteRef));
      toast({
        title: "Highlight Deleted",
        description: `History highlight ${deleteRef} has been deleted`,
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }

    catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsOpen(false)
  }

  const handleDelete = async (rankName) => {

    setDeleteRef(rankName);
    setIsOpen(true)
  }

  return (
    <>
      <Nav />
      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete History Highlight?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteRank} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>



      <Container maxW="90vw" display="flex" flexDir="row">
        <Container maxW="45vw" p="50px 50px" display="flex" flexDir="column" alignItems="center" justifyContent="flex-start" m="0">
          <Text fontSize="3xl" mb="50px" fontWeight="semibold" w="50vw" textAlign="left" p="0px 50px">Add/Delete History Highlights</Text>
          <Formik
            initialValues={{ title: '', description: '', link: '', buttonText: '', time: '' }}
            validate={values => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {

              values.time = serverTimestamp();

              try {

                setDoc(doc(db, "history", values.title), values);

                toast({
                  title: 'History highlight added',
                  description: '',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });

              }

              catch (error) {
                toast({
                  title: 'OOPS, something happened',
                  description: error.message,
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                });
              }

              setSubmitting(false);

              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <VStack spacing={4} w="45vw">
                  <Input
                    type="text"
                    name="title"
                    w="45vw"
                    placeholder="Title"
                    onChange={handleChange}
                    onBlur={handleBlur}

                    value={values.title}
                  />

                  <Textarea
                    type="text"
                    name="description"
                    h="100px"
                    w="45vw"

                    placeholder="Description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />

                  <Input
                    type="text"
                    name="link"
                    w="45vw"
                    placeholder="link to resources"

                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.link}
                  />

                  <Input
                    type="text"
                    name="buttonText"
                    w="45vw"
                    placeholder="Button Text"

                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.buttonText}
                  />
                </VStack>

                {errors.password && touched.password && errors.password}
                <Button type="submit" disabled={isSubmitting} mt="20px">
                  Submit
                </Button>

              </form>
            )}
          </Formik>
        </Container>

        <Container maxW="45vw" bg="white" h="80vh" borderRadius="2xl" boxShadow='lg' position="fixed" right="50px" top="150px" p="50px" overflowY="scroll">
          {history && history.map((highlight) => {
            return (

              <Container className="taskCard" display="flex" flexDirection="row" justifyContent="space-between" mb="20px" p="0" maxW="45vw" borderBottom="1px" borderColor="gray.200" pb="10px" position="relative" >
                <Container maxW="45vw" display="flex" flexDirection="column">
                  <Text as="h1" fontSize="xl" textColor="black" mr="30px" fontWeight="medium">{highlight.title}</Text>

                  <Text as="h1" fontSize="lg" textColor="gray.600" cursor="pointer">{highlight.description}</Text>
                </Container>

                <IconButton
                  icon={<DeleteIcon />}
                  value={highlight.taskId} onClick={() => {
                    handleDelete(highlight.title)
                  }}
                />


              </Container>
            )

          })}
        </Container>
      </Container>
    </>
  )
};

export default HistoryEdit;