import { Center, Container, Text } from '@chakra-ui/react'
import React from 'react'

const Description = () => {
    return (
        <Container maxW="100%" bg="gray.900" id="about">
            <Center pt={["20px", "50px", "80px"]} pb="80px" pl="3%" pr="3%" justifyContent="flex-start" flexDirection="column">
                <Text fontSize={["lg", "xl", "2xl"]} textColor="gray.300" mt="20px" p={["0 10px", "0 50px", "0.5, 100px"]} >
                    The Ananda College Quiz Club's history dates back to the 1970s when it was established to enhance general knowledge among students. Initially known as Anada Nuwana Samaajaya, it was founded in 1974 due to the absence of a formal quiz team at Ananda College, Sri Lanka. The club began with key members like Wijesena Wathugala, Gamini Nanda Gunawardane, and G. S. de Silva.
                </Text>

                <Text fontSize={["lg", "xl", "2xl"]} textColor="gray.300" mt="20px" p={["0 10px", "0 50px", "0.5, 100px"]} >
                    Under the leadership of these students, the club initiated efforts to promote knowledge. They published a newspaper called "Anada Puwath" in 1975 and maintained a bulletin board, "Anada Nuwana Samaaja," in the Fritz Kunz building. A book exhibition was also organized. However, in the mid-1990s, the club became inactive due to a lack of leadership.
                </Text>

                <Text fontSize={["lg", "xl", "2xl"]} textColor="gray.300" mt="20px" p={["0 10px", "0 50px", "0.5, 100px"]} >
                    In 1998, during Mr. T. B. Damunupola's tenure as principal, the club was revived as the "Ananda College Quiz Club." Damith Rodrigo and Tony Ukwattage were its president and secretary respectively, and Mrs. Sujatha de Silva became the first teacher in charge. The club's resurgence was guided by former Ananda College quizzer Mr. Ruwan Senanayake.
                </Text>

                <Text fontSize={["lg", "xl", "2xl"]} textColor="gray.300" mt="20px" p={["0 10px", "0 50px", "0.5, 100px"]} >
                    Over the years, the club gained strength and began hosting an interschool quiz competition named "Mind Probe" from 2003 onwards. It also established a primary quiz club to engage younger students. The club extended its influence by collaborating with other schools, organizing workshops, model quiz competitions, and even joining hands with Royal College and Musaeus College for the Triangular Quiz in 2014.
                </Text>

                <Text fontSize={["lg", "xl", "2xl"]} textColor="gray.300" mt="20px" p={["0 10px", "0 50px", "0.5, 100px"]} >
                The COVID-19 pandemic had a profound impact, leading the Ananda College Quiz Club to pioneer online quizzing in Sri Lanka. They conducted the first virtual inter-school quiz competition, Mind Probe 2020, and continued the trend with Mind Probe 2021. Furthermore, they hosted the Col. Henry Steele Olcott Memorial Quiz for schools founded by Col. Olcott.
                </Text>
            </Center>

        </Container>
    )
}

export default Description
